<template>
  <div class="brochure">
    <figure class="masonry-item-foto-container">
      <FadeInImage
        :src="require('@/'+brochurePath+brochure.img)"
        :src-placeholder="require('@/assets/img/default-img.jpg')"></FadeInImage>
    </figure>
    <div class="brochure-overlay">
      <div class="brochure-info-container d-flex flex-row align-items-center">
<!--        <component class="icon" :is="wallpaper.familyIcon"></component>-->
        <a :href="base_url + brochure.pdf" target="_blank"
           class="d-inline-flex flex-row align-items-center justify-content-center img-download-container">
          <span class="bcLiguria">{{$t('brochure.download')}} ({{brochure.size}} Mb)</span>
          <div class="d-flex flex-column justify-content-center">
            <DownloadIcon></DownloadIcon>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FadeInImage from '@/components/lazy-load-images/FadeImage'
import DownloadIcon from '@/assets/img/download-icon.svg'
export default {
  name: 'BrochureCard',
  props: {
    brochure: {
      type: Object,
      required: true
    }
  },
  components: {
    FadeInImage,
    DownloadIcon
  },
  data () {
    return {
      brochurePath: 'assets/img/brochures/',
      base_url: process.env.BASE_URL
    }
  }
}
</script>

<style scoped lang="scss">
.brochure {
  .brochure-overlay {
    position: relative;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    background-color: rgba(0,0,0,0.4);

    .brochure-info-container {
      height: 40px;
      position: absolute;
      bottom: var(--main-space);
      left: var(--main-space);

      .img-download-container {
        background-color: #FFF;
        margin-left: calc(var(--main-space) / 2);
        height: 100%;
        padding: 0 calc(var(--main-space) / 2);

        span {
          color: #000;
          margin-right: calc(var(--main-space) / 2);
        }
        svg {
          width: 20px;
        }
        svg, path {
          fill: #000;
        }
      }
    }
  }

  &:hover {
    .brochure-overlay {
      display: flex;
    }
  }
}

</style>
