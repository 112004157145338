<template>
  <div class="grid-container">
    <div class="grid-item" :class="{'grid-item-2': (item%3===0),'grid-item-4': (item%6===0)}" v-for="(item, index) in 24" :key="index">
      <span>{{item}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Grid'
}
</script>

<style scoped lang="scss">
  .grid-container {
    border: 1px solid red;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, calc(20% - 1px));
    grid-auto-flow: row dense;
    grid-gap: 1px;

    .grid-item {
      background-color: pink;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 100%;
      position: relative;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .grid-item-2 {
      grid-column: span 2;
      padding-top: 50%;
      background-color: lightgreen;
    }
    .grid-item-4 {
      grid-column: span 2;
      grid-row: span 2;
      padding-top: 100%;
      background-color: lightblue;
    }
  }
</style>
