<template>
  <div class="person-container d-flex flex-row">
    <div class="person person-separator d-flex flex-column justify-content-center align-items-center">
<!--      <PeopleIcon></PeopleIcon>-->
      <figure>
        <transition name="fade-in-up" mode="out-in">
          <img :src="require('@/assets' + person.img)" alt="">
        </transition>
      </figure>
    </div>
    <div class="person">
      <div class="person-info-container d-flex flex-column">
        <span class="person-name">{{person.name}}</span>
        <span class="person-job">{{person.job}}</span>
        <div class="person-social-container d-flex flex-row align-items-center">
          <a :href="'mailto:' + person.mail" class="d-flex flex-column"><MailIcon></MailIcon></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailIcon from '../assets/img/mail-icon.svg'
export default {
  name: 'PersonBox',
  props: ['person'],
  components: {
    MailIcon
  },
  data () {
    return {}
  }
}
</script>

<style scoped lang="scss">
  .person-container {
    width: calc(100% / 3);
  }
  .person {
    width: 50%;
    padding-top: 50%;
    background-color: #000000;
    position: relative;
    figure {
      background-color: #000000;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        filter: grayscale(1);
        transition: filter 0.5s ease-in-out;
        @supports (object-fit: cover) {
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        &:hover {
          filter: grayscale(0);
        }
        &.selected {
          opacity: 1;
        }
      }
    }
  }
  .person-separator {
    background-color: $footer-text;
    position: relative;
  }
  .person-info-container {
    position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    padding: calc(var(--main-space) / 2);
  }
  .person-name {
    font-size: 18px;
    line-height: 24px;
    color: $footer-text;
    font-weight: 700;
    margin-bottom: calc(var(--main-space) / 5);
  }
  .person-job {
    font-size: 18px;
    line-height: 24px;
    color: $footer-text;
    /*font-weight: 600;*/
  }
  .person-social-container {
    width: 100%;
    padding: 0;
    min-height: 30px;
    position: absolute;
    left: calc(var(--main-space) / 2);
    bottom: calc(var(--main-space) / 2);
  }
  .person-social-container a {
    width: 1.5rem;
    height: 1.5rem;
  }
  .person-social-container a svg,
  .person-social-container a svg path
  {
    fill: $footer-text;
  }
  .person-social-container a:hover svg,
  .person-social-container a:hover svg path {
    fill: #FFFFFF !important;
  }

  .person-container:nth-of-type(6n),
  .person-container:nth-of-type(6n - 1),
  .person-container:nth-of-type(6n - 2)
  {
    //flex-direction: row-reverse !important;
  }

  @include media-breakpoint-down(md) {
    .person-container {
      width: calc(100% / 2);
      &:nth-of-type(1n) {
        flex-direction: row !important;
      }
      &:nth-of-type(4n),
      &:nth-of-type(4n - 1)
      {
        //flex-direction: row-reverse !important;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .person-container {
      width: 100%;
      .person-separator {
        svg {
          width: 60%;
        }
      }
      &:nth-of-type(1n) {
        flex-direction: row !important;
      }
      &:nth-of-type(2n)
      {
        //flex-direction: row-reverse !important;
      }
    }
  }
</style>
