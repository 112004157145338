<template>
  <div class="di-carousel-container position-relative" v-if="itemActive !== null">
    <div class="di-carousel-item">
      <img :src="itemActive" alt="" v-if="isItemActiveImage">
      <video v-if="isItemActiveVideo" controls>
        <source :src="itemActive" type='video/webm; codecs="vp8, vorbis"' />
        <source :src="itemActive" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
        Video tag not supported. Download the video <a :href="itemActive">here</a>.
      </video>
    </div>
    <Arrow class="arrow left-arrow" @click.prevent.stop="moveSlider('left')" v-if="showArrows"></Arrow>
    <Arrow class="arrow right-arrow" @click.prevent.stop="moveSlider('right')" v-if="showArrows"></Arrow>
  </div>
</template>

<script>
import Helper from '@/helpers/Helper'
import Arrow from '@/assets/img/arrow-icon.svg'

export default {
  name: 'DICarousel',
  props: ['items'],
  components: {
    Arrow
  },
  data () {
    return {
      activePos: 0,
      itemActive: null
    }
  },
  computed: {
    isItemActiveImage () {
      return Helper.isURLImage(this.itemActive)
    },
    isItemActiveVideo () {
      return Helper.isURLVideo(this.itemActive)
    },
    showArrows () {
      return this.items.length > 1
    }
  },
  methods: {
    moveSlider (arrow) {
      if (arrow === 'left') {
        this.moveSliderLeft()
      } else {
        this.moveSliderRight()
      }
    },
    moveSliderLeft () {
      this.activePos--
      if (this.activePos < 0) {
        this.activePos = (this.items.length - 1)
      }
      this.selectActiveItem()
    },
    moveSliderRight () {
      this.activePos++
      if (this.activePos === this.items.length) {
        this.activePos = 0
      }
      this.selectActiveItem()
    },
    selectActiveItem () {
      this.itemActive = this.items[this.activePos]
    }
  },
  mounted () {
    this.selectActiveItem()
  }
}
</script>

<style scoped lang="scss">
  .di-carousel-container {
    width: 100%;
    .di-carousel-item {
      width: 100%;
      height: 500px;
      img {
        width: 100%;
        @supports (object-fit: cover) {
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      video {
        width: 100%;
        @supports (object-fit: cover) {
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: var(--main-space);
      &.left-arrow {
        left: calc(var(--main-space) / 2);
        transform: rotate(180deg);
      }
      &.right-arrow {
        right: calc(var(--main-space) / 2);
      }
    }
  }
</style>
