<template>
  <v-lazy-image
    :src="src"
    :src-placeholder="srcPlaceholder"
    :class="classes"
    @load="$emit('load')"
  ></v-lazy-image>
</template>

<script>
import VLazyImage from 'v-lazy-image'
export default {
  name: 'FadeUp',
  components: {
    VLazyImage
  },
  props: {
    src: {
      type: String,
      required: true
    },
    srcPlaceholder: {
      type: String
    },
    classes: {
      type: Array
    }
  }
}
</script>

<style scoped lang="scss">
  .v-lazy-image {
    opacity: 0;
    transition: opacity 2s;
    &.background-solid {
      opacity: 1;
    }
    &.v-lazy-image-loaded {
       opacity: 1;
     }
  }
</style>
