<template>
  <div class="products-container" :class="{'products-container-safari': isSafari || isMobileSafari}">
    <transition-group class="grid-container d-flex flex-column" name="fade-in-right-group" mode="out-in" tag="div"
                      :style="{'--total': (familyProducts.length + 1) }" v-if="showGridContainer">
      <div class="family-container d-flex flex-row" v-for="(family, index) in familyProducts" :key="family.id"
           :style="{'--i': (index + 1)}">
        <div class="family-info-container d-flex flex-row align-items-center">
          <figure class="family-icon">
            <img class="" :src="family.icon" alt="">
          </figure>
          <router-link :to="{name: 'family-product', params: {familyProduct: family.id + '-' + family.path, object: family}}"
                       class="bcLiguria family-name" :style="{color: family.color}">{{family.name_lang[$i18n.locale]}}</router-link>
        </div>
        <div class="family-projects d-none d-sm-flex flex-row" v-if="!isMobileOnly">
          <figure class="family-project" v-for="familyProject in getFamilyProjects(family.id)"
                  :key="familyProject.id" @click.stop.prevent="showWork(familyProject)">
            <img :src="familyProject.fotoFile" alt="">
          </figure>
          <figure class="family-project" v-for="i in getFamilyProjectsRestantes(family.id)"
                  :key="family.id + 'no-project-msh-' + i">
            <img :src="require('@/assets/img/default-img.jpg')" alt="Image default MSH">
          </figure>
        </div>
      </div>
<!--      FUNDERMAX-->
      <div class="family-container d-flex flex-row" :style="{'--i': 9}" :key="31">
        <div class="family-info-container family-fundermax-info-container d-flex flex-row align-items-center">
          <figure class="family-icon">
            <FundermaxSvgLogo></FundermaxSvgLogo>
          </figure>
          <router-link :to="{name: 'Fundermax'}" class="bcLiguria family-name">Fundermax</router-link>
        </div>
        <div class="family-projects d-none d-sm-flex flex-row" v-if="!isMobileOnly">
          <figure class="family-project" v-for="fundermaxProject in getFamilyProjects(31)"
                  :key="'fundermax-project-' + fundermaxProject.id" @click.stop.prevent="showWork(fundermaxProject)">
            <img :src="fundermaxProject.fotoFile" alt="">
          </figure>
        </div>
      </div>
<!--      STAC BOND-->
      <div class="family-container d-flex flex-row" :style="{'--i': 10}" :key="38">
        <div class="family-info-container family-fundermax-info-container d-flex flex-row align-items-center">
          <figure class="family-icon">
            <StacBondLogo />
          </figure>
          <router-link :to="{name: 'StacBond'}" class="bcLiguria family-name">StacBond</router-link>
        </div>
        <div class="family-projects d-none d-sm-flex flex-row" v-if="!isMobileOnly">
          <figure class="family-project" v-for="stacBondProject in getFamilyProjects(38)"
                  :key="'stacbond-project-' + stacBondProject.id" @click.stop.prevent="showWork(stacBondProject)">
            <img :src="stacBondProject.fotoFile" alt="">
          </figure>
        </div>
      </div>
    </transition-group>
  </div>
<!--  <div class="products-container" ref="productsContainer" :class="{'products-container-mobile-safari': isSafari || isMobileSafari}">-->
<!--    <transition-group name="fade-in-right-group" ref="gridContainer" class="grid-container d-flex flex-column" mode="out-in" tag="div"-->
<!--                      :style="{ '&#45;&#45;total': (familyProducts.length + 1) }" v-if="showGridContainer">-->
<!--      <div class="family-container d-flex flex-row" v-for="(family, index) in familyProducts" :key="family.id" :style="{'&#45;&#45;i': (index + 1)}">-->
<!--        <div class="family-info-container d-flex flex-row align-items-center">-->
<!--&lt;!&ndash;          <figure class="family-icon">&ndash;&gt;-->
<!--&lt;!&ndash;            <img class="" :src="family.icon" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;          </figure>&ndash;&gt;-->
<!--          <router-link :to="{name: 'family-product', params: {familyProduct: family.id + '-' + family.path, object: family}}"-->
<!--                       class="bcLiguria family-name" :style="{color: family.color}">{{family.name}}</router-link>-->
<!--        </div>-->
<!--        <div class="family-projects d-none d-sm-flex flex-row" v-if="!isMobileOnly">-->
<!--          <figure class="family-project" v-for="familyProject in getFamilyProjects(family.id)"-->
<!--                  :key="familyProject.id" @click.stop.prevent="showWork(familyProject)">-->
<!--            <img :src="familyProject.fotoFile" alt="">-->
<!--          </figure>-->
<!--          <figure class="family-project" v-for="n in getFamilyProjectsRestantes(family.id)" :key="n"></figure>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="family-container d-flex flex-row" :style="{'&#45;&#45;i': 9}" :key="31">-->
<!--        <div class="family-info-container family-fundermax-info-container d-flex flex-row align-items-center">-->
<!--          <figure class="family-icon">-->
<!--            <FundermaxSvgLogo></FundermaxSvgLogo>-->
<!--          </figure>-->
<!--          <router-link :to="{name: 'Fundermax'}" class="bcLiguria family-name">Fundermax</router-link>-->
<!--        </div>-->
<!--        <div class="family-projects d-none d-sm-flex flex-row" v-if="!isMobile">-->
<!--          <figure class="family-project" v-for="fundermaxProject in getFamilyProjects(31)" :key="fundermaxProject.id">-->
<!--            <img :src="fundermaxProject.fotoFile" alt="">-->
<!--          </figure>-->
<!--        </div>-->
<!--      </div>-->
<!--    </transition-group>-->
<!--  </div>-->
</template>

<script>
import { EventBus } from '@/event-bus'
import { isMobile, isMobileOnly, isMobileSafari, isSafari } from 'mobile-device-detect'
import FundermaxSvgLogo from '../assets/img/Familia-Productos/fundermax-new-black.svg'
import StacBondLogo from '../assets/img/Familia-Productos/StacBondLogo.svg'

export default {
  name: 'GridFamilyDots',
  components: {
    FundermaxSvgLogo,
    StacBondLogo
  },
  props: {
    familyProducts: {
      type: Array
    },
    familyProjects: {
      type: Array
    }
  },
  data () {
    return {
      isMobile,
      isMobileOnly,
      isMobileSafari,
      isSafari,
      showItems: false,
      showImgFamily: true,
      fundermaxLogoPath: 'assets/img/fundermax-logo.svg',
      familyProjectsSorted: {},
      familyProjectsReady: true
    }
  },
  computed: {
    familyProductsToShow () {
      if (!this.showItems) {
        return []
      } else {
        return this.familyProducts
      }
    },
    showGridContainer () {
      return this.familyProducts.length > 0 && this.familyProjectsReady
    }
  },
  methods: {
    sortFamilyProjects () {
      if (this.familyProjects.length > 0) {
        this.familyProjects.forEach((fp) => {
          fp.productos.forEach((p) => {
            const family = p.familia
            if (this.familyProjectsSorted[family.id] === undefined) {
              this.familyProjectsSorted[family.id] = {
                family: family,
                projects: []
              }
            }

            if (this.familyProjectsSorted[family.id].projects.length < 3) {
              let add = true
              this.familyProjectsSorted[family.id].projects.forEach(proj => {
                if (proj.id === fp.id) {
                  add = false
                }
              })
              if (add) {
                this.familyProjectsSorted[family.id].projects.push(fp)
              }
            }
          })
        })
        this.familyProjectsReady = true
      }
    },
    getFamilyProjects (familyId) {
      if (this.familyProjectsSorted[familyId] === undefined || this.familyProjectsSorted[familyId].projects === undefined) {
        return []
      }
      return this.familyProjectsSorted[familyId].projects
    },
    getFamilyProjectsRestantes (familyId) {
      const projects = this.getFamilyProjects(familyId)
      return (3 - projects.length)
    },
    showWork (project) {
      EventBus.$emit('showWork', project)
    }
  },
  mounted () {
    if (!isMobileOnly) {
      this.familyProjectsReady = false
      this.sortFamilyProjects()
    }
    this.showItems = this.familyProducts.length > 0
    EventBus.$emit('toggleHeaderFamilyIcons', false)
  }
}
</script>

<style scoped lang="scss">
  .products-container {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    @include backgroundTransparency(#000000, 0.8);
    .grid-container {
      margin-top: calc(var(--main-space) * 4);
      height: calc(100vh - calc(var(--main-space) * 4));
      overflow: auto;
      background-color: black;
      .family-container {
        height: 150px;
        .family-info-container {
          width: 450px;
          &.family-fundermax-info-container {
            background-color: #000000;
            figure {
              background-color: $familyFundermax;
            }
            a {
              color: $familyFundermax;
            }
          }
          .family-icon {
            margin: 0;
            height: 150px;
            width: 150px;
          }
          .family-name {
            font-size: 30px;
            width: calc(100% - 150px);
            text-align: center;
            color: #000000;
            padding: 0 calc(var(--main-space) / 2);
          }
        }
        .family-projects {
          width: calc(100% - 450px);
          .family-project {
            height: 150px;
            width: calc(100% / 3);
            margin: 0;
            overflow: hidden;
            cursor: pointer;
            img {
              width: 100%;
              @supports (object-fit: cover) {
                object-fit: cover;
                object-position: center;
                height: 100%;
              }
            }
          }
        }
      }
    }
    &.products-container-safari {
      overflow: auto;
      height: 100vh;
      .grid-container {
        padding-top: calc(var(--main-space) * 4);
        margin: 0;
        height: auto;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .products-container {
      .grid-container {
        .family-container {
          .family-projects {
            .family-project {
              width: calc(100% / 2);
              &:last-child {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .products-container {
      .grid-container {
        .family-container {
          .family-projects {
            .family-project {
              width: 100%;
              &:nth-of-type(2) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .products-container {
      .grid-container {
        .family-container {
          .family-info-container {
            width: 100%;
            .family-name {
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  /*.products-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10;
    @include backgroundTransparency(#000000, .8);
    .grid-container {
      margin-top: calc(var(--main-space) * 4);
      height: calc(100% - calc(var(--main-space) * 4));
      //overflow: auto;
      background-color: #000000;
      .family-container {
        height: 150px;
        .family-info-container {
          width: 450px;
          //height: 150px;
          &.family-fundermax-info-container {
            background-color: #000000;
            a {
              color: $familyFundermax;
            }
          }
          .family-icon {
            margin: 0;
            width: 150px;
            height: 150px;
            img, svg {
              width: 100%;
              height: 100%;
            }
          }
          .family-name {
            font-size: 30px;
            width: calc(100% - 150px);
            text-align: center;
            color: #000000;
            padding: 0 calc(var(--main-space) / 2);
          }
        }
        .family-projects {
          width: calc(100% - 450px);
          .family-project {
            height: 150px;
            width: calc(100% / 3);
            margin: 0;
            overflow: hidden;
            cursor: pointer;
            img {
              width: 100%;
              @supports (object-fit: cover) {
                object-fit: cover;
                object-position: center;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .products-container {
      .grid-container {
        .family-container {
          .family-projects {
            .family-project {
              width: calc(100% / 2);
              &:last-child {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .products-container {
      .grid-container {
        .family-container {
          .family-projects {
            .family-project {
              width: 100%;
              &:nth-of-type(2) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .products-container {
      .grid-container {
        height: auto;
        max-height: 100vh;
        overflow-y: auto;
        margin: 0;
        padding-top: calc(var(--main-space) * 3);
        .family-container {
          border: 1px solid red;
          .family-info-container {
            width: 100%;
            .family-icon {
            }
            .family-name {
              font-size: 24px;
            }
          }
        }
      }
      &.products-container-mobile-safari {
        .grid-container {
          .family-container {
            .family-info-container {
            }
          }
        }
      }
    }
  }*/
</style>
