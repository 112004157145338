<template>
  <div class="material-grid-item d-flex flex-column" @click="$emit('selectMaterial', material)">
    <figure class="material-grid-item-img">
      <img :src="material.foto_file" alt="">
    </figure>
    <span class="material-grid-item-name bcLiguria d-flex flex-column align-items-center justify-content-center">{{material.nombre_lang[$i18n.locale]}}</span>
  </div>
</template>

<script>
export default {
  name: 'MaterialGridItem',
  props: ['material']
}
</script>

<style scoped lang="scss">
  .material-grid-item {
    /*height: calc(calc(100vh - calc(var(--main-space) * 4)) / 3);*/
    border: 1px solid $main-color;
    cursor: pointer;
    .material-grid-item-img {
      width: 100%;
      /*height: 75%;*/
      height: 250px;
      margin: 0;
      img {
        width: 100%;
        @supports (object-fit: cover) {
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .material-grid-item-name {
      color: #FFFFFF;
      font-size: 20px;
      padding: calc(var(--main-space) / 2) 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .material-grid-item {
      border: none;
      .material-grid-item-name {
        padding: calc(var(--main-space) * 2) 0;
      }
    }
  }
</style>
