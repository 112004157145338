<template>
  <transition-group name="fade-in-right-group" mode="out-in" class="masonry-container" tag="div" :style="{ '--total': obras.length }">
<!--    <router-link :to="{name: 'Works', params: {work: obraPath(obra), object: obra}}" class="masonry-item" :class="'obra-fotos-' + obra.cuadros"-->
<!--                 v-for="(obra, index) in obras" :key="index" :style="{'&#45;&#45;i': index}" @click.native="$emit('workSelected', obra)">-->
    <a class="masonry-item" :class="'obra-fotos-' + obra.cuadros" v-for="(obra, index) in obras" :key="obra.id"
       :style="{'--i': index}" @click.stop.prevent="showWork(obra)">
    <figure class="masonry-item-foto-container d-flex flex-row">
        <FadeUp
          :src="obra.fotoFile"
          :src-placeholder="require('../assets/img/default-img.jpg')"></FadeUp>
      </figure>
      <div class="division division-x" :class="{white: divisionWhite}"></div>
      <div class="division division-y" :class="{white: divisionWhite}"></div>
      <div class="masonry-item-overlay" :class="{'overlay-effect': productosNoDuplicateByFamily(obra).length < 2}"
           :style="{backgroundColor: getBackgroundColor(obra)}">
        <div class="masonry-item-overlay-info-container position-relative">
          <div class="d-flex flex-row align-items-center w-100 obra-overlay-info-line">
            <span class="obra-overlay-info-text d-none d-sm-block semibold">{{getObraProducts(obra)}}</span>
          </div>
          <div class="d-flex flex-row align-items-center w-100 obra-overlay-info-line obra-overlay-info-line-2">
            <span class="obra-overlay-info-text d-none d-sm-block">{{getFamilias(obra)}}</span>
          </div>
          <div class="d-flex flex-row w-100 obra-overlay-info-line obra-overlay-info-line-1-5">
            <span class="obra-overlay-info-text">{{obra.nombre_lang[$i18n.locale]}}</span>
          </div>
          <div class="d-flex flex-row align-items-center w-100 obra-overlay-info-line">
            <span class="obra-overlay-info-text">{{obra.estudio}}</span>
          </div>
          <div class="d-none d-lg-flex flex-row align-items-center obra-overlay-icons">
            <figure class="obra-overlay-info-icon" v-for="(producto, index) in productosNoDuplicateByFamily(obra)" :key="index">
              <img :class="{'img-svg-white': productosNoDuplicateByFamily(obra).length < 2}" :src="getProductFamilyIcon(producto)" alt="">
            </figure>
          </div>
        </div>
      </div>
    </a>
  </transition-group>
</template>

<script>
import Helper from '../helpers/Helper'
import { overlayObra } from '@/assets/js/animations'
import FadeUp from './lazy-load-images/FadeImage'
import { EventBus } from '@/event-bus'

export default {
  name: 'MasonryObras',
  props: ['obras', 'division-white'],
  components: {
    FadeUp
  },
  methods: {
    getFirstProduct (obra) {
      if (obra.productos !== undefined && obra.productos.length > 0) {
        return obra.productos[0]
      }
      return null
    },
    getObraProducts (obra) {
      let productos = ''
      if (obra.productos !== undefined && obra.productos !== null) {
        obra.productos.forEach((p, index) => {
          productos += p.nombre_lang[this.$i18n.locale]
          if (index < (obra.productos.length - 1)) {
            productos += ' - '
          }
        })
      }
      return productos
    },
    getFamilias (obra) {
      let familias = ''
      if (obra.productos !== undefined && obra.productos !== null) {
        obra.productos.forEach((p, index) => {
          // familias += (p.familia.nombre_lang[this.$i18n.locale] !== undefined) ? p.familia.nombre_lang[this.$i18n.locale] : p.nombre_lang[this.$i18n.locale]
          familias += p.nombre_lang[this.$i18n.locale]
          if (index < (obra.productos.length - 1)) {
            familias += ' - '
          }
        })
      }
      return familias
    },
    getProductName (producto) {
      if (producto !== null) {
        return producto.nombre
      }
      return ''
    },
    getProductFamilyName (producto) {
      if (producto !== null && producto.familia !== undefined) {
        return producto.familia.nombre
      }
      return ''
    },
    getProductFamilyIcon (producto) {
      if (producto !== null && producto.familia !== undefined) {
        return producto.familia.icono
      }
      return ''
    },
    getProductFamilyColor (producto) {
      if (producto !== null && producto.familia !== undefined) {
        return producto.familia.color
      }
      return '#000000'
    },
    obraPath (obra) {
      return Helper.nameToPath(obra.nombre)
    },

    getBackgroundColor (obra) {
      if (this.productosNoDuplicateByFamily(obra).length > 1) {
        return '#000000CC'
      }
      return this.getProductFamilyColor(this.getFirstProduct(obra)) + 'E6'
    },
    productosNoDuplicateByFamily (obra) {
      if (obra.productos !== undefined) {
        const prodNoDuply = []
        obra.productos.forEach((p) => {
          if (!prodNoDuply.some((p2) => p2.familia.id === p.familia.id)) {
            prodNoDuply.push(p)
          }
        })
        return prodNoDuply
      }
      return []
    },
    showWork (obra) {
      EventBus.$emit('showWork', obra)
    }
  },
  mounted () {
    overlayObra()
  }
}
</script>

<style scoped lang="scss">
  .masonry-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, calc(25% - 1px));
    grid-auto-flow: row dense;
    grid-gap: 1px;
    background-color: #000000;

    .masonry-item {
      width: 100%;
      padding-top: 100%;
      position: relative;
      cursor: pointer;
      background-color: #000000;
      &.inViewPort {
        transition-delay: .1s;
        transition-duration: .25s;
        transition-timing-function: ease-in;
        transform: translateY(0);
        opacity: 1;
      }

      .masonry-item-overlay {
        position: relative;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: calc(var(--main-space) / 2);
        display: none;
        .masonry-item-overlay-info-container {
          width: 100%;
          height: 100%;
          padding: 0 calc(var(--main-space) / 2) 0 0;
        }

        .obra-overlay-icons {
          position: absolute;
          /*bottom: calc(var(--main-space) / 2);*/
          /*left: calc(var(--main-space) / 2);*/
          left: 0;
          bottom: 0;
          figure {
            margin: 0;
            margin-right: calc(var(--main-space) / 2);
          }
        }
      }

      .masonry-item-foto-container {
        width: 100%;
        height: 100%;
        position: relative;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        margin: 0;

        img {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          transition: all 0.5s ease-in-out;
        }
      }

      .division {
        position: absolute;
        background-color: #000000;
        display: none;
        z-index: 9;

        &.white {
          background-color: #FFFFFF;
        }

        &.division-x {
          width: 100%;
          height: 1px;
          top: 50%;
          left: 0;
          margin-top: -1px;
        }
        &.division-y {
          width: 1px;
          height: 100%;
          top: 0;
          left: 50%;
        }
      }

      &.obra-fotos-2 {
        background-color: lightblue;
        grid-column: span 2;
        padding-top: 50%;

        .division-y {display: block}

        .masonry-item-overlay {
          .masonry-item-overlay-info-container {
            width: 50%;
          }
        }
      }
      &.obra-fotos-4 {
        grid-column: span 2;
        grid-row: span 2;
        padding-top: 100%;

        .division-x {display: block}
        .division-y {display: block}

        .masonry-item-overlay {
          cursor: pointer;
          .masonry-item-overlay-info-container {
            width: 50%;
            height: 50%;
            padding-bottom: calc(var(--main-space) / 2);
          }
          &.overlay-effect {
            mix-blend-mode: hard-light;
          }
          .obra-overlay-icons {
            /*bottom: calc(calc(100% / 2) + var(--main-space));*/
            bottom: calc(var(--main-space) / 2);
          }
        }
      }
      .obra-overlay-info-line {
        //margin-bottom: calc(var(--main-space)/5*1.5);
        margin-bottom: 0;
        &.obra-overlay-info-line-1-5 {
          margin-bottom: calc(var(--main-space)/5*1.5);
        }
        &.obra-overlay-info-line-2 {
          margin-bottom: calc(var(--main-space)/5*4.5);
        }
      }

      .obra-overlay-info-text {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        color: #FFFFFF;
        &.semibold {
          font-weight: 500;
        }
      }
      .obra-overlay-info-icon {
        width: var(--main-space);
        height: var(--main-space);
      }

      &:hover > .masonry-item-overlay {
        display: block;
      }
      &:hover > .overlay-effect {
        mix-blend-mode: hard-light;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .masonry-container {
      .masonry-item {
        .masonry-item-overlay {
          .obra-overlay-info-text {
            font-size: 14px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .masonry-container {
      .masonry-item {
        .masonry-item-overlay {
          .obra-overlay-info-line-2 {
            margin-bottom: calc(var(--main-space)/5*1.5);
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .masonry-container {
      grid-template-columns: repeat(auto-fit, calc(50% - 1px));

      .masonry-item {
        .masonry-item-overlay {
          display: block;
          opacity: 0;
          .obra-overlay-info-text {
            font-size: 12px;
            display: block;
            width: 100%;
          }
          .obra-overlay-info-line-2 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>
