<template>
  <div></div>
</template>

<script>
export default {
  name: 'PageNotFound',
  mounted () {
    window.location.href = '/'
  }
}
</script>

<style scoped>

</style>
