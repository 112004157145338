<template>
  <div>
    <div class="contact-container position-relative" v-if="!showModalContactForm">
      <div class="contact-map w-100" v-if="!isMobile">
<!--        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3288.9601937146635!2d-58.662615784177895!3d-34.4785340583933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bca369df551489%3A0xdc135d82ba636953!2sGrupo%20MSH%20S.A.!5e0!3m2!1ses-419!2sar!4v1591148429677!5m2!1ses-419!2sar" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>-->
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3288.9486040558118!2d-58.66167958417801!3d-34.47882805840884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bca369df551489%3A0xdc135d82ba636953!2sGrupo%20MSH%20S.A.!5e0!3m2!1ses-419!2sar!4v1663686813097!5m2!1ses-419!2sar" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div class="contact-grid w-100 d-flex flex-column" v-if="!isMobile">
        <div class="contact-grid-row d-flex flex-row" id="row-1">
          <div class="contact-grid-cell" v-for="i in 9" :key="'row-1-' + i"></div>
        </div>
        <div class="contact-grid-row d-flex flex-row" id="row-2">
          <div class="contact-grid-cell contact-grid-cell-3" style="background-color: #000000;">
            <div class="d-flex flex-column contact-info">
              <span class="contact-info-tit">{{$t('contact.title')}}</span>
              <span class="contact-info-dir">{{$t('contact.address')}}</span>
              <a href="tel:+541152630413"><span class="contact-info-tel">{{$t('contact.tel.code')}} <strong>{{$t('contact.tel.num')}}</strong> {{$t('contact.tel.text')}}</span></a>
              <span class="contact-info-form d-flex flex-row" @click="showModalContactForm = true">{{$t('contact.form')}} <LogoMail></LogoMail></span>
            </div>
          </div>
          <div class="contact-grid-cell" v-for="i in 6" :key="'row-2-' + i"></div>
        </div>
        <div class="contact-grid-row d-flex flex-row" id="row-3">
          <div class="contact-grid-cell" v-for="(i) in 9" :key="'row-3-' + i"></div>
        </div>
        <div class="contact-grid-row d-none flex-row" id="row-4">
          <div class="contact-grid-cell" v-for="(i) in 9" :key="'row-4-' + i"></div>
        </div>
        <div class="contact-grid-row d-none flex-row" id="row-5">
          <div class="contact-grid-cell" v-for="(i) in 9" :key="'row-5-' + i"></div>
        </div>
        <div class="contact-grid-row d-flex flex-row">
          <div class="contact-grid-cell"></div>
          <div class="contact-grid-cell"></div>
          <div class="contact-grid-cell"></div>
          <div class="contact-grid-cell"></div>
          <div class="contact-grid-cell"></div>
          <div class="contact-grid-cell"></div>
          <div class="contact-grid-cell"></div>
          <div class="contact-grid-cell"></div>
          <div class="contact-grid-cell"></div>
        </div>
      </div>

      <div class="contact-grid w-100 d-flex flex-column" v-if="isMobile">
        <div class="contact-grid-row d-flex flex-row" id="row-mobile-2">
          <div class="contact-grid-cell contact-grid-cell-2" style="background-color: #000000;">
            <div class="d-flex flex-column contact-info">
              <span class="contact-info-tit">{{$t('contact.title')}}</span>
              <span class="contact-info-dir">{{$t('contact.address')}}</span>
              <span class="contact-info-tel">{{$t('contact.tel.code')}} <strong>{{$t('contact.tel.num')}}</strong> / {{$t('contact.tel.text')}}</span>
              <div class="contact-mobile-redes-container d-flex flex-row align-items-center">
                <a href="https://www.instagram.com/grupomshsa/?hl=es-la" target="_blank"><LogoInstagram></LogoInstagram></a>
                <a href="https://www.linkedin.com/company/grupo-msh-s-a-/" target="_blank"><LogoLinkedin></LogoLinkedin></a>
                <a href="https://www.youtube.com/channel/UCZxI91Z8g2UYtYaQjDeWkAw" target="_blank"><LogoYoutube></LogoYoutube></a>
                <div @click="showModalContactForm = true"><LogoMail></LogoMail></div>
                <a :href="'http://wa.me/' + constantes.whatsapp_number" target="_blank"><TelefonoLogo></TelefonoLogo></a>
                <a href="tel:+541152630413" target="_blank"><LogoWhatsApp></LogoWhatsApp></a>
              </div>
              <span class="contact-info-form d-flex flex-row" @click="showModalContactForm = true">{{$t('contact.form')}}  <LogoMail></LogoMail></span>
            </div>
          </div>
        </div>
        <div class="contact-map position-relative">
<!--          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3288.9601937146635!2d-58.662615784177895!3d-34.4785340583933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bca369df551489%3A0xdc135d82ba636953!2sGrupo%20MSH%20S.A.!5e0!3m2!1ses-419!2sar!4v1591148429677!5m2!1ses-419!2sar" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>-->
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3288.9486040558118!2d-58.66167958417801!3d-34.47882805840884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bca369df551489%3A0xdc135d82ba636953!2sGrupo%20MSH%20S.A.!5e0!3m2!1ses-419!2sar!4v1663686813097!5m2!1ses-419!2sar" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <div class="contact-grid-row d-flex flex-row" id="row-mobile-3">
            <div class="contact-grid-cell" v-for="(i) in 3" :key="'row-mobile-3-' + i"></div>
          </div>
          <div class="contact-grid-row d-flex flex-row" id="row-mobile-4">
            <div class="contact-grid-cell" v-for="(i) in 3" :key="'row-mobile-4-' + i"></div>
          </div>
          <div class="contact-grid-row d-flex flex-row" id="row-mobile-5">
            <div class="contact-grid-cell" v-for="(i) in 3" :key="'row-mobile-5-' + i"></div>
          </div>
          <div class="contact-grid-row d-flex flex-row" id="row-mobile-6">
            <div class="contact-grid-cell" v-for="(i) in 3" :key="'row-mobile-6-' + i"></div>
          </div>
          <div class="contact-grid-row d-flex flex-row" id="row-mobile-7">
            <div class="contact-grid-cell" v-for="(i) in 3" :key="'row-mobile-7-' + i"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-container d-flex flex-column justify-content-center align-items-center" v-else>
      <CloseIcon class="close-icon" @click="showModalContactForm = false"></CloseIcon>
      <div class="modal-content d-flex flex-column p-4">
        <h3 class="bcLiguria">Envianos tu consulta!</h3>
        <div class="form d-flex flex-column mt-4">
          <label for="contact-form-name">Nombre y apellido</label>
          <input type="text" placeholder="Ingrese su nombre y apellido" id="contact-form-name" class="p-2" v-model="contactFormData.name">
          <label for="contact-form-email" class="mt-4">Correo electrónico</label>
          <input type="email" placeholder="Ingrese su correo electrónico" id="contact-form-email" class="p-2" v-model="contactFormData.email">
          <label for="contact-form-phone" class="mt-4">Teléfono</label>
          <input type="tel" placeholder="Ingrese su Teléfono" id="contact-form-phone" class="p-2" v-model="contactFormData.phone">
          <label for="contact-form-message" class="mt-4">Mensaje</label>
          <textarea name="contact-form-message" id="contact-form-message" placeholder="Ingrese su mensaje..." class="p-2" v-model="contactFormData.message"></textarea>
          <div class="d-flex flex-row justify-content-start align-items-center mt-5">
            <b-button @click="sendEmail">Enviar</b-button>
            <span class="ml-4 contact-form-send-info">{{ contactFormSendInfo }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import LogoMail from '../assets/img/mail-icon.svg'
import LogoLinkedin from '../assets/img/linkedin-icon.svg'
import LogoInstagram from '../assets/img/instagram-icon.svg'
import LogoYoutube from '../assets/img/youtube-icon.svg'
import LogoWhatsApp from '../assets/img/whatsapp.svg'
import TelefonoLogo from '@/assets/img/telefono-icon.svg'
import Helper from '../helpers/Helper'
import { isMobile } from 'mobile-device-detect'
import { EventBus } from '@/event-bus'
import constantes from '../constantes.json'
import CloseIcon from '../assets/img/close-icon.svg'
import axios from 'axios'

export default {
  name: 'Contact',
  components: {
    LogoMail,
    LogoInstagram,
    LogoLinkedin,
    LogoYoutube,
    LogoWhatsApp,
    TelefonoLogo,
    CloseIcon
  },
  data () {
    return {
      isMobile,
      constantes: constantes,
      showModalContactForm: false,
      contactFormData: {
        name: '',
        email: '',
        phone: '',
        message: ''
      },
      contactFormSendInfo: ''
    }
  },
  methods: {
    calcHeights () {
      if (Helper.isInURL('contact')) {
        if (!isMobile) {
          const contactGridHeight = document.getElementsByClassName('contact-grid')[0].offsetHeight
          document.getElementsByClassName('contact-map')[0].style.height = contactGridHeight + 'px'
          document.getElementsByClassName('contact-container')[0].style.height = contactGridHeight + 'px'
        }
      }
    },
    async sendEmail () {
      if (this.validateContactForm()) {
        this.contactFormSendInfo = 'Enviando...'
        try {
          const res = await axios.post('https://grupomsh.com.ar/API/send-email.php', this.contactFormData)
          this.contactFormSendInfo = res.data.response
          this.contactFormData.name = ''
          this.contactFormData.email = ''
          this.contactFormData.phone = ''
          this.contactFormData.message = ''
          return res
        } catch (err) {
          this.contactFormSendInfo = 'Surgio un error al enviar el mail. Por favor intente mas tarde.'
          throw err.response
        }
      } else {
        this.contactFormSendInfo = 'Complete los datos correctamente.'
      }
    },
    validateContactForm () {
      return !Helper.isEmpty(this.contactFormData.name) && Helper.isValidMail(this.contactFormData.email)
    }
  },
  mounted () {
    setTimeout(() => {
      this.calcHeights()
    }, 1000)

    this.showModalContactForm = (this.$route.params.form !== undefined) ? this.$route.params.form : false

    window.addEventListener('resize', (e) => {
      this.calcHeights()
    })

    EventBus.$emit('showTitlePage', 'Contacto')
    EventBus.$emit('showContactsIcons', true)
  },
  watch: {
    '$i18n.locale': {
      handler () {
        EventBus.$emit('showTitlePage', this.$t('contact.pageTitle'))
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .contact-container {
    width: 100%;
    margin-top: calc(var(--main-space) * 4);
    background-color: #000000;
    min-height: calc(100vh - calc(var(--main-space) * 4) - 52px);
  }
  .contact-map iframe {
    width: 100%;
    height: 100%;
  }
  .contact-grid {
    position: absolute;
    left: 0;
    top: 0;
    @include backgroundTransparency(#000000, .1);
    pointer-events: none;
  }

  .contact-grid-cell {
    position: relative;
    border: 1px solid #000000;
    width: calc(100% / 9);
    padding-top: calc(100% / 9);
  }
  .contact-grid-cell-2 {width: calc(calc(100% / 9) * 2);}
  .contact-grid-cell-3 {width: calc(calc(100% / 9) * 3);}

  .contact-grid-borderless {
    border: none;
  }

  .contact-grid-cell span.contact-text {
    position: absolute;
    top: 50%;
    left: var(--main-space);
    transform: translateY(-50%);
    font-size: 48px;
    font-weight: 600;
  }
  .contact-info {
    width: 100%;
    padding: var(--main-space);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: auto;
  }
  .contact-info span,
  .contact-info span strong,
  .contact-info a
  {
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
  }
  .contact-info span.contact-info-tit {
    font-weight: 700;
    svg {
      width: calc(var(--main-space) / 2);
      fill: #FFFFFF;
      margin-left: calc(var(--main-space) / 2);
      cursor: pointer;
    }
  }
  .contact-info span.contact-info-dir {
    font-weight: 400;
    margin: calc(var(--main-space) / 3) 0;
  }
  .contact-info span.contact-info-tel {
    font-weight: 400;
  }
  .contact-info .contact-info-como-llegar {
    font-weight: 400;
    margin-top: calc(var(--main-space) / 3);
  }
  .contact-info span.contact-info-form {
    font-weight: 700;
    margin-top: calc(var(--main-space) / 3);
    cursor: pointer;

    svg {
      width: 20px;
      margin-left: calc(var(--main-space) / 2);
      * {
        fill: #FFFFFF;
      }
    }
  }

  .contact-logo-container a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    height: 35%;
  }
  .contact-logo-container a svg,
  .contact-logo-container a svg path
  {
    fill: #000000;
  }
  .contact-logo-container a {
    pointer-events: all;
  }

  // OCULTO ITEMS DEL MAPA
  .gm-inset-map-impl {display: none !important;}

  // MODAL DE CONTACT FORM
  .modal-container {
    position: fixed;
    position: relative;
    z-index: 999;
    background-color: #000;
    width: 100%;
    height: 100vh !important;
    .close-icon {
      position: absolute;
      top: var(--main-space);
      right: var(--main-space);
      width: var(--main-space);
      fill: #FFFFFF;
    }
    .modal-content {
      width: 500px;
      max-width: 90%;
      border: 1px solid grey;
      background-color: #000;
      border-radius: 0;
      h3 {
        color: #FFF;
      }
      .form {
        label {
          color: #FFF;
        }
        input, textarea {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #FFF;
          color: #FFF;
        }
        textarea {
          height: 80px;
        }
        ::v-deep {
          .btn {
            border-radius: 0;
            background-color: #FFF;
            color: #000;
          }
        }
        .contact-form-send-info {
          color: #FFFFFF;
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    #row-4 {
      display: flex !important;
    }
    .contact-grid-cell span.contact-text {
      font-size: 36px;
    }
    .contact-info a,
    .contact-info span,
    .contact-info span strong
    {
      font-size: 18px;
    }
    .contact-info span.contact-info-dir {
      margin: calc(var(--main-space) / 3) 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .contact-info a,
    .contact-info span,
    .contact-info span strong
    {
      font-size: 14px;
    }
  }

  @include media-breakpoint-down(md) {
    #row-5 {
      display: flex !important;
    }
    .contact-grid-cell span.contact-text {
      font-size: 24px;
    }
    .contact-info span,
    .contact-info span strong
    {
      font-size: 0.8rem;
    }
    .contact-info-como-llegar {
      display: none;
    }
    .contact-info span.contact-info-dir {
      margin: calc(var(--main-space) / 4) 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .contact-container {
      /*min-height: 100vh;*/
      min-height: calc(100vh - calc(var(--main-space) * 3));
      margin-top: calc(var(--main-space) * 3);
      /*background-color: #FFFFFF;*/
      #row-mobile-1 {
        background-color: #FFFFFF;
      }

      .contact-grid {
        background: transparent;
        position: static;
        pointer-events: auto;
        .contact-grid-cell {
          position: relative;
          border: 1px solid #000000;
          width: calc(100% / 3);
          padding-top: calc(100% / 3);

          span.contact-text {
            font-size: 48px;
          }

          .contact-info span,
          .contact-info span strong
          {
            font-size: 14px;
          }
          .contact-info-como-llegar {
            display: block;
          }
          .contact-mobile-redes-container {
            margin-top: calc(var(--main-space) / 2);
            a {
              svg {
                fill: #FFFFFF;
                //width: calc(var(--main-space) / 3 * 2);
                width: calc(var(--main-space) / 5 * 4);
                path {
                  fill: #FFFFFF;
                }
              }
              &:not(:last-child) {
                margin-right: calc(var(--main-space) / 2);
              }
            }
          }
        }
        .contact-grid-cell-2 {
          width: calc(100% * 2);
          padding-top: calc(100% / 3);
        }

        .contact-map {
          pointer-events: none;
          iframe {
            position: absolute;
          }
        }
      }
    }
  }
</style>
