<template>
  <div class="about scrollAnimationOverlayParent">
    <div class="background-container-mobile" id="background-container-mobile"></div>
    <div class="video-container">
      <figure class="gptw-logo-container">
<!--        <img :src="require('../assets/img/gptw.png')" alt="Great place to work logo blanco">-->
        <GPTWLogo />
      </figure>
      <div class="about-overlay scrollAnimationOverlay" id="about-overlay"></div>
      <div class="wrapper">
        <div class="d-flex flex-column justify-content-center fullH scrollAnimation1">
          <span class="text-white bcLiguria"></span>
        </div>
        <div class="d-flex align-items-center fullH animationText scrollAnimation2">
          <p class="text-white bcLiguria about-description">{{$t('gptw.text')}}</p>
        </div>
      </div>
    </div>
    <a href="mailto:rrhh@grupomsh.com.ar" class="header-mail-icon" v-if="showMailIcon">
      <MailIcon />
    </a>
  </div>
</template>

<script>
import { fadeInOutSection, backgroundOverlay } from '@/assets/js/animations'
import { EventBus } from '@/event-bus'
import Helper from '@/helpers/Helper'
import GPTWLogo from '@/assets/img/gptw-blanco.svg'
import MailIcon from '@/assets/img/mail-icon.svg'

export default {
  name: 'GPTW',
  components: {
    GPTWLogo,
    MailIcon
  },
  data () {
    return {
      styleOverlay: {
        backgroundColor: 'rgba(0,0,0,0)'
      },
      showMailIcon: true
    }
  },
  mounted () {
    fadeInOutSection()
    backgroundOverlay()

    window.addEventListener('scroll', (e) => {
      const footerHeight = Helper.isBottomOfPage() ? (document.getElementsByClassName('footer')[0].clientHeight) : 0

      if (document.getElementById('background-container-mobile') !== null) {
        document.getElementById('background-container-mobile').style.transform = 'translateY(-' + footerHeight + 'px)'
      }
      if (document.getElementById('about-overlay') !== null) {
        document.getElementById('about-overlay').style.transform = 'translateY(-' + footerHeight + 'px)'
      }
    })

    EventBus.$emit('showTitlePage', this.$t('gptw.pageTitle'))
    EventBus.$emit('toggleHeaderFamilyIcons', false)

    EventBus.$on('showMailIcon', (toggle) => {
      this.showMailIcon = toggle
    })
  },
  watch: {
    '$i18n.locale': {
      handler () {
        EventBus.$emit('showTitlePage', this.$t('gptw.pageTitle'))
      }
    }
  }
}
</script>

<style lang="scss">
.about {
  .header-mail-icon {
    width: var(--main-space);
    position: fixed;
    top: var(--main-space);
    z-index: 11;
    right: calc(calc(calc(3*var(--main-space)) + var(--main-space)) + calc(3*calc(var(--main-space) / 5 * 4)));
    svg, path {
      fill: #FFF;
    }
  }
  .background-container-mobile {
    //background-image: url('../assets/img/gptw/gptw-color_background.jpg');
    background-image: url('../assets/img/gptw/gptw_background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  .video-container {
    width: 100%;
    height: 100%;
    z-index: -2;
    overflow: hidden;

    .gptw-logo-container {
      position: fixed;
      bottom: var(--main-space);
      right: var(--main-space);
      width: 80px;
      //z-index: 999;
      img {
        width: 100%;
      }
    }

    .video-player-container {
      /*width: 100%;*/
      /*height: 100vh;*/
      position: fixed;
      top: 0;
      left: 0;

      height: 100%;
      /*width: 177.77777778vh; !* 100 * 16 / 9 *!*/
      /*min-height: 56.25vw; !* 100 * 9 / 16 *!*/
      width: calc(100vh * 16 / 9); /* 100 * 16 / 9 */
      min-width: 100%;
      min-height: calc(100vw * 9 / 16); /* 100 * 9 / 16 */

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .about-overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0;
    }
  }

  .about-sections-container {
    position: relative;
  }
  .about-description {
    font-size: 36px;
    width: 80%;
    line-height: calc(var(--main-space)/5*6);
  }

  .about-section {
    padding-top: var(--main-space);
    .title-section {
      color: #FFFFFF;
      margin-bottom: var(--main-space);
      height: 40px;
    }
  }
}

@media screen and (min-width: 1240px) and (max-width: 1240px)
and (min-height: 600px) and (max-height: 800px) {
  .background-container-mobile {
    background-position: top;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
}

@include media-breakpoint-down(lg) {
  .about {
    .about-description {
      font-size: 30px;
      line-height: 150%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .about {
    .video-container {

      .gptw-logo-container {
        width: 50px;
      }
    }
    .about-description {
      font-size: 16px;
      width: 90%;
    }
    .about-section {
      .title-section {
        font-size: 28px;
        height: auto;
      }
    }
  }
}
</style>
