<template>
  <div class="stac-bond-popup-container">
    <CloseIcon class="close-icon" @click="$emit('close')" />
    <div class="wrapper">
      <div class="d-flex flex-column popup-text-container">
        <p class="bcLiguria text-white" v-for="(text, i) in $t('stacBond.popupText')" :key="'stacbond-popup-text' + i">{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '../assets/img/close-icon.svg'
export default {
  name: 'StacBondPopup',
  components: {
    CloseIcon
  }
}
</script>

<style scoped lang="scss">
.stac-bond-popup-container {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  @include backgroundTransparency(#000000, 0.9);
  z-index: 999;
  .close-icon {
    width: var(--main-space);
    position: absolute;
    right: var(--main-space);
    top: var(--main-space);
    cursor: pointer;
    path {
      fill: #FFFFFF;
    }
  }

  .popup-text-container {
    margin-top: calc(var(--main-space) * 5);
    p {
      font-size: 32px;
      width: 80%;
      line-height: calc(var(--main-space)/5*6);
      margin-bottom: var(--main-space);
    }
  }
}
</style>
