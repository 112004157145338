<template>
  <MasonryObras :obras="obras"></MasonryObras>
</template>

<script>
import obras from '../assets/js/obras-home'
import MasonryObras from '../components/MasonryObras'
export default {
  name: 'Masonry',
  components: {
    MasonryObras
  },
  data () {
    return {
      obras: obras
    }
  },
  mounted () {
    document.getElementsByClassName('header')[0].style.display = 'none'
  }
}
</script>

<style scoped lang="scss">
</style>
