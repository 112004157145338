<template>
  <div class="wallpapers-parent">
    <div class="wrapper">
      <div @click="downloadAllWallpapers()"
         class="d-inline-flex flex-row align-items-center justify-content-center img-download-container">
        <span class="bcLiguria">{{$t('wallpapers.downloadAll')}} ({{totalSize}} Mb)</span>
        <div class="d-flex flex-column justify-content-center">
          <DownloadIcon></DownloadIcon>
        </div>
      </div>
    </div>
    <div class="wallpapers-container">
      <WallpaperCard v-for="(wallpaper, index) in wallpapers" :key="'wallpaper-' + index" :wallpaper="wallpaper"></WallpaperCard>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import wallpapers from '@/assets/js/wallpapers.json'
import WallpaperCard from '@/components/WallpaperCard'
import DownloadIcon from '@/assets/img/download-icon.svg'
import JSZip from 'jszip'
import saveAs from 'jszip/vendor/FileSaver'
// import JSZipUtils from 'jszip-utils'

export default {
  name: 'Wallpapers',
  components: {
    WallpaperCard,
    DownloadIcon
  },
  data () {
    return {
      wallpapers: wallpapers
    }
  },
  computed: {
    totalSize () {
      let total = 0
      this.wallpapers.forEach(w => { total += parseFloat(w.size) })
      return total.toFixed(2)
    }
  },
  methods: {
    getBase64Image (img) {
      // Create an empty canvas element
      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      // Copy the image contents to the canvas
      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      // Get the data-URL formatted image
      // Firefox supports PNG and JPEG. You could check img.src to guess the
      // original format, but be aware the using "image/jpg" will re-encode the image.
      var dataURL = canvas.toDataURL('image/png')

      return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
    },
    downloadAllWallpapers () {
      EventBus.$emit('toggleLoading', true)
      const zip = new JSZip()
      const img = zip.folder('wallpapers')

      this.wallpapers.forEach(w => {
        const imgPath = 'assets/img/wallpapers/' + w.img
        const image = new Image()
        image.src = require('../' + imgPath)
        const base64 = this.getBase64Image(image)
        img.file(w.img, base64, { base64: true })
      })

      zip.generateAsync({
        type: 'blob'
      })
        .then((content) => {
          // see FileSaver.js
          saveAs(content, 'wallpapers.zip')
          EventBus.$emit('toggleLoading', false)
        })
        .catch(() => {
          EventBus.$emit('toggleLoading', false)
        })
    }
  },
  mounted () {
    EventBus.$emit('showTitlePage', 'Wallpapers')
  }
}
</script>

<style scoped lang="scss">

.wallpapers-parent {
  padding-top: calc(var(--main-space) * 4);

  .img-download-container {
    cursor: pointer;
    background-color: #FFF;
    margin: 0 0 calc(var(--main-space) / 2) 0;
    height: 40px;
    padding: 0 calc(var(--main-space) / 2);

    span {
      color: #000;
      margin-right: calc(var(--main-space) / 2);
    }
    svg {
      width: 20px;
    }
    svg, path {
      fill: #000;
    }
  }

  .wallpapers-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row dense;
    grid-gap: 1px;
    min-height: 100vh;
    transition: all 0.5s ease-in-out;
  }
}

@include media-breakpoint-down(sm) {
  .wallpapers-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

</style>
