import Service from './Service'
export default class MaterialesService extends Service {
  constructor () {
    super()
    this.paramsURL = {
      materiales: 'materiales.php'
    }
  }

  static parse (material) {
    const m = material
    m.showInfo = false
    return m
  }

  findAll (params, callbackSuccess, callbackHandleError) {
    let stringParams = '?'
    let i = 0
    for (const param in params) {
      if (i > 0) {
        stringParams += '&'
      }
      stringParams += param + '=' + params[param]
      i++
    }
    this.ajax('GET', this.paramsURL.materiales + stringParams, {}, callbackSuccess, callbackHandleError)
  }
}
