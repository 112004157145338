<template>
  <div class="d-flex justify-content-center align-items-center loading-container" :class="{'inside-container': insideContainer}">
    <div id='loader'></div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    insideContainer: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  #loader {
    animation: spin 2s linear infinite;
    height: 10px;
    width: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -5px;
  }

  @keyframes spin {
    0% {
      box-shadow:
        0px -30px $spinner,
        10px -30px $spinner,
        20px -20px $spinner,
        30px -10px $spinner,
        30px 0px $spinner,
        30px 10px $spinner,
        20px 20px $spinner,
        10px 30px $spinner,
        0px 30px transparent,
        -10px 30px transparent,
        -20px 20px transparent,
        -30px 10px transparent,
        -30px 0px transparent,
        -30px -10px transparent,
        -20px -20px transparent,
        -10px -30px transparent;
    }
    6.25% {
      box-shadow:
        0px -30px transparent,
        10px -30px $spinner,
        20px -20px $spinner,
        30px -10px $spinner,
        30px 0px $spinner,
        30px 10px $spinner,
        20px 20px $spinner,
        10px 30px $spinner,
        0px 30px $spinner,
        -10px 30px transparent,
        -20px 20px transparent,
        -30px 10px transparent,
        -30px 0px transparent,
        -30px -10px transparent,
        -20px -20px transparent,
        -10px -30px transparent;
    }
    12.5% {
      box-shadow:
        0px -30px transparent,
        10px -30px transparent,
        20px -20px $spinner,
        30px -10px $spinner,
        30px 0px $spinner,
        30px 10px $spinner,
        20px 20px $spinner,
        10px 30px $spinner,
        0px 30px $spinner,
        -10px 30px $spinner,
        -20px 20px transparent,
        -30px 10px transparent,
        -30px 0px transparent,
        -30px -10px transparent,
        -20px -20px transparent,
        -10px -30px transparent;
    }
    18.75% {
      box-shadow:
        0px -30px transparent,
        10px -30px transparent,
        20px -20px transparent,
        30px -10px $spinner,
        30px 0px $spinner,
        30px 10px $spinner,
        20px 20px $spinner,
        10px 30px $spinner,
        0px 30px $spinner,
        -10px 30px $spinner,
        -20px 20px $spinner,
        -30px 10px transparent,
        -30px 0px transparent,
        -30px -10px transparent,
        -20px -20px transparent,
        -10px -30px transparent;
    }
    25% {
      box-shadow:
        0px -30px transparent,
        10px -30px transparent,
        20px -20px transparent,
        30px -10px transparent,
        30px 0px $spinner,
        30px 10px $spinner,
        20px 20px $spinner,
        10px 30px $spinner,
        0px 30px $spinner,
        -10px 30px $spinner,
        -20px 20px $spinner,
        -30px 10px $spinner,
        -30px 0px transparent,
        -30px -10px transparent,
        -20px -20px transparent,
        -10px -30px transparent;
    }
    31.25% {
      box-shadow:
        0px -30px transparent,
        10px -30px transparent,
        20px -20px transparent,
        30px -10px transparent,
        30px 0px transparent,
        30px 10px $spinner,
        20px 20px $spinner,
        10px 30px $spinner,
        0px 30px $spinner,
        -10px 30px $spinner,
        -20px 20px $spinner,
        -30px 10px $spinner,
        -30px 0px $spinner,
        -30px -10px transparent,
        -20px -20px transparent,
        -10px -30px transparent;
    }
    37.5% {
      box-shadow:
        0px -30px transparent,
        10px -30px transparent,
        20px -20px transparent,
        30px -10px transparent,
        30px 0px transparent,
        30px 10px transparent,
        20px 20px $spinner,
        10px 30px $spinner,
        0px 30px $spinner,
        -10px 30px $spinner,
        -20px 20px $spinner,
        -30px 10px $spinner,
        -30px 0px $spinner,
        -30px -10px $spinner,
        -20px -20px transparent,
        -10px -30px transparent;
    }
    43.75% {
      box-shadow:
        0px -30px transparent,
        10px -30px transparent,
        20px -20px transparent,
        30px -10px transparent,
        30px 0px transparent,
        30px 10px transparent,
        20px 20px transparent,
        10px 30px $spinner,
        0px 30px $spinner,
        -10px 30px $spinner,
        -20px 20px $spinner,
        -30px 10px $spinner,
        -30px 0px $spinner,
        -30px -10px $spinner,
        -20px -20px $spinner,
        -10px -30px transparent;
    }
    50% {
      box-shadow:
        0px -30px transparent,
        10px -30px transparent,
        20px -20px transparent,
        30px -10px transparent,
        30px 0px transparent,
        30px 10px transparent,
        20px 20px transparent,
        10px 30px transparent,
        0px 30px $spinner,
        -10px 30px $spinner,
        -20px 20px $spinner,
        -30px 10px $spinner,
        -30px 0px $spinner,
        -30px -10px $spinner,
        -20px -20px $spinner,
        -10px -30px $spinner;
    }
    56.25% {
      box-shadow:
        0px -30px $spinner,
        10px -30px transparent,
        20px -20px transparent,
        30px -10px transparent,
        30px 0px transparent,
        30px 10px transparent,
        20px 20px transparent,
        10px 30px transparent,
        0px 30px transparent,
        -10px 30px $spinner,
        -20px 20px $spinner,
        -30px 10px $spinner,
        -30px 0px $spinner,
        -30px -10px $spinner,
        -20px -20px $spinner,
        -10px -30px $spinner;
    }
    62.5% {
      box-shadow:
        0px -30px $spinner,
        10px -30px $spinner,
        20px -20px transparent,
        30px -10px transparent,
        30px 0px transparent,
        30px 10px transparent,
        20px 20px transparent,
        10px 30px transparent,
        0px 30px transparent,
        -10px 30px transparent,
        -20px 20px $spinner,
        -30px 10px $spinner,
        -30px 0px $spinner,
        -30px -10px $spinner,
        -20px -20px $spinner,
        -10px -30px $spinner;
    }
    68.75% {
      box-shadow:
        0px -30px $spinner,
        10px -30px $spinner,
        20px -20px $spinner,
        30px -10px transparent,
        30px 0px transparent,
        30px 10px transparent,
        20px 20px transparent,
        10px 30px transparent,
        0px 30px transparent,
        -10px 30px transparent,
        -20px 20px transparent,
        -30px 10px $spinner,
        -30px 0px $spinner,
        -30px -10px $spinner,
        -20px -20px $spinner,
        -10px -30px $spinner;
    }
    75% {
      box-shadow:
        0px -30px $spinner,
        10px -30px $spinner,
        20px -20px $spinner,
        30px -10px $spinner,
        30px 0px transparent,
        30px 10px transparent,
        20px 20px transparent,
        10px 30px transparent,
        0px 30px transparent,
        -10px 30px transparent,
        -20px 20px transparent,
        -30px 10px transparent,
        -30px 0px $spinner,
        -30px -10px $spinner,
        -20px -20px $spinner,
        -10px -30px $spinner;
    }
    81.25% {
      box-shadow:
        0px -30px $spinner,
        10px -30px $spinner,
        20px -20px $spinner,
        30px -10px $spinner,
        30px 0px $spinner,
        30px 10px transparent,
        20px 20px transparent,
        10px 30px transparent,
        0px 30px transparent,
        -10px 30px transparent,
        -20px 20px transparent,
        -30px 10px transparent,
        -30px 0px transparent,
        -30px -10px $spinner,
        -20px -20px $spinner,
        -10px -30px $spinner;
    }
    87.5% {
      box-shadow:
        0px -30px $spinner,
        10px -30px $spinner,
        20px -20px $spinner,
        30px -10px $spinner,
        30px 0px $spinner,
        30px 10px $spinner,
        20px 20px transparent,
        10px 30px transparent,
        0px 30px transparent,
        -10px 30px transparent,
        -20px 20px transparent,
        -30px 10px transparent,
        -30px 0px transparent,
        -30px -10px transparent,
        -20px -20px $spinner,
        -10px -30px $spinner;
    }
    93.75% {
      box-shadow:
        0px -30px $spinner,
        10px -30px $spinner,
        20px -20px $spinner,
        30px -10px $spinner,
        30px 0px $spinner,
        30px 10px $spinner,
        20px 20px $spinner,
        10px 30px transparent,
        0px 30px transparent,
        -10px 30px transparent,
        -20px 20px transparent,
        -30px 10px transparent,
        -30px 0px transparent,
        -30px -10px transparent,
        -20px -20px transparent,
        -10px -30px $spinner;
    }
    100% {
      box-shadow:
        0px -30px $spinner,
        10px -30px $spinner,
        20px -20px $spinner,
        30px -10px $spinner,
        30px 0px $spinner,
        30px 10px $spinner,
        20px 20px $spinner,
        10px 30px $spinner,
        0px 30px transparent,
        -10px 30px transparent,
        -20px 20px transparent,
        -30px 10px transparent,
        -30px 0px transparent,
        -30px -10px transparent,
        -20px -20px transparent,
        -10px -30px transparent;
    }
  }
  .loading-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    /*background-color: #FFFFFF;*/

    &.inside-container {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
  /*.spinner {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .cube1, .cube2 {
    background-color: #FFF;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 1.8s infinite ease-in-out;
  }

  .cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  @-webkit-keyframes sk-cubemove {
    25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
    50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
    75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
    100% { -webkit-transform: rotate(-360deg) }
  }

  @keyframes sk-cubemove {
    25% {
      transform: translateX(42px) rotate(-90deg) scale(0.5);
      -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    } 50% {
        transform: translateX(42px) translateY(42px) rotate(-179deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
      } 50.1% {
          transform: translateX(42px) translateY(42px) rotate(-180deg);
          -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
        } 75% {
            transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
            -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
          } 100% {
              transform: rotate(-360deg);
              -webkit-transform: rotate(-360deg);
            }
  }*/
</style>
