<template>
  <div class="about scrollAnimationOverlayParent">
    <Loading v-if="showLoading"></Loading>
    <div class="background-container-mobile" id="background-container-mobile" v-if="isMobileOnly || isIOS"></div>
    <div class="video-container">
      <div :data-vimeo-id="idVideo" id="handstick" class="video-player-container" v-if="!isMobileOnly && !isIOS"></div>
      <div class="about-overlay scrollAnimationOverlay" id="about-overlay"></div>
      <div class="wrapper">
        <div class="d-flex flex-column justify-content-center fullH scrollAnimation1">
          <!--            <vue-typer class="text-white bcLiguria" text="''" :repeat="0"></vue-typer>-->
          <span class="text-white bcLiguria"></span>
        </div>
        <div class="d-flex align-items-center fullH animationText scrollAnimation2">
          <p class="text-white bcLiguria about-description">{{$t('about.text')}}</p>
        </div>
      </div>

      <div class="about-sections-container">
        <div class="wrapper">
          <section class="about-section about-values">
            <h1 class="title-section bcLiguria">{{$t('about.titleValues')}}</h1>
            <AboutSection :data="{columns: 2, items: $t('about.values')}" :home-service-classes="['img-lg']"></AboutSection>
          </section>
          <section class="about-section about-services mt-4">
            <h1 class="title-section bcLiguria">{{$t('about.titleServices')}}</h1>
            <AboutSection :data="{columns: 3, items: $t('about.services')}" :home-service-classes="['img-md']"></AboutSection>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import services from '../assets/js/services'
import { fadeInOutSection, backgroundOverlay } from '@/assets/js/animations'
// import aboutValues from '../assets/js/about-values'
import AboutSection from '../components/AboutSection'
import { isMobileOnly, isIOS } from 'mobile-device-detect'
import Player from '@vimeo/player'
import Loading from '../components/Loading'
import { EventBus } from '@/event-bus'
import Helper from '@/helpers/Helper'

export default {
  name: 'About',
  components: {
    AboutSection,
    Loading
  },
  data () {
    return {
      isMobileOnly,
      isIOS,
      // services: services,
      // aboutValues: aboutValues,
      idVideo: 450166134,
      vimeoPlayer: null,
      showLoading: true,
      styleOverlay: {
        backgroundColor: 'rgba(0,0,0,0)'
      }
    }
  },
  methods: {
    vimeoPlayerOnLoad () {
      this.vimeoPlayer = new Player('handstick', {
        id: this.idVideo,
        muted: true,
        controls: false,
        loop: true,
        responsive: true
      })

      this.vimeoPlayer.loadVideo(this.idVideo).then(() => {
        this.vimeoPlayer.play().then(() => {
          this.showLoading = false
        }).catch(() => {
          this.showLoading = false
        })
      })
    }
  },
  mounted () {
    fadeInOutSection()
    backgroundOverlay()

    window.addEventListener('scroll', (e) => {
      const footerHeight = Helper.isBottomOfPage() ? (document.getElementsByClassName('footer')[0].clientHeight) : 0

      if (document.getElementById('handstick') !== null) {
        // document.getElementById('handstick').style.transform = 'translateY(-' + footerHeight + 'px)'
        // document.getElementById('handstick').style.top = '-' + footerHeight + 'px'
        document.getElementById('handstick').style.zIndex = Helper.isBottomOfPage() ? '-1' : 'auto'
      }
      if (document.getElementById('background-container-mobile') !== null) {
        document.getElementById('background-container-mobile').style.transform = 'translateY(-' + footerHeight + 'px)'
      }
      if (document.getElementById('about-overlay') !== null) {
        document.getElementById('about-overlay').style.transform = 'translateY(-' + footerHeight + 'px)'
      }
    })

    if (!isMobileOnly && !isIOS) {
      this.vimeoPlayerOnLoad()
    } else {
      this.showLoading = false
    }

    EventBus.$emit('showTitlePage', this.$t('about.pageTitle'))
  },
  watch: {
    '$i18n.locale': {
      handler () {
        EventBus.$emit('showTitlePage', this.$t('about.pageTitle'))
      }
    },
    showLoading: {
      handler () {
        if (!this.showLoading) {
          EventBus.$emit('toggleLoading', false)
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .about {
    .background-container-mobile {
      background-image: url('../assets/img/about-background.jpg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
    }
    .video-container {
      width: 100%;
      height: 100%;
      z-index: -2;
      overflow: hidden;

      .video-player-container {
        /*width: 100%;*/
        /*height: 100vh;*/
        position: fixed;
        top: 0;
        left: 0;

        height: 100%;
        /*width: 177.77777778vh; !* 100 * 16 / 9 *!*/
        /*min-height: 56.25vw; !* 100 * 9 / 16 *!*/
        width: calc(100vh * 16 / 9); /* 100 * 16 / 9 */
        min-width: 100%;
        min-height: calc(100vw * 9 / 16); /* 100 * 9 / 16 */

        iframe {
          width: 100%;
          height: 100%;
        }
      }

      .about-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0;
      }
    }

    .about-sections-container {
      position: relative;
    }
    .about-description {
      font-size: 36px;
      width: 80%;
      line-height: calc(var(--main-space)/5*6);
    }

    .about-section {
      padding-top: var(--main-space);
      .title-section {
        color: #FFFFFF;
        margin-bottom: var(--main-space);
        height: 40px;
      }
    }
  }

  @media screen and (min-width: 1240px) and (max-width: 1240px)
  and (min-height: 600px) and (max-height: 800px) {
    .background-container-mobile {
      background-position: top;
      background-repeat: no-repeat;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .about {
      .about-description {
        font-size: 30px;
        line-height: 150%;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .about {
      .about-description {
        font-size: 16px;
        width: 90%;
      }
      .about-section {
        .title-section {
          font-size: 28px;
          height: auto;
        }
      }
    }
  }
</style>
