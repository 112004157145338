<template>
  <div class="fundermax-container">
    <ImagesSlider id="fundermax-images-slider" :images="imagesSlider" v-if="imagesSlider.length > 0"
                  @changeSlider="changeSlider"></ImagesSlider>
    <div class="dot-overlay" v-if="sliderElemns.showOverlay"></div>
    <div class="wrapper">
      <section class="hero-slider">
        <div class="slider-content d-flex flex-column justify-content-center fullH scrollAnimation1">
          <div class="d-flex flex-column" v-if="showTyperText === 1">
            <vue-typer class="text-white bcLiguria" text="More than you see" :repeat="0"></vue-typer>
          </div>
          <div class="d-flex flex-column" v-else>
            <vue-typer class="text-white bcLiguria" text="Shaping the future" :repeat="0"></vue-typer>
            <div class="d-flex flex-row">
              <vue-typer text="of " :repeat="0" :pre-type-delay="1500" @completed="showFundermaxTyperLast = true"></vue-typer>
              <vue-typer :text="['paper', 'architecture', 'design']" :repeat="0" v-if="showFundermaxTyperLast"
                         :type-delay="70" erase-style="clear" :erase-on-complete='true' :erase-delay='2000'
                         caret-animation='expand' @completed="initVueTyper"></vue-typer>
            </div>
          </div>
        </div>
        <div class="mision-container d-flex flex-column justify-content-center fullH animationText scrollAnimation2">
          <p class="text-white bcLiguria">{{$t('stacBond.text')}}</p>
          <div class="d-flex flex-lg-row flex-column">
            <a class="pdf-download-container stacbond-more-info d-inline-flex" target="_blank" @click.stop.prevent="showPopup = true">
              <div class="d-flex flex-column justify-content-center">
                <MoreIcon />
              </div>
              <span>Mas informacion</span>
            </a>
          </div>
        </div>
        <div class="pdfs-download-container d-flex flex-lg-row flex-column" v-if="showPDFStacBond">
          <a v-for="(file, indexFile) in $t('stacBond.buttons')" class="pdf-download-container d-flex flex-row justify-content-between"
             :href="base_url + file.file" target="_blank" :key="'stacbond-file-' + indexFile">
            <span>{{file.text}} (PDF)</span>
            <div class="d-flex flex-column justify-content-center">
              <DownloadIcon></DownloadIcon>
            </div>
          </a>
        </div>
        <div class="arrow-slider" v-if="!isMobile">
          <router-link :to="{name: 'Fundermax'}">
            <ArrowIcon v-if="sliderElemns.showArrow"></ArrowIcon>
          </router-link>
        </div>
        <div class="mouse-container" v-if="!isMobile">
          <MouseIcon v-if="sliderElemns.showMouse"></MouseIcon>
        </div>
        <floatingButtonsContainer v-if="!isMobile && sliderElemns.showFloatingButtons" :hide-mobile="true"></floatingButtonsContainer>
        <a href="https://www.stacbond.com/" target="_blank" class="partner-slider-info d-flex flex-row align-items-center"
           v-if="sliderElemns.showSliderInfo">
          <div class="d-flex flex-column">
            <span>Partnered with</span>
            <StacBondLogo />
<!--            <img src="../assets/img/fundermax-new-logo-white.png" alt="Fundermax logo white">-->
          </div>
        </a>
      </section>
      <section class="services scrollAnimation3 d-flex flex-column justify-content-end" id="fundermax-section-services">
        <h4 class="bcLiguria text-white benefit-title">{{ $t('stacBond.benefitsTitle') }}</h4>
        <div class="services-row">
          <div class="services-container">
            <HomeService v-for="(benefit, index) in $t('stacBond.benefits')" :name="benefit.name"
                         :classes="['height-auto', 'min-lg-height', 'service-box-fundermax', 'benefit-box-stacbond',
                         'd-flex', 'justify-content-center', 'align-items-center']" :key="index"></HomeService>
          </div>
        </div>
      </section>
      <section class="projects scrollAnimation4">
        <div class="projects-container fullrow">
          <MasonryObras v-if="showObras" :obras="obras"></MasonryObras>
        </div>
      </section>
    </div>

    <StacBondPopup v-if="showPopup" @close="showPopup = false" />
  </div>
</template>

<script>
import { fadeInOutSection } from '@/assets/js/animations'
import ArrowIcon from '../assets/img/arrow-icon.svg'
import MouseIcon from '../assets/img/mouse-icon.svg'
import floatingButtonsContainer from '../components/floatingButtonsContainer'
import StacBondLogo from '../assets/img/stacbond-logo.svg'
import { VueTyper } from 'vue-typer'
import { EventBus } from '@/event-bus'
import HomeService from '../components/HomeService'
import ImagesSlider from '@/components/ImagesSlider'
import MasonryObras from '../components/MasonryObras'
import { isMobile } from 'mobile-device-detect'
import ObrasService from '../services/ObrasService'
import DownloadIcon from '../assets/img/download-icon.svg'
import MoreIcon from '../assets/img/more-icon.svg'
import Helper from '@/helpers/Helper'
import StacBondPopup from '@/components/StacBondPopup'
export default {
  name: 'Fundermax',
  components: {
    ArrowIcon,
    MouseIcon,
    floatingButtonsContainer,
    StacBondLogo,
    HomeService,
    VueTyper,
    MasonryObras,
    ImagesSlider,
    DownloadIcon,
    MoreIcon,
    StacBondPopup
  },
  data () {
    return {
      isMobile,
      showTyperText: 1,
      showFundermaxTyperLast: false,
      sliderElemns: {
        showArrow: true,
        showMouse: true,
        showSliderInfo: true,
        showOverlay: true,
        showFloatingButtons: true
      },
      showPDFStacBond: false,
      showObras: false,
      obras: [],
      obrasSlider: [],
      imagesSlider: [],
      obraSliderSelected: {},
      base_url: process.env.BASE_URL,
      showPopup: false
    }
  },
  methods: {
    initVueTyper () {
      this.showTyperText = 1
      this.showFundermaxTyperLast = false
    },
    getObras () {
      const service = new ObrasService()
      // ID 31 CORRESPONDE A FAMILIA STACBOND
      service.findAll({ fid: 38 }, (response) => {
        this.obras = response
        this.obrasSlider = this.obras
        this.obrasSlider.forEach(o => {
          if (o.slider === 1 || o.slider === '1') {
            this.imagesSlider.push(o.fotoFile)
          }
        })
        this.showObras = true
        this.changeSlider(0)
      })
    },
    changeSlider (sliderPos) {
      this.obraSliderSelected = this.obrasSlider[sliderPos]
    }
  },
  mounted () {
    this.getObras()

    fadeInOutSection()

    window.addEventListener('scroll', (e) => {
      let footerHeight = 0
      if (Helper.isBottomOfPage()) {
        footerHeight = (document.getElementsByClassName('footer')[0].clientHeight)
      }
      if (document.getElementById('fundermax-images-slider') !== null) {
        document.getElementById('fundermax-images-slider').style.transform = 'translateY(-' + footerHeight + 'px)'
      }
    })

    EventBus.$on('toggleSliderFirstElemns', (toggle) => {
      this.sliderElemns.showMouse = toggle
      this.sliderElemns.showSliderInfo = toggle
      this.sliderElemns.showOverlay = toggle
    })
    EventBus.$on('toggleSliderFinalElemns', (toggle) => {
      this.sliderElemns.showArrow = toggle
      this.sliderElemns.showFloatingButtons = toggle
    })

    EventBus.$emit('showLogo')

    EventBus.$on('togglePDFStacBond', (show) => {
      this.showPDFStacBond = show
    })
  },
  watch: {
    showPopup: {
      handler () {
        EventBus.$emit('bodyScroll', !this.showPopup)
      }
    }
  }
}
</script>

<style scoped lang="scss">

  .fundermax-container {
    width: 100%;

    position: relative;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow-x: hidden;
    @include backgroundTransparency(#000, 0.3);

    transition: background 0.5s linear;
    -webkit-transition: background 0.5s linear;
    -moz-transition: background 0.5s linear;
    -o-transition: background 0.5s linear;
  }

  .mision-container{
    position: relative;
    p {
      font-size: 36px;
      width: 80%;
      line-height: calc(var(--main-space)/5*6);
    }
  }

  .pdfs-download-container {
    position: fixed;
    bottom: var(--main-space);
    left: var(--main-space);
  }
  .pdf-download-container {
    padding-left: 0 !important;
    margin-right: var(--main-space);
    background-color: #000000;
    span {
      padding: 0.5rem 1.5rem;
      margin-right: 1px;
      cursor: pointer;
      color: #FFFFFF;
      font-size: 18px;
      line-height: 1.5rem;
      font-family: 'IBMPlexSans-SemiBold', sans-serif;
    }
    svg {
      fill: #FFFFFF;
    }
    div {
      padding: 0.5rem;
      width: var(--main-space);
      cursor: pointer;
    }
    &.stacbond-more-info {}
  }

  .services {
    min-height: 100vh;
    margin-bottom: calc(var(--main-space) * 3);
    //padding-top: calc(25vh + calc(var(--main-space) * 2));
  }
  .benefit-title {
    margin-bottom: var(--main-space) !important;
  }
  .services-row {
    width: calc(100% + calc(var(--main-space) * 2));
    margin-left: calc(-1 * var(--main-space));
    margin-bottom: 1px;
    position: relative;
    .services-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1px;
      grid-auto-flow: row dense;
    }
  }

  .hero-slider .arrow-slider svg{
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
  }

  .partner-slider-info {
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    width: 180px;
  }
  .partner-slider-info span {
    color: #FFFFFF;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: calc(var(--main-space) / 5);
  }
  .partner-slider-info svg,
  .partner-slider-info img
  {
    width: 100%;
  }

  .partner-slider-info svg .st0{fill:#FFFFFF;}
  //.partner-slider-info svg .st0{fill:#DF2226;}
  //.partner-slider-info svg .st1{fill:#FFFFFF;}

  section.projects {
    margin-top: 0;
  }
  section.projects .projects-container{
    padding-top: 0;
  }

  @include media-breakpoint-down(lg) {
    .mision-container p {
      width: 70%;
      font-size: 30px;
      line-height: 150%;
    }

    .pdfs-download-container {
      .pdf-download-container {
        //margin-right: 0;
        //margin-top: calc(var(--main-space) / 2);
      }
    }
  }
  @include media-breakpoint-down(md) {
    .pdfs-download-container {
      .pdf-download-container {
        margin-right: 0;
        margin-top: calc(var(--main-space) / 2);
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .mision-container p {
      font-size: 24px;
      width: 100%;
      //line-height: var(--main-space);
      line-height: calc(var(--main-space) / 3 * 4)
    }

    section.services {
      height: auto !important;
    }
    .services-row{
      .services-container {
        grid-template-columns: 1fr;
      }
    }

    .pdfs-download-container {
      .pdf-download-container {
        div {
          width: calc(var(--main-space) * 3 / 2);
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .pdfs-download-container {
      width: calc(100% - calc(var(--main-space) * 2));
    }
  }
</style>
