<template>
  <div class="masonry-container">
    <figure class="masonry-item" :class="'masonry-item-'+(index + 1)" v-for="(img, index) in imgs" :key="index">
      <img :src="img.foto" alt="">
    </figure>
<!--    <figure class="masonry-item" :class="'masonry-item-'+(index + 1 + imgs.length)" v-for="(img, index) in imgs" :key="index">-->
<!--            <img :src="img.foto" alt="">-->
<!--    </figure>-->
  </div>
</template>

<script>
export default {
  name: 'MasonryGridGallery',
  props: ['imgs']
}
</script>

<style scoped lang="scss">
  .masonry-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(33.3% - 1rem), 1fr));
    /*grid-auto-rows: 300px;*/
    grid-gap: 1rem;
    grid-auto-flow: row dense;
    border: 1px solid red;
    align-items: flex-start;
    .masonry-item {
      overflow: hidden;
      width: 100%;
      margin: 0;
      img {
        width: 100%;
        height: 100%;
        @supports (object-fit: cover) {
          object-fit: cover;
        }
      }

      /*&.masonry-item-1 {
        grid-column: 1/2;
        grid-row: 1/3;
      }
      &.masonry-item-4 {
        grid-column: 2/4;
      }*/
    }
  }
</style>
