<template>
  <div class="dot-slider-container d-flex flex-row align-items-center">
    <div class="dots d-none d-sm-flex flex-row align-items-center" v-if="!isMobile">
      <div class="dot ml-1 mr-1 hoverable" v-for="(item, index) in items" :key="index"
           :class="{active: index === posActive}" @click="$emit('selectMaterialByPos', index)"></div>
    </div>
    <ArrowIcon class="hoverable" @click="$emit('next')"></ArrowIcon>
  </div>
</template>

<script>
import ArrowIcon from '../assets/img/updown-arrow-icon.svg'
import { isMobile } from 'mobile-device-detect'
export default {
  name: 'DotsSlider',
  props: ['items', 'posActive'],
  components: {
    ArrowIcon
  },
  data () {
    return {
      isMobile
    }
  }
}
</script>

<style scoped lang="scss">
  .dot-slider-container {
    position: absolute;
    bottom: var(--main-space);
    right: var(--main-space);

    .dots {
      .dot {
        width: calc(var(--main-space) / 5);
        height: calc(var(--main-space) / 5);
        background-color: #000000;
        &.active {
          background-color: #FFFFFF;
        }
      }
    }
    svg {
      width: var(--main-space);
      height: var(--main-space);
      margin-left: calc(var(--main-space) / 2);
      transform: rotate(-90deg);
      fill: #FFFFFF;
    }
  }
  @include media-breakpoint-down(sm) {
    .dot-slider-container {
      svg {
        width: calc(var(--main-space) * 2);
        height: calc(var(--main-space) * 2);
      }
    }
  }
</style>
