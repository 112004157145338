export default class Helper {
  static IMG_EXT = ['jpg', 'jpeg', 'gif', 'png']
  static VIDEO_EXT = ['mp4', 'webm']

  static nameToPath (name) {
    return name.replace(/\s+/g, '-').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
  }

  static isInURL (name) {
    const url = window.location.href
    const partes = url.split('/')
    return partes.includes(name)
  }

  static isInPage (page) {
    page = page || ''
    const url = window.location.href
    const partes = url.split('/')
    return partes[(partes.length - 1)].toLowerCase() === page.toLowerCase()
  }

  static getUrlParamsValues (param, url) {
    url = url || window.location.href
    const params = url.split('?')
    const vars = params[(params.length - 1)].split('&')
    const values = {}
    vars.forEach(v => {
      const value = v.split('=')
      values[value[0]] = value[1]
    })
    return values
  }

  static isHome () { return Helper.isInPage('') }
  static isFundermax () { return Helper.isInPage('fundermax') }
  static isStacBond () { return Helper.isInPage('stacbond') }

  static screenMobile () { return Helper.screenWidthLowerThan(576) }
  static screenTablet () { return Helper.screenWidthLowerThan(768) }
  static screenWidthLowerThan (width) { return window.innerWidth < width }
  static screenWidthGraterThan (width) { return window.innerWidth > width }

  static preloadImages (arrayImages, path) {
    const images = []
    arrayImages.forEach(objImg => {
      const image = new Image()
      image.onload = function () {}
      image.src = path + objImg.img
      images.push(image)
    })
  }

  static isURLImage (url) {
    const parts = url.split('.')
    const ext = parts[(parts.length - 1)]
    return Helper.IMG_EXT.includes(ext.toLowerCase())
  }

  static isURLVideo (url) {
    const parts = url.split('.')
    const ext = parts[(parts.length - 1)]
    return Helper.VIDEO_EXT.includes(ext.toLowerCase())
  }

  static isBottomOfPage () {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight
  }

  static generateRandomString (length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  static getCurrentLocale () {
    return Helper.getLocaleFromUrl() || Helper.getLocalFromNavigator()
  }

  static getLocalFromNavigator () {
    return navigator.language.split('-')[0]
  }

  static getLocaleFromUrl () {
    const parts = window.location.href.split('/')
    return (parts[3] === 'es' || parts[3] === 'en') ? parts[3] : null
  }

  static isEmpty (field) {
    return field === '' || field === undefined || field === null
  }

  static isValidMail (mail) {
    return mail.toLowerCase().includes('@') && mail.toLowerCase().includes('.com')
  }
}
