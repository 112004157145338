import Service from './Service'
export default class ObrasService extends Service {
  constructor () {
    super()
    this.paramsURL = {
      obras: 'obras.php'
    }
  }

  static parse (product) {
    return {}
  }

  findAll (params, callbackSuccess, callbackHandleError) {
    let stringParams = '?'
    let i = 0
    for (const param in params) {
      if (i > 0) {
        stringParams += '&'
      }
      stringParams += param + '=' + params[param]
      i++
    }
    this.ajax('GET', this.paramsURL.obras + stringParams, {}, callbackSuccess, callbackHandleError)
  }
}
