<template>
  <a :href="'http://wa.me/' + constantes.whatsapp_number" target="_blank" class="whatsapp-floating-icon">
    <Whatsapp></Whatsapp>
    <span>Contactanos!</span>
  </a>
</template>

<script>
import Whatsapp from '../assets/img/whatsapp.svg'
import constantes from '../constantes.json'
export default {
  name: 'WhatsAppIcon',
  components: {
    Whatsapp
  },
  data () {
    return {
      constantes: constantes
    }
  }
}
</script>

<style scoped lang="scss">
  .whatsapp-floating-icon {
    position: relative;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
    }

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: calc(100% + 1rem);
      background-color: green;
      padding: 0.2rem 1rem;
      color: #FFFFFF;
      display: none;
    }
  }
</style>
