import constantes from './constantes'

export default class ConstantsHelper {
  static getURLs () {
    if (constantes.mode === 'development') {
      return {
        APIpath: constantes.urls.development.APIpath,
        v1: constantes.urls.development.APIpath + 'v1/'
      }
    } else if (constantes.mode === 'production') {
      return {
        APIpath: constantes.urls.production.APIpath,
        v1: constantes.urls.production.APIpath + 'v1/'
      }
    }
  }
}
