<template>
  <transition-group name="fade-in-right-group" mode="out-in" tag="div"
                    class="d-flex flex-row align-items-center contacts-icons-menu-container"
                    :style="{ '--total': 5 }">
    <b-list-group-item class="contact-icon-menu" key="header-contact-icon-instagram" :style="{'--i': 0}">
      <a href="https://www.instagram.com/grupomshsa/" target="_blank" class="text-nowrap" id="header-contacts-instagram">
        <figure class="d-flex flex-column justify-content-center align-items-center icon-container-instagram">
          <InstagramLogo></InstagramLogo>
        </figure>
        <b-tooltip custom-class="header-family-icon-tooltip" target="header-contacts-instagram"
                   placement="bottom" triggers="hover">
          <span class="bcLiguria text-nowrap d-flex flex-column justify-content-end">Instagram</span>
        </b-tooltip>
      </a>
    </b-list-group-item>
    <b-list-group-item class="contact-icon-menu" key="header-contact-icon-linkedin" :style="{'--i': 1}">
      <a href="https://www.linkedin.com/company/grupo-msh-s-a-/" target="_blank" class="text-nowrap" id="header-contacts-linkedin">
        <figure class="d-flex flex-column justify-content-center align-items-center icon-container-linkedin">
          <LinkedinLogo></LinkedinLogo>
        </figure>
        <b-tooltip custom-class="header-family-icon-tooltip" target="header-contacts-linkedin"
                   placement="bottom" triggers="hover">
          <span class="bcLiguria text-nowrap d-flex flex-column justify-content-end">Linkedin</span>
        </b-tooltip>
      </a>
    </b-list-group-item>
    <b-list-group-item class="contact-icon-menu" key="header-contact-icon-whatsapp" :style="{'--i': 2}">
      <a :href="'http://wa.me/' + constantes.whatsapp_number" target="_blank" class="text-nowrap" id="header-contacts-whatsapp">
        <figure class="d-flex flex-column justify-content-center align-items-center icon-container-whatsapp">
          <WhatsappLogo></WhatsappLogo>
        </figure>
        <b-tooltip custom-class="header-family-icon-tooltip" target="header-contacts-whatsapp"
                   placement="bottom" triggers="hover">
          <span class="bcLiguria text-nowrap d-flex flex-column justify-content-end">Whatsapp</span>
        </b-tooltip>
      </a>
    </b-list-group-item>
    <b-list-group-item class="contact-icon-menu" key="header-contact-icon-tel" :style="{'--i': 3}">
      <a href="tel:+541152630413" class="text-nowrap" id="header-contacts-tel">
        <figure class="d-flex flex-column justify-content-center align-items-center icon-container-tel">
          <TelefonoLogo></TelefonoLogo>
        </figure>
        <b-tooltip custom-class="header-family-icon-tooltip" target="header-contacts-tel"
                   placement="bottom" triggers="hover">
          <span class="bcLiguria text-nowrap d-flex flex-column justify-content-end">Teléfono</span>
        </b-tooltip>
      </a>
    </b-list-group-item>
    <b-list-group-item class="contact-icon-menu" key="header-contact-icon-mail" :style="{'--i': 4}">
<!--      <a href="mailto:proyectos@grupomsh.com.ar" target="_blank" class="text-nowrap" id="header-contacts-mail">
        <figure class="d-flex flex-column justify-content-center align-items-center">
          <MailLogo></MailLogo>
        </figure>
        <b-tooltip custom-class="header-family-icon-tooltip" target="header-contacts-mail"
                   placement="bottom" triggers="hover">
          <span class="bcLiguria text-nowrap d-flex flex-column justify-content-end">{{$t('general.email')}}</span>
        </b-tooltip>
      </a>-->
      <router-link :to="{name: 'Contact', params: {lang: this.$i18n.locale, form: true}}"  class="text-nowrap" id="header-contacts-mail">
        <figure class="d-flex flex-column justify-content-center align-items-center">
          <MailLogo></MailLogo>
        </figure>
        <b-tooltip custom-class="header-family-icon-tooltip" target="header-contacts-mail"
                   placement="bottom" triggers="hover">
          <span class="bcLiguria text-nowrap d-flex flex-column justify-content-end">{{$t('general.email')}}</span>
        </b-tooltip>
      </router-link>
    </b-list-group-item>
    <b-list-group-item class="contact-icon-menu" key="header-contact-icon-wallpapers" :style="{'--i': 5}">
      <router-link :to="{name: 'Wallpapers'}" class="text-nowrap" id="header-contacts-wallpapers">
        <figure class="d-flex flex-column justify-content-center align-items-center icon-container-wallpapers">
          <DownloadIcon></DownloadIcon>
        </figure>
        <b-tooltip custom-class="header-family-icon-tooltip" target="header-contacts-wallpapers"
                   placement="bottom" triggers="hover">
          <span class="bcLiguria text-nowrap d-flex flex-column justify-content-end">Wallpapers</span>
        </b-tooltip>
      </router-link>
    </b-list-group-item>
    <b-list-group-item class="contact-icon-menu" key="header-contact-icon-brochure" :style="{'--i': 6}">
      <router-link :to="{name: 'Brochure'}" class="text-nowrap" id="header-contacts-brochure">
        <figure class="d-flex flex-column justify-content-center align-items-center icon-container-wallpapers">
          <BrochureIcon></BrochureIcon>
        </figure>
        <b-tooltip custom-class="header-family-icon-tooltip" target="header-contacts-brochure"
                   placement="bottom" triggers="hover">
          <span class="bcLiguria text-nowrap d-flex flex-column justify-content-end">Brochure</span>
        </b-tooltip>
      </router-link>
    </b-list-group-item>
    <b-list-group-item class="contact-icon-menu" key="header-contact-icon-idioma" :style="{'--i': 7}">
      <a @click="changeLanguage()" class="text-nowrap" id="header-contacts-idioma">
        <figure class="d-flex flex-column justify-content-center align-items-center">
          <component :is="languageIcon"></component>
        </figure>
        <b-tooltip custom-class="header-family-icon-tooltip" target="header-contacts-idioma"
                   placement="bottom" triggers="hover">
          <span class="bcLiguria text-nowrap d-flex flex-column justify-content-end">{{$t('general.language')}}</span>
        </b-tooltip>
      </a>
    </b-list-group-item>
  </transition-group>
</template>

<script>
import InstagramLogo from '@/assets/img/instagram-icon.svg'
import WhatsappLogo from '@/assets/img/whatsapp.svg'
import TelefonoLogo from '@/assets/img/telefono-icon.svg'
import LinkedinLogo from '@/assets/img/linkedin-icon.svg'
import LanguageIconEN from '@/assets/img/iconoEN.svg'
import LanguageIconES from '@/assets/img/iconoES.svg'
import MailLogo from '@/assets/img/mail-icon.svg'
import DownloadIcon from '@/assets/img/download-icon.svg'
import BrochureIcon from '@/assets/img/brochure-icon.svg'
import constantes from '@/constantes.json'
import { EventBus } from '@/event-bus'

export default {
  name: 'Contacts',
  components: {
    InstagramLogo,
    LinkedinLogo,
    WhatsappLogo,
    TelefonoLogo,
    MailLogo,
    LanguageIconEN,
    LanguageIconES,
    DownloadIcon,
    BrochureIcon
  },
  data () {
    return {
      constantes: constantes
    }
  },
  computed: {
    languageIcon () {
      return (this.$i18n.locale === 'en') ? 'languageIconEN' : 'languageIconES'
    }
  },
  methods: {
    changeLanguage () {
      EventBus.$emit('changeLanguage')
    }
  }
}
</script>

<style scoped lang="scss">
  .contact-icon-menu {
    background-color: $footer-text;
    width: var(--main-space);
    height: var(--main-space);
    //margin-right: calc(var(--main-space) / 2);
    margin-right: calc(var(--main-space) / 5 * 2);
    border: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 0 !important;
    a {
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      figure {
        width: 100%;
        height: 100%;
        margin: 0 !important;
        overflow: hidden;
        svg {
          fill: #000000 !important;
          width: 60% !important;
          * {
            fill: #000000 !important;
          }
        }
        &.icon-container-youtube:hover svg path {
          fill: red !important;
        }

        &.icon-container-linkedin:hover svg > * {
          fill: $linkedin !important;
        }

        &.icon-container-instagram:hover svg > * {
          fill: $instagram !important;
        }

        &.icon-container-whatsapp:hover svg > * {
          fill: $whatsapp !important;
        }
      }
    }
    &:last-child {
      //margin-right: var(--main-space);
      margin-right: calc(var(--main-space) / 5 * 4);
    }
  }
</style>
