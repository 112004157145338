import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Product from '../views/Product'
import FamilyProduct from '../views/FamilyProduct'
import Fundermax from '../views/Fundermax'
import StacBond from '../views/StacBond'
import About from '../views/About'
import People from '../views/People'
import Contact from '../views/Contact'
import Wallpapers from '@/views/Wallpapers'
import Brochure from '@/views/Brochure'
import Videos from '../views/Videos'
import Materials from '../views/Materials'
import Works from '../views/Works'
import Work from '../views/Work'
import DesignInsight from '../views/DesignInsight'
import ImagesSlider from '@/components/ImagesSlider'
import PageNotFound from '@/views/PageNotFound'
import i18n from '@/i18n'
import Partners from '@/views/Partners'
// import GPTW from '@/views/GPTW'

// function load (component) {
//   return () => import('@/views/' + component + '.vue')
// }

Vue.use(VueRouter)

const routes = [
  {
    path: '/:locale',
    component: { render (c) { return c('router-view') } },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale.split('/')[0]
      if (locale !== 'es' && locale !== 'en') {
        let path = to.path
        if (locale.length === 2) {
          path = to.path.replace('/' + locale, '')
        }
        const newUrl = 'es' + path
        return next(newUrl)
      }

      if (i18n.locale !== locale) {
        i18n.locale = locale
      }

      return next()
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        meta: { transitionName: 'fade-in-left' }
      },
      {
        path: 'product/:productPath',
        name: 'product',
        component: Product,
        meta: { transitionName: 'fade' }
      },
      {
        path: 'family-product/:familyProduct',
        name: 'family-product',
        component: FamilyProduct,
        meta: { transitionName: 'fade' }
      },
      {
        path: 'fundermax',
        name: 'Fundermax',
        component: Fundermax,
        meta: { transitionName: 'fade-in-right' }
      },
      {
        path: 'stacbond',
        name: 'StacBond',
        component: StacBond,
        meta: { transitionName: 'fade-in-right' }
      },
      {
        path: 'about',
        name: 'About',
        component: About,
        meta: { transitionName: 'fade-in-up' }
      },
      {
        path: 'people',
        name: 'People',
        component: People,
        meta: { transitionName: 'fade' }
      },
      {
        path: 'materials',
        name: 'Materials',
        component: Materials,
        meta: { transitionName: 'fade' }
      },
      {
        path: 'works/:work?',
        name: 'Works',
        component: Works,
        props: true,
        meta: { transitionName: 'fade' }
      },
      {
        path: 'work/:work',
        name: 'Work',
        props: true,
        component: Work,
        meta: { transitionName: 'fade' }
      },
      {
        path: 'design-insight',
        name: 'DesignInsight',
        component: DesignInsight,
        meta: { transitionName: 'fade' }
      },
      {
        path: 'videos',
        name: 'Videos',
        component: Videos,
        meta: { transitionName: 'fade' }
      },
      {
        path: 'contact',
        name: 'Contact',
        component: Contact,
        meta: { transitionName: 'fade' }
      },
      {
        path: 'wallpapers',
        name: 'Wallpapers',
        component: Wallpapers,
        meta: { transitionName: 'fade' }
      },
      {
        path: 'brochure',
        name: 'Brochure',
        component: Brochure,
        meta: { transitionName: 'fade' }
      },
      {
        path: 'partners',
        name: 'Partners',
        component: Partners,
        meta: { transitionName: 'fade' }
      },
      // {
      //   path: 'gptw',
      //   name: 'GPTW',
      //   component: GPTW,
      //   meta: { transitionName: 'fade' }
      // },
      {
        path: 'slider',
        name: 'ImagesSlider',
        component: ImagesSlider,
        meta: { transitionName: 'fade' }
      },
      {
        path: '*',
        name: 'NotFound',
        component: PageNotFound,
        meta: { transitionName: 'fade-in-left' }
      }
    ]
  },
  {
    path: '*',
    redirect: to => {
      return 'es'
    }
  }
  /* {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { transitionName: 'fade-in-left' }
  }
  {
    path: '/product/:productPath',
    name: 'product',
    component: Product,
    meta: { transitionName: 'fade' }
  },
  {
    path: '/family-product/:familyProduct',
    name: 'family-product',
    component: FamilyProduct,
    meta: { transitionName: 'fade' }
  },
  {
    path: '/fundermax',
    name: 'Fundermax',
    component: Fundermax,
    meta: { transitionName: 'fade-in-right' }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { transitionName: 'fade-in-up' }
  },
  {
    path: '/people',
    name: 'People',
    component: People,
    meta: { transitionName: 'fade' }
  },
  {
    path: '/materials',
    name: 'Materials',
    component: Materials,
    meta: { transitionName: 'fade' }
  },
  {
    path: '/works/:work?',
    name: 'Works',
    component: Works,
    props: true,
    meta: { transitionName: 'fade' }
  },
  {
    path: '/work/:work',
    name: 'Work',
    props: true,
    component: Work,
    meta: { transitionName: 'fade' }
  },
  {
    path: '/design-insight',
    name: 'DesignInsight',
    component: DesignInsight,
    meta: { transitionName: 'fade' }
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos,
    meta: { transitionName: 'fade' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { transitionName: 'fade' }
  },
  {
    path: '/slider',
    name: 'ImagesSlider',
    component: ImagesSlider,
    meta: { transitionName: 'fade' }
  },
  {
    path: '*',
    name: 'NotFound',
    component: PageNotFound,
    meta: { transitionName: 'fade-in-left' }
  } */
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
