import Service from './Service'
import Helper from '../helpers/Helper'
export default class FamiliasProductosService extends Service {
  constructor () {
    super()
    this.paramsURL = {
      familias: 'familias.php'
    }
  }

  static parse (familyProduct) {
    // const partesNombre = familyProduct.nombre.split('-')
    // let nombre = partesNombre[0]
    // if (partesNombre.length > 1) {
    //   nombre = ''
    //   for (let i = 1; i < partesNombre.length; i++) {
    //     nombre += partesNombre[i].trim()
    //   }
    // }
    const nombre = familyProduct.nombre
    const path = Helper.nameToPath(nombre)

    familyProduct.productos.forEach(p => {
      p.path = Helper.nameToPath(p.nombre)
    })

    return {
      id: familyProduct.id,
      path: path,
      // icon: 'FamilyProduct' + nombre.replace(/\s+/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      icon: familyProduct.icono,
      name: nombre,
      name_lang: familyProduct.nombre_lang,
      color: familyProduct.color,
      archivos: familyProduct.archivos,
      foto: familyProduct.foto,
      productos: familyProduct.productos,
      text1: familyProduct.texto1,
      text1_lang: familyProduct.texto1_lang,
      text2: familyProduct.texto2,
      text2_lang: familyProduct.texto2_lang
    }
  }

  findAll (callbackSuccess, callbackHandleError) {
    this.ajax('GET', this.paramsURL.familias, {}, callbackSuccess, callbackHandleError)
  }

  findById (params, callbackSuccess, callbackHandleError) {
    this.ajax('GET', this.paramsURL.familias + '?id=' + params.id, {}, callbackSuccess, callbackHandleError)
  }
}
