<template>
  <div class="work-container" :class="{'mouseLeft': (mouseLeft && showMpuseArrow), 'mouseRight': (mouseRight && showMpuseArrow)}"
       @click="moveSliderImages()">
    <ImagesSlider :images="imagesSlider" class="images-slider" @changeSlider="changeSlider"
                  :options="{showControls: isMobile, initSliderOnMounted: false}"></ImagesSlider>
    <WorkSelectedHeader :work="work" @toggleWorkInfo="toggleWorkInfo()" @closeWork="$emit('closeWork')"
                        @handlerMouseenter="showMpuseArrow = false" @handlerMouseleave="showMpuseArrow = true"></WorkSelectedHeader>
    <WorkSelectedInfo :work="work" v-if="showWorkInfo" @handlerMouseenter="showMpuseArrow = false" @handlerMouseleave="handlerMouseLeaveWorkInfo()"></WorkSelectedInfo>
    <div class="work-images-slider-container" v-if="!showWorkInfo">
      <span class="bcLiguria">{{(imgSliderPos + 1)}}/{{workCantImages}}</span>
    </div>
  </div>
</template>

<script>
import WorkSelectedHeader from './WorkSelectedHeader'
import WorkSelectedInfo from './WorkSelectedInfo'
import { isMobile } from 'mobile-device-detect'
import ImagesSlider from '@/components/ImagesSlider'
import { EventBus } from '@/event-bus'
export default {
  name: 'WorkSelected',
  props: ['work'],
  components: {
    WorkSelectedHeader,
    WorkSelectedInfo,
    ImagesSlider
  },
  data () {
    return {
      isMobile,
      styleBackground: {
        backgroundImage: ''
      },
      imgSliderPos: 0,
      showWorkInfo: false,

      showMpuseArrow: true,
      mouseLeft: false,
      mouseRight: false,
      imagesSlider: []
    }
  },
  computed: {
    workCantImages () {
      return this.imagesSlider.length
    }
  },
  methods: {
    initImagesSlider () {
      this.work.fotos.forEach(f => {
        this.imagesSlider.push(f.foto)
      })
    },
    changeSlider (sliderPos) {
      this.imgSliderPos = sliderPos
    },
    moveSliderImages () {
      if (!isMobile) {
        if (this.mouseLeft) {
          EventBus.$emit('moveLeft')
        }
        if (this.mouseRight) {
          EventBus.$emit('moveRigth')
        }
      }
    },
    toggleWorkInfo (show) {
      show = show || !this.showWorkInfo
      this.showWorkInfo = show
    },
    handlerMouseMove (e) {
      const mousePos = e.offsetX
      const windowWidth = window.innerWidth
      if (mousePos > (windowWidth / 2)) {
        this.mouseRight = true
        this.mouseLeft = false
      } else {
        this.mouseLeft = true
        this.mouseRight = false
      }
    },
    handlerMouseLeaveWorkInfo () {
      this.showMpuseArrow = true
      this.toggleWorkInfo(false)
    }
  },
  mounted () {
    this.initImagesSlider()
    window.addEventListener('mousemove', this.handlerMouseMove)
  },
  watch: {
    styleBackground: {
      deep: true,
      handler () {}
    }
  }
}
</script>

<style scoped lang="scss">
  .work-container {
    width: 100%;
    height: 100vh;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;

    background-color: #000000;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;

    &.mouseRight {
      cursor: url('../../assets/img/flecha-der.png'), auto;
    }
    &.mouseLeft {
      cursor: url('../../assets/img/flecha-izq.png'), auto;
    }

    .images-slider {
      z-index: -1;
    }

    .work-images-slider-container {
      position: absolute;
      bottom: var(--main-space);
      right: var(--main-space);
      span {
        font-size: 48px;
        color: #FFFFFF;
      }
    }
  }
</style>
