<template>
    <div class="buttons-container" :class="{'d-none': hideMobile, 'd-sm-flex': hideMobile, 'd-flex': !hideMobile}">
<!--        <GPTWLogoBlanco class="gptw"></GPTWLogoBlanco>-->
        <floatingButton variant="language" :icono="languageIcon" @changeLanguage="changeLanguage()"></floatingButton>
        <floatingButton :variant="'router-link'" name="mail" :link="{name: 'Contact', params: {lang: this.$i18n.locale, form: true}}" :icono="'mailIcon'"></floatingButton>
        <floatingButton :variant="'link'" name="youtube" :link="'https://www.youtube.com/channel/UCZxI91Z8g2UYtYaQjDeWkAw'" :icono="'youtubeIcon'"></floatingButton>
        <floatingButton :variant="'link'" name="linkedin" :link="'https://www.linkedin.com/company/grupo-msh-s-a-/'" :icono="'linkedinIcon'"></floatingButton>
        <floatingButton :variant="'link'" name="instagram" :link="'https://www.instagram.com/grupomshsa/'" :icono="'instagramIcon'"></floatingButton>
        <floatingButton :variant="'link'" name="telefono" link="tel:+541152630413" :icono="'TelefonoLogo'"></floatingButton>
        <floatingButton :variant="'link'" name="whatsapp" :classes="['nomb']" :link="'http://wa.me/' + constantes.whatsapp_number" :icono="'whatsappIcon'"></floatingButton>
    </div>
</template>

<script>
import floatingButton from './floatingButton'
import constantes from '../constantes.json'
import { EventBus } from '@/event-bus'
// import GPTWLogoBlanco from '@/assets/img/gptw-blanco.svg'

export default {
  name: 'floatingButtonsContainer',
  props: ['hideMobile'],
  components: {
    floatingButton
    // GPTWLogoBlanco
  },
  data () {
    return {
      constantes: constantes
    }
  },
  computed: {
    languageIcon () {
      return (this.$i18n.locale === 'en') ? 'languageIconEN' : 'languageIconES'
    }
  },
  methods: {
    changeLanguage () {
      EventBus.$emit('changeLanguage')
    }
  }
}
</script>

<style lang="scss">
    .buttons-container {
        position: fixed;
        bottom: var(--main-space);
        right: 0;

      .gptw {
        height: var(--main-space);
        transform: scale(1.5);
        margin-right: calc(var(--main-space) / 4);
      }
    }
</style>
