<template>
  <div class="grid-gallery">
    <div class="grid-item" v-for="(img, index) in imgs" :key="index">
      <figure>
        <img :src="img.foto" alt="">
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GridWorkGallery',
  props: ['imgs']
}
</script>

<style scoped lang="scss">
  .grid-gallery {
    width: 100%;
    display: grid;
    /*grid-template-columns: repeat(auto-fit, calc(25% - 1rem));*/
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row dense;
    grid-gap: 1rem;

    .grid-item {
      width: 100%;
      padding-top: 100%;
      position: relative;

      figure {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        margin: 0;
        top: 0;
        left: 0;

        img {
          height: 100%;
          @supports (object-fit: cover) {
            & {
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }
</style>
