<template>
<!--  <UnderConstruccion></UnderConstruccion>-->
  <div class="work">
    <div class="wrapper">

      <div class=" d-flex flex-row pt-3">
<!--        <p class="obra-descripcion">{{obra.descripcion || 'Sin descripcion'}}</p>-->
        <figure class="obra-main-foto">
          <img :src="obra.fotoFile" alt="">
        </figure>
        <div class="d-flex flex-column p-3 obra-info-container">
          <div class="d-flex flex-row align-items-center obra-info-item-container">
            <span class="info">{{obra.nombre}}</span>
          </div>
          <div class="d-flex flex-row align-items-center obra-info-item-container">
            <span class="title mr-3">Ubicación</span>
            <span class="info">{{obra.ubicacion}}</span>
          </div>
          <div class="d-flex flex-row align-items-center obra-info-item-container">
            <span class="title mr-3">Año</span>
            <span class="info">{{obra.fecha}}</span>
          </div>
          <div class="d-flex flex-row align-items-center obra-info-item-container">
            <span class="title mr-3">Estudio</span>
            <span class="info">{{obra.estudio}}</span>
          </div>

          <div class="d-flex flex-column mt-4">
            <span class="title">MSH</span>

            <div class="d-flex flex-column obra-info-productos-container mt-4">
              <span class="title">Productos</span>
              <div class="d-flex flex-row flex-wrap mt-1">
                <span class="tag" v-for="(producto, indexP) in obraProducts" :key="indexP">{{producto.nombre}}</span>
                <span class="tag" v-if="!hasProducts">Sin productos</span>
              </div>
            </div>
            <div class="d-flex flex-column obra-info-productos-container mt-4">
              <span class="title">Materiales</span>
              <div class="d-flex flex-row mt-1">
                <span class="tag" v-for="(material, indexM) in obraMaterials" :key="indexM">{{material.nombre}}</span>
                <span class="tag" v-if="!hasMAterials">Sin materiales</span>
              </div>
            </div>
            <div class="d-flex flex-column obra-info-productos-container mt-4">
              <span class="title">Aplicaciones</span>
              <div class="d-flex flex-row mt-1">
                <span class="tag" v-for="(aplicacion, indexM) in obraAplications" :key="indexM">{{aplicacion.nombre}}</span>
                <span class="tag" v-if="!hasAplications">Sin aplicaciones</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="obra-imagenes" v-if="hasGallery">
        <h3 class="title bcLiguria mt-4 mb-4">Imágenes de la obra</h3>
        <GridWorkGallery :imgs="obra.fotos"></GridWorkGallery>
        <MasonryGridGallery class="mt-4" :imgs="obra.fotos" v-if="false"></MasonryGridGallery>
      </div>

<!--      <figure class="obra-img-container">-->
<!--        <img :src="obra.fotoFile" alt="">-->
<!--      </figure>-->
    </div>
    <div class="obra-obras-relacionadas">
      <h3 class="title bcLiguria mt-4 mb-4 wrapper">Obras relacionadas</h3>
      <MasonryObras v-if="hasRelated" :obras="obra.obras"></MasonryObras>
    </div>
  </div>
</template>

<script>
import ObrasService from '../services/ObrasService'
import { EventBus } from '../event-bus'
import MasonryObras from '../components/MasonryObras'
import GridWorkGallery from '../components/GridWorkGallery'
import MasonryGridGallery from '../components/MasonryGridGallery'
export default {
  name: 'Work',
  components: {
    MasonryObras,
    GridWorkGallery,
    MasonryGridGallery
  },
  data () {
    return {
      obra: {
        productos: [],
        materiales: [],
        aplicaciones: []
      }
    }
  },
  computed: {
    obraProducts () {
      return this.obra.productos || []
    },
    hasProducts () {
      return this.obra.productos !== undefined && this.obra.productos.length > 0
    },
    obraMaterials () {
      return this.obra.materiales || []
    },
    hasMAterials () {
      return this.obra.materiales !== undefined && this.obra.materiales.length > 0
    },
    obraAplications () {
      return this.obra.aplicaciones || []
    },
    hasAplications () {
      return this.obra.aplicaciones !== undefined && this.obra.aplicaciones.length > 0
    },
    hasGallery () {
      return this.obra.fotos !== undefined && this.obra.fotos.length > 0
    },
    hasRelated () {
      return this.obra.obras !== undefined && this.obra.obras.length > 0
    }
  },
  methods: {
    getIdObraFromUrl () {
      const partesUrl = window.location.href.split('/')
      return partesUrl[(partesUrl.length - 1)].split('-')[0]
    },
    getObraById (id) {
      const service = new ObrasService()
      EventBus.$emit('toggleLoading', true)
      service.findAll({ id: id }, (response) => {
        this.obra = response
        EventBus.$emit('toggleLoading', false)
      })
    }
  },
  mounted () {
    this.getObraById(this.getIdObraFromUrl())
    EventBus.$emit('showLogo')
  },
  watch: {
    $route: {
      handler () {
        this.getObraById(this.getIdObraFromUrl())
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .work {
    margin-top: calc(3 * var(--main-space));
    min-height: calc(100vh - 5rem);
    background-color: $footer-text;

    .wrapper {
      .obra-descripcion,
      .obra-main-foto {
        padding: 1rem 2rem 1rem 1rem;
        width: calc(50% - 3rem);
        font-size: 1.4rem;
        margin: 0;
        img {
          width: 100%;
          @supports (object-fit: cover) {
            height: 100%;
            object-fit: cover;
            object-position: center;
}
        }
      }

      .obra-info-container {
        padding-left: 2rem !important;
        width: calc(50% - 3rem);
        .obra-info-item-container {
          border-bottom: 5px dotted #4c4c4c;
          padding-bottom: 0.5rem;
          &:not(:first-child) {
            padding-top: 0.5rem;
          }
        }
        .title {
          font-size: 1.4rem;
          width: 100px;
        }
        .info {
          font-size: 1.2rem;
          width: calc(100% - 200px);
          font-weight: 600;
        }

        .obra-info-productos-container {
          .title {
            font-weight: 600;
          }
          .tag {
            background-color: #000000;
            color: #FFFFFF;
            padding: 0.5rem 1rem;
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .obra-img-container {
      width: calc(100% + 4rem);
      margin-left: -2rem;
      img {
        width: 100%;
      }
    }
    .obra-obras-relacionadas {
      .title {
        font-size: 1.6rem;
      }
    }
  }
</style>
