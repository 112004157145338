<template>
  <div class="masonry-container" :class="classes">
    <div class="masonry-item d-flex flex-wrap" :class="'obra-fotos-' + obra.cantidad_fotos" v-for="(obra, index) in obras" :key="index">
      <figure class="masonry-item-foto-container" v-for="(foto, indexFoto) in obra.fotos" :key="indexFoto">
        <img :src="require('../' + path + foto)" :alt="foto">
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MasonryObrasPartner',
  props: {
    obras: Array,
    path: String,
    classes: {
      type: Array
    }
  }
}
</script>

<style scoped lang="scss">
  .masonry-container {
    width: 100%;
    overflow: hidden;
    @include backgroundTransparency(#000000, 0.5);
  }
  .masonry-container.fullH {
    min-height: 100vh !important;
  }
  .masonry-item {
    float: left;
    width: 25%;
    padding-top: 25%;
    position: relative;
  }
  .masonry-item.obra-fotos-4
  {
    width: 50%;
    padding-top: 50%;
  }
  .masonry-item.obra-fotos-2
  {
    width: 50%;
    padding-top: 25%;
  }

  .masonry-item .masonry-item-foto-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 1px;
  }
  .masonry-item .masonry-item-foto-container img {
    width: 100%;
  }

  .masonry-item.obra-fotos-4 .masonry-item-foto-container {
    width: calc(50% - 2px);
    height: calc(50% - 2px);
  }
  .masonry-item.obra-fotos-4 .masonry-item-foto-container:first-child {top: 0; left: 0; }
  .masonry-item.obra-fotos-4 .masonry-item-foto-container:nth-of-type(2) {bottom: 0; left: 0; }
  .masonry-item.obra-fotos-4 .masonry-item-foto-container:nth-of-type(3) {top: 0; right: 0; }
  .masonry-item.obra-fotos-4 .masonry-item-foto-container:nth-of-type(4) {bottom: 0; right: 0; }

  .masonry-item.obra-fotos-2 .masonry-item-foto-container {
    width: calc(50% - 2px);
    height: calc(100% - 2px);
  }
  .masonry-item.obra-fotos-2 .masonry-item-foto-container:first-child {top: 0; left: 0; }
  .masonry-item.obra-fotos-2 .masonry-item-foto-container:nth-of-type(2) {top: 0; right: 0; }

  .masonry-item.obra-fotos-1 .masonry-item-foto-container {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
  }
  .masonry-item.obra-fotos-1 .masonry-item-foto-container:first-child {top: 0; left: 0; }

  @include media-breakpoint-down(sm) {
    .masonry-container {
      .masonry-item {
        width: 50%;
        padding-top: 50%;
      }
      .masonry-item.obra-fotos-4
      {
        width: 100%;
        padding-top: 100%;
      }
      .masonry-item.obra-fotos-2
      {
        width: 100%;
        padding-top: 50%;
      }
    }
  }
</style>
