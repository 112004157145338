<template>
  <div class="di-grid-item d-flex flex-column position-relative" :ref="'di'+index" @click="selectDI()"
       :class="{'di-grid-item-selected': di.selected, 'di-grid-item-turnoff': di.turnOff}">
    <figure v-if="!di.selected">
      <Loading v-if="showLoading" :inside-container="true" />
      <FadeUp
        :src="firstImage"
        :src-placeholder="require('../../assets/img/default-img.jpg')"
        @load="imageLoaded()"></FadeUp>
    </figure>
    <DICarousel :items="di.archivos" v-else></DICarousel>
    <div class="di-grid-item-info d-flex flex-column">
      <span class="di-tit bcLiguria">{{di.nombre_lang[$i18n.locale]}}</span>
      <span class="di-description">{{di.descripcion_lang[$i18n.locale]}}</span>
    </div>
  </div>
</template>

<script>
import FadeUp from '../lazy-load-images/FadeImage'
import Helper from '@/helpers/Helper'
import DICarousel from '@/components/desing-insight/DICarousel'
import { isMobileOnly } from 'mobile-device-detect'
import Loading from '@/components/Loading'

export default {
  name: 'DIGridItem',
  props: ['di', 'index'],
  data () {
    return {
      isMobileOnly,
      showLoading: true
    }
  },
  components: {
    FadeUp,
    DICarousel,
    Loading
  },
  computed: {
    firstImage () {
      const files = this.diImages
      return (files[0] !== undefined) ? files[0] : ''
    },
    diImages () {
      return this.di.archivos.filter(a => Helper.isURLImage(a))
    }
  },
  methods: {
    selectDI () {
      this.$emit('diSelected', {
        di: this.di,
        index: this.index
      })
    },
    imageLoaded () {
      this.showLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
  .di-grid-item {
    cursor: pointer;
    figure  {
      margin: 0;
      overflow: hidden;
      height: 500px;
      position: relative;
      img {
        width: 100%;
        @supports (object-fit: cover) {
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .di-grid-item-info {
      padding: var(--main-space);
      span {
        color: #FFFFFF;
        &.di-tit {
          font-size: 30px;
          margin-bottom: calc(var(--main-space) / 2);
        }
        &.di-description {
          font-size: 16px;
          overflow: hidden;
          line-height: 1.3rem;
          position: relative;
          margin: 0;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }

    &.di-grid-item-selected {
      //grid-row: span 2;
      grid-column: span 2;
      .di-grid-item-info {
        background-color: #FFFFFF;
        height: 40%;
        span {
          color: #000000;
          &.di-description {
            font-size: 20px;
            -webkit-line-clamp: inherit;
            //overflow: auto;
            display: block;
          }
        }
      }
    }
    &.di-grid-item-turnoff {
      opacity: 0.2;
      figure {
        img {
          filter: grayscale(100%);
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .di-grid-item {
      figure {
        height: 400px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .di-grid-item {
      figure {
        height: 500px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .di-grid-item {
      &.di-grid-item-selected {
        grid-row: span 1;
        grid-column: span 1;
      }
      .di-grid-item-info {
        span {
          &.di-tit {
            font-size: 24px;
          }
        }
      }

      &.di-grid-item-turnoff {
        display: none !important;
      }
    }
  }
</style>
