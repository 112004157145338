<template>
  <header class="header" :class="{'header-white': whiteHeaderProp, 'menu-open': !showMenuIcon}">
    <div class="wrapper d-flex flex-row align-items-center justify-content-between">
      <div class="header-logo hideable" v-if="!showTitlePage" :class="{'show': showMenu}">
        <router-link :to="{name: 'Home', params: {lang: this.$i18n.locale}}">
          <SVGLogo></SVGLogo>
        </router-link>
      </div>
      <div class="header-tit hideable" :class="{'show': showMenu}" v-else><span class="bcLiguria">{{title}}</span></div>
      <nav id="header-navigation">
        <b-list-group horizontal id="msh-menu" class="d-flex flex-row align-items-center">
          <HeaderFamilies v-if="showFamilyIcons && !isMobile" :family-products="familyProducts" class="hideable" :class="{'show': showMenu}"
                          :family-selected="familySelected" :icons-compressed="iconsCompressed"
                          :white-icons="whiteIcons"></HeaderFamilies>

          <HeaderContacts class="hideable" v-if="showContactsIcons && !isMobile" :class="{'show': showMenu}"></HeaderContacts>

<!--          <GPTWLogoBlanco class="gptw hideable" :class="{'show': showMenu}" v-if="showContactsIcons && !isMobile"></GPTWLogoBlanco>-->

          <b-list-group-item class="d-none d-sm-block header-icon hideable" :class="{'show': showMenu}" v-if="!isMobile">
            <div id="header-family-grid-dot-icon">
              <FamilyGrid v-if="showFamilyGridIcon" @click="toggleGridFamilyDots()"></FamilyGrid>
            </div>
            <b-tooltip custom-class="header-family-icon-tooltip" target="header-family-grid-dot-icon"
                       placement="bottom" triggers="hover">
              <span class="bcLiguria text-nowrap text-white d-flex flex-column justify-content-end">{{$t('header.products')}}</span>
            </b-tooltip>
            <CloseMenu v-if="!showFamilyGridIcon" @click="toggleGridFamilyDots()"></CloseMenu>
          </b-list-group-item>
          <b-list-group-item class="d-none d-sm-block header-icon hideable" :class="{'show': showMenu}" v-if="!isMobile">
            <router-link :to="{name: 'Works'}" class="search-option">
              <Search></Search>
            </router-link>
          </b-list-group-item>
          <b-list-group-item class="header-icon d-flex flex-row align-items-center">
            <a @click="changeLanguage()" class="text-nowrap language-header-icon-container hideable" :class="{'show': showMenu}" v-if="isMobile">
              <component :is="languageIcon"></component>
            </a>
            <Menu v-if="showMenuIcon && !showMoreIcon" @click="toggleMenu()" @mouseover="toggleHoverMenu(true)"></Menu>
            <MoreIcon v-if="showMoreIcon" @click="toggleMenu()" @mouseleave="toggleHoverMenu(false)"></MoreIcon>
            <CloseMenu v-if="!showMenuIcon" @click="toggleMenu()"></CloseMenu>
          </b-list-group-item>
        </b-list-group>
      </nav>
    </div>
  </header>
</template>

<script>
import SVGLogo from '@/assets/img/logo.svg'
import FamilyGrid from '@/assets/img/Familia-Productos/family-grid.svg'
import Search from '@/assets/img/search-icon.svg'
import Menu from '@/assets/img/menu.svg'
import CloseMenu from '@/assets/img/close-icon.svg'
import MoreIcon from '@/assets/img/more-icon.svg'
import { EventBus } from '@/event-bus'
import FamiliasProductosService from '@/services/FamiliasProductosService'
import { isMobile } from 'mobile-device-detect'
import Helper from '@/helpers/Helper'
import HeaderContacts from './header/Contacts'
import HeaderFamilies from './header/Families'
import languageIconEN from '@/assets/img/iconoEN.svg'
import languageIconES from '@/assets/img/iconoES.svg'
// import GPTWLogoBlanco from '@/assets/img/gptw-blanco.svg'

export default {
  name: 'Header',
  props: ['whiteHeader'],
  components: {
    SVGLogo,
    FamilyGrid,
    Search,
    Menu,
    CloseMenu,
    MoreIcon,
    HeaderFamilies,
    HeaderContacts,
    languageIconEN,
    languageIconES
  },
  data () {
    return {
      isMobile,
      isScrolling: false,
      showMenu: true,
      scrollPos: 0,
      familyProducts: [],
      showLanguageIcon: true,
      showMenuIcon: true,
      showMoreIcon: false,
      showFamilyGridIcon: true,
      showFamilyIcons: true,
      showFundermaxIcon: false,
      showContactsIcons: false,
      familySelected: null,
      blackIcons: false,
      whiteIcons: false,
      iconsCompressed: false,

      showTitlePage: false,
      title: '',
      currentTitlePage: ''
    }
  },
  computed: {
    whiteHeaderProp: {
      get () { return this.whiteHeader },
      set (val) { this.$emit('update:value', val) }
    },
    familyProductsToShow () {
      if (!this.showFamilyIcons) {
        return []
      }
      return this.familyProducts
    },
    languageIcon () {
      return (this.$i18n.locale === 'en') ? 'languageIconEN' : 'languageIconES'
    }
  },
  methods: {
    setTitlePage (title) {
      this.title = title
    },
    toggleShowTitle (toggle) {
      this.showTitlePage = toggle
    },
    listenOnScroll () {
      let pos = this.scrollPos
      window.addEventListener('scroll', (e) => {
        pos = window.scrollY
        if (!Helper.isHome() && !Helper.isFundermax()) {
          // this.showMenu = (pos < this.scrollPos)
          this.showMenu = pos === 0
          this.scrollPos = pos
        } else {
          let el = document.getElementById('home-section-services')
          if (Helper.isFundermax()) {
            el = document.getElementById('fundermax-section-services')
          }
          if (el !== null) {
            // this.showMenu = (pos < this.scrollPos || pos < el.offsetHeight)
            this.showMenu = pos < (el.offsetHeight - 200) // DEJO 200PX PARA QUE NO SE ENCIME CON EL TITULO
            this.scrollPos = pos
          }
        }
      })
    },
    isFamilySelected (family) {
      return (this.familySelected !== null && this.familySelected.id === family.id)
    },
    getFamilias () {
      const service = new FamiliasProductosService()
      service.findAll((response) => {
        response.forEach(r => {
          this.familyProducts.push(FamiliasProductosService.parse(r))
        })
        // this.sortFamilies()
        EventBus.$emit('familyProducts', this.familyProducts)
        EventBus.$emit('toggleLoading', false)
      })
    },
    sortFamilies () {
      // ENVIO PIELES METALICAS AL COMIENZO
      const families = [...this.familyProducts]
      families.forEach((f, index) => {
        if (parseInt(f.id) === 1) {
          this.familyProducts.splice(index, 1)
          this.familyProducts.unshift(f)
        }
      })
    },
    toggleMenu () {
      this.showMenuIcon = !this.showMenuIcon
      this.showMenu = !this.showMenuIcon || (window.scrollY === 0)
      this.toggleContactsIcons(!this.showMenuIcon)
      EventBus.$emit('bodyScroll', this.showMenuIcon)
      if (this.showMenuIcon && this.$route.name === 'GPTW') {
        this.showFamilyIcons = false
      }
      // if (this.$route.path.includes('works')) {
      //   this.whiteHeaderProp = !this.whiteHeaderProp
      // }

      if (!this.showMenuIcon) {
        this.toggleShowTitle(false)
      } else {
        if (this.currentTitlePage !== '') {
          this.setTitlePage(this.currentTitlePage)
          this.toggleShowTitle(true)
        } else {
          this.toggleShowTitle(false)
        }
      }
    },
    toggleHoverMenu (toggle) {
      if (!isMobile) {
        this.showMoreIcon = toggle
      }
    },
    toggleGridFamilyDots () {
      this.showFamilyGridIcon = !this.showFamilyGridIcon
      this.toggleContactsIcons(!this.showFamilyGridIcon)

      if (!this.showFamilyGridIcon) {
        this.setTitlePage(this.$t('header.products'))
        this.toggleShowTitle(true)
      } else {
        if (this.currentTitlePage !== '') {
          this.setTitlePage(this.currentTitlePage)
          this.toggleShowTitle(true)
        } else {
          this.toggleShowTitle(false)
        }
      }

      EventBus.$emit('bodyScroll', this.showFamilyGridIcon)
    },
    toggleContactsIcons (show) {
      this.showFamilyIcons = !show
      this.showContactsIcons = show
      this.showFundermaxIcon = !show
    },
    hideContactsIcons () {
      this.showContactsIcons = false
    },
    changeLanguage () {
      EventBus.$emit('changeLanguage')
    }
  },
  mounted () {
    this.listenOnScroll()
    this.getFamilias()

    EventBus.$on('getFamilies', () => {
      return this.familyProducts
    })

    EventBus.$on('toggleMenuIcon', (toggle) => {
      this.showMenuIcon = toggle
    })
    EventBus.$on('toggleGridFamilyIcon', (toggle) => {
      this.showFamilyGridIcon = toggle
    })
    EventBus.$on('headerFamilySelected', (family) => {
      this.familySelected = family
      this.iconsCompressed = (family !== null)
    })
    EventBus.$on('toggleHeaderFamilyIcons', (toggle) => {
      this.showFamilyIcons = toggle
    })
    EventBus.$on('toggleHeaderBlack', (toggle) => {
      this.blackHeader = toggle
    })
    EventBus.$on('showTitlePage', (titlePage) => {
      this.currentTitlePage = titlePage
      this.setTitlePage(titlePage)
      this.toggleShowTitle(true)
    })
    EventBus.$on('showLogo', () => {
      this.currentTitlePage = ''
      this.setTitlePage('')
      this.toggleShowTitle(false)
    })
    EventBus.$on('showContactsIcons', (show) => {
      this.toggleContactsIcons(show)
    })
  },
  watch: {
    showMenuIcon: {
      handler () {
        EventBus.$emit('toggleMenu', !this.showMenuIcon)
        if (!this.showMenuIcon || this.$route.name === 'GPTW') {
          EventBus.$emit('toggleGridFamilyDots', false)
        }
      }
    },
    showFamilyGridIcon: {
      handler () {
        EventBus.$emit('toggleGridFamilyDots', !this.showFamilyGridIcon)
        if (!this.showFamilyGridIcon) {
          EventBus.$emit('toggleMenu', false)
          this.hideContactsIcons()
        }
      }
    }
  }
}
</script>

<style lang="scss">

  .hideable {
    transform: translateY(calc(calc(-1 * var(--main-space)) * 3));
    transition: transform 0.5s ease-in-out;
    &.show {
      transform: translateY(0);
    }
  }

  .header {
    width: 100%;
    padding: var(--main-space) 0;
    position: fixed;
    z-index: 11;

    /*&.header-black {
      background-color: #000000;
    }*/
    &.header-white {
      background-color: #FFFFFF;
    }

    .header-logo {
      height: var(--main-space);
      svg {
        fill: #ffffff;
        height: 100%;
      }
    }
    .header-tit {
      height: var(--main-space);
      span {
        color: #FFFFFF;
        font-size: 40px;
        height: 100%;
        display: inline-block;
      }
    }

    &.header-white {
      background-color: #FFFFFF;
      .header-tit {
        span {
          color: #000000;
        }
      }
    }

    &.menu-open {
      #header-navigation {
        margin-right: 5px;
      }
    }
  }

  .logo-fundermax-header {
    height: var(--main-space);
    width: auto !important;
    margin-right: calc(var(--main-space) / 5 * 4);
  }

  .list-group-item.header-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: calc(var(--main-space) / 5 * 4);
    border-radius: 0;

    &:last-child {
      margin-right: 0;
    }

    svg {
      width: var(--main-space);
    }
  }

  .gptw {
    height: calc(var(--main-space) * 1.5);
    margin-right: calc(var(--main-space) / 2);
  }

  /*.header #msh-menu .family-grid-option,
  #msh-menu .search-option,
  #msh-menu svg {
    fill: #ffffff;
  }*/

  .header {
    .header-icon {
      svg {
        fill: #FFFFFF;
        path {
          fill: #FFFFFF;
        }
      }

      .language-header-icon-container {
        margin-right: calc(var(--main-space) / 2);
        svg {
          height: var(--main-space);
        }
      }
    }
    &.header-white {
      .header-icon {
        svg {
          fill: #000000;
          path {
            fill: #000000;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .header {
      .header-tit {
        span {
          font-size: 24px;
        }
      }
    }
  }
</style>
