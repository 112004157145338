import Service from './Service'
export default class ProductosService extends Service {
  constructor () {
    super()
    this.paramsURL = {
      producto: 'productos.php'
    }
  }

  static parse (product) {
    return {}
  }

  findById (params, callbackSuccess, callbackHandleError) {
    this.ajax('GET', this.paramsURL.producto + '?id=' + params.id, {}, callbackSuccess, callbackHandleError)
  }
}
