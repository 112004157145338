<template>
  <div class="person-container d-flex flex-row">
    <div class="person person-separator d-flex flex-column justify-content-center align-items-center">
      <figure class="p-4">
        <transition name="fade-in-down" mode="out-in">
          <img :src="require('@/assets/img/partners/' + partner.img)" alt="">
        </transition>
      </figure>
    </div>
    <div class="person">
      <div class="person-info-container d-flex flex-column">
        <span class="person-name">{{partner.name}}</span>
        <span class="person-dir">{{partner.address}}</span>
        <span class="person-dir">{{partner.contact}}</span>
        <a :href="partner.webLink" target="_blank" class="d-flex flex-column mr-3"><span class="person-web mt-2">{{partner.web}}</span></a>
        <div class="person-social-container d-flex flex-row align-items-center mt-4">
          <a v-if="partner.mail" :href="'mailto:' + partner.mail" class="d-flex flex-column mr-3"><MailIcon /></a>
          <a v-if="partner.instagram" :href="partner.instagram" target="_blank" class="mr-3"><InstagramIcon /></a>
          <a v-if="partner.linkedin" :href="partner.linkedin" target="_blank" class="mr-3"><LinkedinIcon /></a>
          <a v-if="partner.phone" :href="'tel:' + partner.phone"><PhoneIcon /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailIcon from '../assets/img/mail-icon.svg'
import InstagramIcon from '../assets/img/instagram-icon.svg'
import LinkedinIcon from '../assets/img/linkedin-icon.svg'
import PhoneIcon from '../assets/img/telefono-icon.svg'
export default {
  name: 'PartnerBox',
  props: {
    partner: {
      type: Object,
      required: true
    }
  },
  components: {
    MailIcon,
    InstagramIcon,
    LinkedinIcon,
    PhoneIcon
  }
}
</script>

<style scoped lang="scss">
.person-container {
  width: calc(100% / 3);
  &:hover {
    .person {
      figure {
        img {
          -webkit-filter: grayscale(0);
          filter: none;
        }
      }
    }
    .person-info-container {
      .person-name, .person-web {
        color: #FFFFFF;
      }
      .person-dir {
        color: #DCDCDC;
      }
    }
  }
}
.person {
  //width: 50%;
  //padding-top: 50%;
  background-color: #000000;
  position: relative;
  flex: 1;
  figure {
    background-color: #000000;
    //position: absolute;
    //top: 0;
    //left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    //overflow: hidden;

    //background-repeat: no-repeat;
    //background-position: center;
    //background-size: cover;
    img {
      width: 100%;
      filter: gray;
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
      //transition: opacity 0.1s ease-in-out;
      //opacity: 0;
      //@supports (object-fit: cover) {
      //  height: 100%;
      //  object-fit: cover;
      //  object-position: center;
      //}
      &.selected {
        opacity: 1;
      }
    }
  }
}
.person-separator {
  background-color: $footer-text;
  position: relative;
  width: 300px;
  //svg {
  //  width: calc(100% - calc(var(--main-space) * 2));
  //  position: absolute;
  //  top: 50%;
  //  left: 50%;
  //  transform: translate(-50%, -50%);
  //}
}
.person-info-container {
  //position: relative;
  //position: absolute;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  //-webkit-transform: translate(-50%, -50%);
  //-moz-transform: translate(-50%, -50%);
  //width: 100%;
  //height: 100%;
  padding: calc(var(--main-space) / 2);
}
.person-name {
  font-size: 18px;
  line-height: 24px;
  color: $footer-text;
  font-weight: 700;
  margin-bottom: calc(var(--main-space) / 5);
}
.person-dir {
  font-size: 18px;
  line-height: 24px;
  color: $footer-text;
}
.person-web {
  font-size: 14px;
  line-height: 24px;
  color: $footer-text;
  font-weight: 700;
}
.person-social-container {
  width: 100%;
  padding: 0;
  min-height: 30px;
  //position: absolute;
  //left: calc(var(--main-space) / 2);
  //bottom: calc(var(--main-space) / 2);
}
.person-social-container a {
  width: 1.5rem;
  height: 1.5rem;
}
.person-social-container a svg,
.person-social-container a svg path
{
  fill: $footer-text;
}
.person-social-container a:hover svg,
.person-social-container a:hover svg path {
  fill: #FFFFFF !important;
}

.person-container:nth-of-type(6n),
.person-container:nth-of-type(6n - 1),
.person-container:nth-of-type(6n - 2)
{
  //flex-direction: row-reverse !important;
}

@include media-breakpoint-down(md) {
  .person-container {
    width: calc(100% / 2);
    &:nth-of-type(1n) {
      flex-direction: row !important;
    }
    &:nth-of-type(4n),
    &:nth-of-type(4n - 1)
    {
      //flex-direction: row-reverse !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .person-container {
    width: 100%;
    margin-bottom: 4rem;
    .person-separator {
      svg {
        width: 60%;
      }
    }
    &:nth-of-type(1n) {
      flex-direction: row !important;
    }
    &:nth-of-type(2n)
    {
      //flex-direction: row-reverse !important;
    }
  }
}
</style>
