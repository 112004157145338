<template>
  <div class="home-container">
    <ImagesSlider id="home-images-slider" :images="imagesSlider" v-if="imagesSlider.length > 0"
                  @changeSlider="changeSlider"></ImagesSlider>
    <div class="dot-overlay" v-if="sliderElemns.showOverlay"></div>
    <div class="wrapper">
      <section class="hero-slider">
        <div class="slider-content d-flex flex-column justify-content-center fullH scrollAnimation1">
          <vue-typer class="text-white bcLiguria" text="Shaping the future" :repeat="0"></vue-typer>
          <div class="d-flex flex-row">
            <vue-typer text="of " :repeat="0" :pre-type-delay="1500" @completed="showHomeTyperLast = true"></vue-typer>
            <vue-typer :text="['design', 'architecture', 'metal']" v-if="showHomeTyperLast"
                       :type-delay="70" erase-style="clear" caret-animation='expand'></vue-typer>
          </div>
        </div>
        <div class="mision-container d-flex align-items-center fullH animationText scrollAnimation2">
          <p class="text-white bcLiguria">{{$t('home.text')}}</p>
        </div>
        <div class="arrow-slider" v-if="!isMobile">
          <router-link :to="{name: 'Fundermax'}">
            <ArrowIcon v-if="sliderElemns.showArrow"></ArrowIcon>
          </router-link>
        </div>
        <div class="d-none d-md-block mouse-container" v-if="!isMobile">
          <MouseIcon v-if="sliderElemns.showMouse"></MouseIcon>
        </div>
        <floatingButtonsContainer v-if="!isMobile && sliderElemns.showFloatingButtons" :hide-mobile="true"></floatingButtonsContainer>
        <HomeSliderInfo v-if="sliderElemns.showSliderInfo && obraSliderSelected !== null" :data="obraSliderSelected"></HomeSliderInfo>
      </section>
      <section class="services scrollAnimation3 d-flex flex-column justify-content-end" id="home-section-services">
        <h2 class="text-white bcLiguria services-tit">{{$t('about.titleServices')}}</h2>
        <div class="services-row">
          <div class="services-container">
            <HomeService v-for="(service, indexService) in $t('about.services')" :name="service.name"
                         :description="service.description" :key="indexService" :classes="['height-auto', 'min-lg-height']"></HomeService>
          </div>
        </div>
      </section>
      <section class="projects scrollAnimation4">
        <div class="projects-container fullrow">
          <MasonryObras :obras="obras" :division-white="false" v-if="showObras" @workSelected="selectWork"></MasonryObras>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { fadeInOutSection } from '@/assets/js/animations'
import ArrowIcon from '../assets/img/arrow-icon.svg'
import MouseIcon from '../assets/img/mouse-icon.svg'
import floatingButtonsContainer from '../components/floatingButtonsContainer'
import { EventBus } from '@/event-bus'
import HomeSliderInfo from '../components/HomeSliderInfo'
import HomeService from '../components/HomeService'
import MasonryObras from '../components/MasonryObras'
import services from '../assets/js/services'
import { VueTyper } from 'vue-typer'
import ObrasService from '../services/ObrasService'
import { isMobile } from 'mobile-device-detect'
import ImagesSlider from '@/components/ImagesSlider'
import Helper from '@/helpers/Helper'

export default {
  name: 'Home',
  components: {
    ImagesSlider,
    ArrowIcon,
    MouseIcon,
    floatingButtonsContainer,
    HomeService,
    HomeSliderInfo,
    MasonryObras,
    VueTyper
  },
  data () {
    return {
      isMobile,
      showHomeTyperLast: false,
      showSlider: false,
      showArrow: true,
      services: services,
      sliderElemns: {
        showArrow: true,
        showMouse: true,
        showSliderInfo: true,
        showOverlay: true,
        showFloatingButtons: true
      },
      obras: [],
      showObras: false,
      obrasSlider: [],
      imagesSlider: [],
      obraSliderSelected: null,
      dataGetted: 0
    }
  },
  methods: {
    changeSlider (sliderPos) {
      this.obraSliderSelected = this.obrasSlider[sliderPos]
    },

    getObras () {
      const service = new ObrasService()
      service.findAll({ des: 1 }, (response) => {
        this.obras = response
        this.showObras = true
        this.dataGetted++
      })
    },
    getObrasSlider () {
      const service = new ObrasService()
      this.obrasSlider = []
      this.imagesSlider = []
      service.findAll({ sid: 1 }, (response) => {
        this.obrasSlider = response
        this.obrasSlider.forEach(o => {
          const img = (isMobile) ? o.fotoBannerMobile : o.fotoBanner
          this.imagesSlider.push(img)
        })
        this.changeSlider(0)
        this.dataGetted++
      })
    },
    selectWork (work) {}
  },
  mounted () {
    fadeInOutSection()

    window.addEventListener('scroll', (e) => {
      let footerHeight = 0
      if (Helper.isBottomOfPage()) {
        footerHeight = (document.getElementsByClassName('footer')[0].clientHeight)
      }
      if (document.getElementById('home-images-slider') !== null) {
        document.getElementById('home-images-slider').style.transform = 'translateY(-' + footerHeight + 'px)'
      }
    })
    // if (!isMobile) {
    //   window.addEventListener('scroll', (e) => {
    //     let footerHeight = 0
    //     if (Helper.isBottomOfPage()) {
    //       footerHeight = (document.getElementsByClassName('footer')[0].clientHeight)
    //     }
    //     if (document.getElementById('home-images-slider') !== null) {
    //       document.getElementById('home-images-slider').style.transform = 'translateY(-' + footerHeight + 'px)'
    //     }
    //   })
    // }

    EventBus.$on('toggleSliderFirstElemns', (toggle) => {
      this.sliderElemns.showMouse = toggle
      this.sliderElemns.showSliderInfo = toggle
      this.sliderElemns.showOverlay = toggle
    })
    EventBus.$on('toggleSliderFinalElemns', (toggle) => {
      this.sliderElemns.showArrow = toggle
      this.sliderElemns.showFloatingButtons = toggle
    })
    EventBus.$emit('headerFamilySelected', null)

    this.getObras()
    this.getObrasSlider()

    EventBus.$emit('showLogo')
  },
  watch: {
    dataGetted: {
      handler () {
        EventBus.$emit('toggleLoading', true)
        if (this.dataGetted === 2) {
          EventBus.$emit('toggleLoading', false)
        }
      }
    }
  }
}
</script>

<style lang="scss">

  .dot-overlay {
    width: 100%;
    height: 100%;
    //background-color: rgba(0, 0, 0, 0.5);
    //background-image: radial-gradient(#000 50%, transparent 10%);
    //background-size: 4px 4px;
    --circle_w: 1px;
    --circle_color: #000;
    --bg_color: transparent;
    --m_bet_circle: 2px;
    --A: calc(var(--circle_w) + var(--m_bet_circle));
    --B: calc(var(--circle_w) / 2);
    background: 0 0/var(--A) var(--A) radial-gradient(circle at center,
      var(--circle_color) var(--B),
      var(--bg_color) var(--B)), center/cover no-repeat;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
  }

  .wrapper {
    z-index: 2;
    position: relative;
  }

  .slider-content h2{
    font-size: 3rem;
  }
  .hero-slider{
      position: relative;
  }
  .hero-slider .arrow-slider{
    position: fixed;
    top: 50vh;
    right: 50px;
    width: calc(var(--main-space) / 5 * 7);
    animation: arrowLoop infinite 2s;
  }
  .arrow-container svg, .arrow-slider svg, .mouse-container svg{
    fill: #ffffff;
  }
  .hero-slider .mouse-container{
    position: fixed;
    bottom: var(--main-space);
    left: 50vw;
    width: var(--main-space);
    svg {
      #wheel {
        animation: mouseLoop infinite 2s;
      }
    }
  }

  .hero-slider .vue-typer {
    margin-bottom: 19px;
    display: inline-block;
    .custom.char {
      color: #FFFFFF;
      font-size: 61px;
      font-family: 'BC-Liguria', sans-serif;
    }
  }

  .mision-container p {
    font-size: 36px;
    width: 820px;
    line-height: calc(var(--main-space)/5*6);
  }
  section.services{
      //padding-top: 25vh;
    min-height: 100vh;
  }
  section.services h2{
      margin-bottom: var(--main-space);
  }
  .services-row{
    width: calc(100% + calc(var(--main-space) * 2));
    margin-left: calc(-1 * var(--main-space));
    margin-bottom: 1px;
    position: relative;
    .services-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1px;
      grid-auto-flow: row dense;
    }
  }

  section.projects{
      margin-top: 300px;
  }
  section.projects .projects-container{
      /*padding-top: 15vh;*/
    background-color: #000000;
    position: relative;
    z-index: 9;
  }

  .animationText{
      opacity: 0;
  }
  .obra {
    border: 1px solid red;
    width: 25%;
  }
  .obra-2 {
    width: 50%;
  }
  .obra-4 {
    width: 50%;
  }

  .obra .obra-foto {
    margin: 1px;
  }
  .obra-1 .obra-foto {
    width: 100%;
    border: 1px solid blue;
  }
  .obra-2 .obra-foto,
  .obra-4 .obra-foto
  {
    width: calc(50% - 2px);
    padding-top: calc(50% - 2px);
    border: 1px solid green;
  }

  @include media-breakpoint-down(lg) {
    .mision-container p {
      width: 70%;
      font-size: 30px;
      line-height: 150%;
    }
    section.services {
      //padding-top: 20vh;
    }
    .services-row{
      .services-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  @include media-breakpoint-down(md) {
    .mision-container p {
      width: 80%;
    }
    section.services {
      //padding-top: 15vh;
    }
  }
  @include media-breakpoint-down(sm) {
    .hero-slider .arrow-slider {
      display: none;
    }
    .hero-slider .vue-typer {
      margin-bottom: 10px;
      .custom.char {
        font-size: 32px;
      }
    }
    section.services {
      height: auto !important;
    }
    .services-row{
      .services-container {
        grid-template-columns: 1fr;
      }
    }
    section.projects {
      margin-top: 0;
    }
    .mision-container p {
      font-size: 24px;
      width: 100%;
      line-height: calc(var(--main-space) / 3 * 4);
    }
  }
</style>
