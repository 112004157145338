<template>
    <div class="menu-container" :class="{'mobile-safari': isMobileSafari}" :style="{top: yPos + 'px'}">
<!--        <div class="menu-space"></div>-->
      <transition-group name="fade-in-right-group" mode="out-in" tag="ul" class="menu-options-container d-flex flex-wrap flex-row" :style="{ '--total': 9 }">
        <li class="menu-option text-white" :key="'menu-li-home'" v-if="showOptions" :style="{'--i': 1}">
          <router-link :to="{name: 'Home', params: {lang: this.$i18n.locale}}" @click.native="goTo('Home')" class="bcLiguria">{{$t('menu.home')}}</router-link></li>
        <li class="menu-option text-white" :key="'menu-li-about'" v-if="showOptions" :style="{'--i': 2}">
          <router-link :to="{name: 'About', params: {lang: this.$i18n.locale}}" @click.native="goTo('About')" class="bcLiguria">{{$t('menu.about')}}</router-link></li>
        <li class="menu-option text-white" :key="'menu-li-product'" v-if="showOptions" :style="{'--i': 3}">
          <a href="#" class="bcLiguria" @click.stop.prevent="showFamilyGrid()">{{$t('menu.products')}}</a></li>
        <li class="menu-option text-white" :key="'menu-li-materials'" v-if="showOptions" :style="{'--i': 4}">
          <router-link :to="{name: 'Materials', params: {lang: this.$i18n.locale}}" @click.native="goTo('Materials')" class="bcLiguria">{{$t('menu.materials')}}</router-link></li>
        <li class="menu-option text-white" :key="'menu-li-works'" v-if="showOptions" :style="{'--i': 5}">
          <router-link :to="{name: 'Works', params: {lang: this.$i18n.locale}}" @click.native="goTo('Works')" class="bcLiguria">{{$t('menu.proyects')}}</router-link></li>
        <li class="menu-option text-white" :key="'menu-li-design-insight'" v-if="showOptions" :style="{'--i': 6}">
          <router-link :to="{name: 'DesignInsight', params: {lang: this.$i18n.locale}}" @click.native="goTo('DesignInsight')" class="bcLiguria">{{$t('menu.di')}}</router-link></li>
        <li class="menu-option text-white" :key="'menu-li-people'" v-if="showOptions" :style="{'--i': 7}">
          <router-link :to="{name: 'People', params: {lang: this.$i18n.locale}}" @click.native="goTo('People')" class="bcLiguria">{{$t('menu.team')}}</router-link></li>
        <li class="menu-option text-white" :key="'menu-li-videos'" v-if="showOptions" :style="{'--i': 8}">
          <router-link :to="{name: 'Videos', params: {lang: this.$i18n.locale}}" @click.native="goTo('Videos')" class="bcLiguria">{{$t('menu.videos')}}</router-link></li>
        <li class="menu-option text-white" :key="'menu-li-contact'" v-if="showOptions" :style="{'--i': 9}">
          <router-link :to="{name: 'Contact', params: {lang: this.$i18n.locale}}" @click.native="goTo('Contact')" class="bcLiguria">{{$t('menu.contact')}}</router-link></li>
        <li class="menu-option text-white" :key="'menu-li-partners'" v-if="showOptions" :style="{'--i': 10}">
          <router-link :to="{name: 'Partners', params: {lang: this.$i18n.locale}}" @click.native="goTo('Partners')" class="bcLiguria">{{$t('menu.partners')}}</router-link></li>
        <li class="menu-option text-white" :key="'menu-li-fundermax'" v-if="showOptions" :style="{'--i': 11}">
          <router-link :to="{name: 'Fundermax', params: {lang: this.$i18n.locale}}" @click.native="goTo('Fundermax')" class="bcLiguria">{{$t('menu.fundermax')}}</router-link></li>
        <li class="menu-option menu-option-gptw text-white d-flex align-items-center justify-content-center" :key="'menu-li-gptw'"
            v-if="showOptions && !isMobile" :style="{'--i': 12}">
<!--          <router-link :to="{name: 'GPTW', params: {lang: this.$i18n.locale}}" @click.native="goTo('GPTW')" class="bcLiguria">{{$t('menu.gptw')}}</router-link>-->
        </li>
      </transition-group>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { isMobile, isMobileSafari } from 'mobile-device-detect'
export default {
  name: 'Menu',
  data () {
    return {
      yPos: 0,
      showOptions: false,
      isMobile,
      isMobileSafari
    }
  },
  computed: {
  },
  methods: {
    goTo (name) {
      EventBus.$emit('toggleMenu', false)
      // this.$router.push({ name: name })
    },
    showFamilyGrid () {
      EventBus.$emit('toggleGridFamilyDots', true)
    }
  },
  mounted () {
    EventBus.$emit('bodyScroll', false)
    this.yPos = 0
    if (isMobile) {
      this.yPos = window.scrollY
    }
    this.showOptions = true
  }
}
</script>

<style scoped lang="scss">
    //.menu-space{
    //  height: calc(20vh - 1px);
    //  margin-bottom: 1px;
    //  width: 100vw;
    //  /*background-color: #000000;*/
    //  @include backgroundTransparency(#000000, .8);
    //}
    .menu-container{
      width: 100vw;
      height: 100vh;
      position: fixed;
      z-index: 10;
      @include backgroundTransparency(#000000, 1);
    }
    .menu-options-container{
      height: calc(100% - calc(var(--main-space) * 4));
      margin-top: calc(var(--main-space) * 4);
      background-color: $main-color;
      border-top: 1px solid $main-color;
    }
    .menu-option {
      width: calc((100vw / 4) - 1px);
      height: calc(100% / 3);
      margin: 0 1px 1px 0px;
      background-color: #000000;
    }
    .menu-option a {
      /*height: calc(100% / 3);*/
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      /*background-color: #000000;*/
      @include backgroundTransparency(#000000, .5);
      font-size: 2rem;
    }

    .menu-option.menu-option-gptw {
      svg {
        height: 60%;
      }
    }

    @include media-breakpoint-down(sm) {
      .menu-container {
        position: absolute;
        .menu-options-container {
          flex-direction: column !important;
          flex-wrap: nowrap !important;
          height: auto;
          max-height: 100vh;
          overflow-y: auto;
          margin: 0;
          padding-top: calc(var(--main-space) * 3);
          background-color: #000000;
          .menu-option {
            width: 100%;
            margin: 0 0 1px 0;
            border-top: 1px solid $main-color;
            a {
              width: 100%;
              height: auto;
              padding: calc(var(--main-space)*2) 0;
              font-size: 1.4rem;
            }
          }
        }
        &.mobile-safari {
          .menu-options-container {
            //padding-bottom: 70px;
          }
        }
      }
    }
</style>
