<template>
  <div>
    <div class="under-construccion d-flex flex-column justify-content-center">
      <div class="wrapper">
        <div class="under-construccion-text-container">
          <vue-typer text="Site under construction" :repeat="0"></vue-typer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTyper } from 'vue-typer'
export default {
  name: 'UnderConstruccion',
  components: {
    VueTyper
  }
}
</script>

<style lang="scss">
  .under-construccion {
    background-image: url("../assets/img/home-slider/fondo-app.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
  }
  .under-construccion-text-container .vue-typer .custom.char {
    color: #FFFFFF;
    font-size: 3rem;
    font-family: 'BC-Liguria', sans-serif;
  }
</style>
