<template>
  <div class="about-section-container" :class="['gtc-' + data.columns, data.classes]">
    <HomeService v-for="(item, index) in data.items" :name="item.name" :description="item.description"
                 :key="index" :img="item.img" :classes="[homeServiceClasses, 'height-auto']" :not-hover="true"></HomeService>
  </div>
</template>

<script>
import HomeService from './HomeService'
export default {
  name: 'AboutSection',
  props: ['data', 'homeServiceClasses'],
  components: {
    HomeService
  }
}
</script>

<style scoped lang="scss">
  .about-section-container {
    width: calc(100% + calc(var(--main-space) * 2));
    margin-left: calc(-1 * var(--main-space));
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1px;
    grid-auto-flow: row dense;
    &.gtc-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @include media-breakpoint-down(md) {
    .about-section-container {
      grid-template-columns: repeat(2, 1fr);
      &.gtc-2 {
        grid-template-columns: 1fr;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .about-section-container {
      grid-template-columns: 1fr;
    }
  }
</style>
