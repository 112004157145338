import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '@/i18n/locales'
import Helper from '@/helpers/Helper'

Vue.use(VueI18n)

export default new VueI18n({
  locale: Helper.getCurrentLocale(),
  fallbackLocale: 'es',
  messages: locales
})
