import { render, staticRenderFns } from "./MasonryObrasPartner.vue?vue&type=template&id=2729b465&scoped=true"
import script from "./MasonryObrasPartner.vue?vue&type=script&lang=js"
export * from "./MasonryObrasPartner.vue?vue&type=script&lang=js"
import style0 from "./MasonryObrasPartner.vue?vue&type=style&index=0&id=2729b465&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2729b465",
  null
  
)

export default component.exports