<template>
  <div class="family-product-landing-container" v-bind:style="styleBackground">
    <div class="family-product-container wrapper d-flex flex-column">
      <h2 class="bcLiguria family-product-tit" :style="{color: familyProduct.color}">{{familyProduct.name_lang[$i18n.locale]}}</h2>
      <p class="family-product-description">{{familyProduct.text1_lang[$i18n.locale]}}</p>
      <router-link v-if="!isMobile" class="pdf-download-container d-inline-flex flex-row"
                   :to="{name: 'Works', params: {filtroFamilia: familyProduct.id}}">
        <span :style="{'background-color': familyProduct.color}">
          {{$t('familyProducts.seeFamilyProjectsButton',{family: familyProduct.name_lang[$i18n.locale]})}}</span>
      </router-link>

      <div class="family-product-products-overlay-container" :class="{'fullrow': !isMobileOnly}">
        <div class="family-product-products-container wrapper" v-if="familyProduct.productos.length > 0">
          <router-link class="family-product-product position-relative" v-for="(product, index) in familyProduct.productos" :key="index"
                       :to="{name: 'product', params: {productPath: product.id + '-' + product.path, object: product}}">

            <FadeUp
              v-if="product.fotos.length > 0"
              :src="firstImgProduct(product)"
              :src-placeholder="require('../assets/img/default-img.jpg')"
              :classes="['background-solid']"
            ></FadeUp>
            <img src="../assets/img/default-img.jpg" alt="" v-else>

            <div class="overlay flex-column">
              <span :style="{color: familyProduct.color}">{{familyProduct.name_lang[$i18n.locale]}}</span>
              <span :style="{color: familyProduct.color}">{{product.nombre}}</span>
              <figure class="product-overlay-family-icon">
                <img :src="familyProduct.icon" alt="">
              </figure>
            </div>
          </router-link>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import FamiliasProductosService from '../services/FamiliasProductosService'
import { EventBus } from '@/event-bus'
import { isMobile, isMobileOnly } from 'mobile-device-detect'
import FadeUp from '../components/lazy-load-images/FadeImage'
import { overlayFamilyProducts } from '@/assets/js/animations'
// import Helper from '@/helpers/Helper'
export default {
  name: 'FamilyProduct',
  components: {
    FadeUp
  },
  data () {
    return {
      isMobile,
      isMobileOnly,
      familyProduct: {
        name_lang: {
          es: '',
          en: ''
        },
        text1_lang: {
          es: '',
          en: ''
        },
        productos: []
      },
      styleBackground: {
        backgroundImage: ''
      }
    }
  },
  computed: {
    // familyDescription () {
    //   return this.familyProduct.text1 || 'Es un sistema de lamas fijas con base triangular que proporciona control solar efectivo a los espacios y efectos visuales'
    // }
  },
  methods: {
    firstImgProduct (product) {
      // return product.fotos[0].foto + '?' + Helper.generateRandomString(10)
      return product.fotos[0].foto
    },
    updateBackgroundImage () {
      if (this.familyProduct.foto !== '') {
        this.styleBackground.backgroundImage = 'url(' + this.familyProduct.foto + ')'
      }
    },
    getFamilyProduct (id) {
      id = id || this.$route.params.familyProduct.split('-')[0]
      EventBus.$emit('toggleLoading', true)
      const service = new FamiliasProductosService()
      service.findById({ id: id }, (response) => {
        this.familyProduct = FamiliasProductosService.parse(response)
        EventBus.$emit('headerFamilySelected', this.familyProduct)
        if (!isMobile) {
          this.updateBackgroundImage()
        }
        EventBus.$emit('toggleLoading', false)
      })
    }
  },
  mounted () {
    this.getFamilyProduct()
    EventBus.$emit('showLogo')
  },
  watch: {
    'familyProduct.productos': {
      deep: true,
      handler () {
        this.$nextTick(() => {
          overlayFamilyProducts()
        })
      }
    },
    $route: {
      handler () {
        this.familyProduct = {
          name_lang: {
            es: '',
            en: ''
          },
          text1_lang: {
            es: '',
            en: ''
          },
          productos: []
        }
        this.getFamilyProduct()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .family-product-landing-container {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    -webkit-animation: backgroundFadeIn 5s;
    animation: backgroundFadeIn 5s;
    @include backgroundTransparency(#000, 0.3);
    min-height: 100vh;
  }
  .family-product-container {
    padding-top: calc(var(--main-space) * 4);
    //padding-bottom: var(--main-space);
    .family-product-tit {
      font-size: 3rem;
      margin: 0;
      line-height: 100%;
    }
    .family-product-description {
      font-size: 30px;
      color: #FFFFFF;
      width: 80%;
      margin: var(--main-space) 0;
    }
  }

  .pdf-download-container {
    margin-bottom: var(--main-space);
    span {
      padding: calc(var(--main-space) / 2);
      margin-right: 1px;
      cursor: pointer;
      color: #000000;
      line-height: 22px;
      font-family: 'IBMPlexSans-SemiBold', sans-serif;
    }
  }

  .family-product-products-overlay-container {
    @include backgroundTransparency(#000000, 0.8);
    padding: var(--main-space) 0;
  }
  .family-product-products-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row dense;
    grid-gap: 1rem;
  }
  .family-product-product {
    width: 100%;
    padding-top: 100%;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.family-product-see-projects {
      span {
        color: #000000;
        position: absolute;
        top: calc(var(--main-space) / 2);
        left: calc(var(--main-space) / 2);
        width: calc(100% - calc(var(--main-space) * 2));
        font-size: 1.2rem;
        font-weight: 700;
      }
      .family-projects-icon {
        width: var(--main-space);
        height: var(--main-space);
        position: absolute;
        bottom: calc(var(--main-space) / 2);
        left: calc(var(--main-space) / 2);
        background-color: #000000;
      }
    }
  }
  @supports (object-fit: cover) {
    .family-product-product img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .family-product-product .overlay {
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: none;
    padding: calc(var(--main-space) / 2);
  }
  .family-product-product:hover > .overlay {
    display: flex;
  }

  .family-product-product .overlay span {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
  }
  .product-overlay-family-icon {
    width: var(--main-space);
    height: var(--main-space);
    position: absolute;
    left: calc(var(--main-space) / 2);
    bottom: calc(var(--main-space) / 2);
    margin: 0;
  }

  @include media-breakpoint-down(lg) {
    .family-product-container {
      .family-product-description {
        font-size: 30px;
        width: 100%;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .family-product-landing-container {
      background-image: none !important;
      .family-product-container {
        padding-top: calc(var(--main-space) * 3);
        .family-product-tit {
          padding-top: var(--main-space);
        }
        .family-product-description {
          width: 100%;
          font-size: 24px;
        }
        .family-product-products-container {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .family-product-landing-container {
      .family-product-container {
        .family-product-tit {
          font-size: 2rem;
        }
        .family-product-description {
          font-size: 1rem;
        }
        .family-product-products-container {
          grid-template-columns: repeat(1, 1fr);
          grid-gap: var(--main-space);
          width: calc(100% + calc(var(--main-space) * 2));
          margin-left: calc(-1 * var(--main-space));
          .overlay {
            display: block;
            opacity: 0;
          }
        }
      }
    }

    .pdf-download-container {
      div {
        width: auto;
        svg {
          width: var(--main-space);
        }
      }
    }
  }
</style>
