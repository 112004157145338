<template>
  <div class="wallpaper">
    <figure>
      <FadeInImage
        :src="require('@/'+wallpaperPath+wallpaper.img)"
        :src-placeholder="require('@/assets/img/default-img.jpg')"></FadeInImage>
    </figure>
    <div class="wallpaper-overlay" :style="{backgroundColor: wallpaper.familyColor}">
      <div class="wallpaper-info-container d-flex flex-row align-items-center">
        <component class="icon" :is="wallpaper.familyIcon"></component>
        <a download :href="require('@/'+wallpaperPath+wallpaper.img)"
           class="d-inline-flex flex-row align-items-center justify-content-center img-download-container" target="_blank">
          <span class="bcLiguria">{{$t('wallpapers.download')}} ({{wallpaper.size}} Mb)</span>
          <div class="d-flex flex-column justify-content-center">
            <DownloadIcon></DownloadIcon>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PielesMetalicasIcon from '@/assets/img/Familia-Productos/FamilyProductPielesMetalicas.svg'
import AcusticoIcon from '@/assets/img/Familia-Productos/FamilyProductAcustico.svg'
import BalkonIcon from '@/assets/img/Familia-Productos/FamilyProductBalkon.svg'
import CielorrasosIcon from '@/assets/img/Familia-Productos/FamilyProductCielorrasos.svg'
import DisenoParametricoIcon from '@/assets/img/Familia-Productos/FamilyProductDisenoParametrico.svg'
import EscalonesYplataformasIcon from '@/assets/img/Familia-Productos/FamilyProductEscalonesYplataformas.svg'
import FencesIcon from '@/assets/img/Familia-Productos/FamilyProductFences.svg'
import QuiebrasolesIcon from '@/assets/img/Familia-Productos/FamilyProductQuiebrasoles.svg'
import CustomIcon from '@/assets/img/Familia-Productos/FamilyProductCustom.svg'
import DownloadIcon from '@/assets/img/download-icon.svg'
import FadeInImage from '@/components/lazy-load-images/FadeImage'

export default {
  name: 'WallpaperCard',
  props: {
    wallpaper: {
      type: Object,
      required: true
    }
  },
  components: {
    PielesMetalicasIcon,
    AcusticoIcon,
    BalkonIcon,
    CielorrasosIcon,
    DisenoParametricoIcon,
    EscalonesYplataformasIcon,
    FencesIcon,
    QuiebrasolesIcon,
    CustomIcon,
    DownloadIcon,
    FadeInImage
  },
  data () {
    return {
      wallpaperPath: 'assets/img/wallpapers/'
    }
  }
}
</script>

<style scoped lang="scss">
.wallpaper {
  width: 100%;
  height: 300px;
  position: relative;

  figure {
    overflow: hidden;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
    }
    @supports (object-fit: cover) {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .wallpaper-overlay {
    position: relative;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    mix-blend-mode: hard-light;

    .wallpaper-info-container {
      height: 40px;
      position: absolute;
      bottom: var(--main-space);
      left: var(--main-space);

      svg {
        width: 40px;
        height: 40px;
        path {
          fill: #FFF;
        }
      }

      .img-download-container {
        background-color: #FFF;
        margin-left: calc(var(--main-space) / 2);
        height: 100%;
        padding: 0 calc(var(--main-space) / 2);

        span {
          color: #000;
          margin-right: calc(var(--main-space) / 2);
        }
        svg {
          width: 20px;
        }
        svg, path {
          fill: #000;
        }
      }
    }
  }

  &:hover {
    .wallpaper-overlay {
      display: flex;
    }
  }
}
</style>
