<template>
  <div class="videos-container w-100">
      <transition-group name="fade-in-right-group" mode="out-in" tag="div" class="videos-grid"
                        :style="{ '--total': videosToShow.length }">
        <div class="video video-grid-item" v-for="(video, index) in videosToShow" :key="'videos-' + index" :style="{backgroundImage: 'url(' + video.image + ')', '--i': index}">
          <div class="video-grid-overlay flex-column justify-content-center align-items-center" @click="showThisVideo(video)">
            <h4 class="video-tit bcLiguria">{{video.title}}</h4>
            <div class="play-button">
              <PlayIcon></PlayIcon>
            </div>
          </div>
        </div>
      </transition-group>

    <div class="video-player-container d-flex flex-column align-items-center justify-content-center" v-if="showVideo" @click="showVideo = !showVideo">
      <CloseIcon class="close-icon"></CloseIcon>
      <div class="video-player d-flex align-items-center justify-content-center" v-html="videoSelected.iframe">
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '../assets/img/close-icon.svg'
import vimeoVideos from '../assets/js/vimeo-videos'
import PlayIcon from '../assets/img/play-icon.svg'
import { EventBus } from '@/event-bus'
import { overlayVideos } from '@/assets/js/animations'

export default {
  name: 'Videos',
  components: {
    CloseIcon,
    PlayIcon
  },
  data () {
    return {
      showVideo: false,
      showVideos: false,
      videos: vimeoVideos,
      videoSelected: null
    }
  },
  computed: {
    videosToShow () {
      if (!this.showVideos) { return [] }
      return this.videos
    }
  },
  methods: {
    showThisVideo (video) {
      video = video || null
      this.videoSelected = video
      this.showVideo = true
    }
  },
  mounted () {
    this.showVideos = true
    // overlayVideos()
    this.$nextTick(() => {
      overlayVideos()
    })
    EventBus.$emit('showTitlePage', 'Videos')
    EventBus.$emit('toggleLoading', false)
  },
  watch: {
    '$i18n.locale': {
      handler () {
        EventBus.$emit('showTitlePage', this.$t('team.pageTitle'))
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .videos-container {
    margin-top: calc(var(--main-space) * 4);
    min-height: calc(100vh - calc(var(--main-space) * 4));
    .videos-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: row dense;
      /*grid-gap: var(--main-space);*/
      grid-gap: 1px;
      .video-grid-item {
        width: 100%;
        padding-top: 100%;
        position: relative;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .video-grid-overlay {
          background-color: rgba(0,0,0,0.5);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: calc(var(--main-space) / 2);
          display: none;
          cursor: pointer;

          .video-tit {
            color: #FFFFFF;
            text-align: center;
            font-size: 24px;
            //margin-bottom: calc(var(--main-space) / 2);
            margin-bottom: 0;
          }
          .play-button {
            position: absolute;
            bottom: calc(var(--main-space) / 2);
            left: calc(var(--main-space) / 2);
            width: var(--main-space);
            height: var(--main-space);

            svg {
              fill: #FFFFFF;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        &:hover > .video-grid-overlay {
          display: flex;
        }
      }
    }
  }

  .video-player-container {
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
  }
  .video-player-container .close-icon {
    fill: #FFFFFF;
    width: var(--main-space);
    position: absolute;
    top: var(--main-space);
    right: var(--main-space);
    cursor: pointer;
  }
  .video-player {
    width: 80%;
  }
  .video-player iframe {
    width: 100%;
  }

  @include media-breakpoint-down(lg) {
    .videos-container {
      .videos-grid {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  @include media-breakpoint-down(md) {
    .videos-container {
      .videos-grid {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .videos-container {
      .videos-grid {
        grid-template-columns: repeat(2, 1fr);
        .video-grid-item {
          .video-grid-overlay {
            display: flex;
            opacity: 0;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .videos-container {
      .videos-grid {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
</style>
