<template>
  <div>
    <b-button @click="$emit('clicked')">{{text}}</b-button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: ['text']
}
</script>

<style scoped lang="scss">
  button {
    background: red;
  }
</style>
