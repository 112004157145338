<template>
  <div class="product-container">
    <div class="product-slider fullH position-relative">
      <ImagesSlider :images="imagesSlider" v-if="imagesSlider.length > 0" @changeSlider="changeSlider"></ImagesSlider>
      <div class="slider-dots d-flex flex-column justify-content-center p-3" v-if="!isMobile">
        <div data-label="dot" class="dot" :class="{selected: (index === sliderPosActive)}" v-for="(foto, index) in product.fotos"
             :key="index" :style="{backgroundColor: product.familia.color}" @click="moveSliderTo(index)"></div>
      </div>
      <div class="wrapper product-slider-info-container fullh d-flex flex-column">
        <div class="flex-1 d-flex flex-column justify-content-end">
          <p class="product-description">{{phraseFromFamily}}</p>
          <div class="d-flex flex-row product-buttons-container">
            <a v-if="hasFile" :href="productFile" class="d-inline-flex flex-row pdf-download-container" target="_blank">
              <span :style="{'background-color': product.familia.color}">{{product.nombre_lang[$i18n.locale]}} (PDF)</span>
              <div class="d-flex flex-column justify-content-center" :style="{'background-color': product.familia.color}">
                <DownloadIcon></DownloadIcon>
              </div>
            </a>
            <router-link v-if="!isMobile" class="d-inline-flex flex-row pdf-download-container"
                         :to="{name: 'Works', params: {filtroFamilia: product.familia.id, filtroProducto: product.id}}">
              <span :style="{'background-color': product.familia.color}">{{$t('product.seeProjectsButtons')}}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductosService from '../services/ProductosService'
import DownloadIcon from '../assets/img/download-icon.svg'
import { EventBus } from '@/event-bus'
import { isMobile } from 'mobile-device-detect'
import ImagesSlider from '@/components/ImagesSlider'
export default {
  name: 'Product',
  components: {
    DownloadIcon,
    ImagesSlider
  },
  data () {
    return {
      isMobile,
      showProductMaterial: false,
      product: {
        id: '',
        nombre: '',
        nombre_lang: {
          es: '',
          en: ''
        },
        familia: {
          nombre: '',
          nombre_lang: {
            es: '',
            en: ''
          }
        },
        obras: []
      },
      sliderPosActive: 0,
      imagesSlider: []
    }
  },
  computed: {
    hasFile () {
      return this.product.ficha !== ''
    },
    productFile () {
      if (this.product.ficha_lang !== undefined) {
        return this.product.ficha_lang[this.$i18n.locale]
      }
      return '#'
    },
    phraseFromFamily () {
      let phrase = ''
      if (this.product.familia.nombre !== undefined && this.product.familia.nombre !== null) {
        switch (this.product.familia.nombre.toLowerCase()) {
          case 'pieles metálicas':
            phrase = 'Creating surfaces with character'
        }
      }
      return phrase
    }
  },
  methods: {
    changeSlider (sliderPos) {
      this.sliderPosActive = sliderPos
    },
    moveSliderTo (pos) {
      EventBus.$emit('moveToPos', pos)
    },
    getProductById (id) {
      EventBus.$emit('toggleLoading', true)
      id = id || this.$route.params.productPath.split('-')[0]
      const service = new ProductosService()
      service.findById({ id: id }, (response) => {
        this.product = response
        this.product.fotos.forEach(f => {
          this.imagesSlider.push(f.foto)
        })
        // this.initSlider()
        EventBus.$emit('headerFamilySelected', this.product.familia)
        EventBus.$emit('toggleLoading', false)
      })
    }
  },
  mounted () {
    this.getProductById()
    EventBus.$emit('showLogo')
  },
  watch: {
    product: {
      handler () {
        EventBus.$emit('showTitlePage', this.product.nombre_lang[this.$i18n.locale])
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .product-container {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  .product-slider {
    width: 100%;

    position: relative;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow-x: hidden;
    @include backgroundTransparency(#000, 0.3);

    transition: background 0.5s linear;
    -webkit-transition: background 0.5s linear;
    -moz-transition: background 0.5s linear;
    -o-transition: background 0.5s linear;
  }
  .slider-dots {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    z-index: 9;
    .dot {
      background-color: #000000;
      width: 0.8rem;
      height: 0.8rem;
      transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      cursor: pointer;
      margin: 0.5rem 0;
      &.selected,
      &:hover {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .product-slider-info-container {
    padding-top: calc(var(--main-space) * 4);
    /*height: calc(100vh - 6rem);*/
    height: 100vh;
    position: relative;
    //pointer-events: none;
    .flex-1 {
      flex: 1;
      height: 50%;
    }
    .family-name {
      font-size: 50px;
      color: #FFFFFF;
      margin-bottom: calc(var(--main-space)/5*2);
      line-height: 100%;
    }
    .product-name {
      font-size: 50px;
      font-weight: 700;
      color: #FFFFFF;
      margin: 0;
      line-height: 100%;
    }
  }

  .product-description {
    font-size: 50px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: calc(var(--main-space) * 2);
  }
  .product-buttons-container {
    margin: var(--main-space) 0;
    //.brochures-download-container {
    //  padding-left: 0 !important;
    //  margin-right: var(--main-space);
    //  span {
    //    padding: 0.5rem 1.5rem;
    //    margin-right: 1px;
    //    cursor: pointer;
    //    color: #000000;
    //    font-size: 18px;
    //    line-height: 1.5rem;
    //    font-family: 'IBMPlexSans-SemiBold', sans-serif;
    //  }
    //  div {
    //    padding: 0.5rem;
    //    width: var(--main-space);
    //    cursor: pointer;
    //  }
    //}
    .pdf-download-container {
      margin-bottom: var(--main-space);
      span {
        padding: calc(var(--main-space) / 2);
        margin-right: 1px;
        cursor: pointer;
        color: #000000;
        line-height: 22px;
        font-family: 'IBMPlexSans-SemiBold', sans-serif;
      }
    }
  }

  .product-materials-containers {
    padding: 2rem 0;
    background-color: #000000;
  }
  .product-materials-containers .wrapper {
    min-height: 300px;
    border-bottom: 1px solid $main-color;
  }
  .product-materials-containers .wrapper h2 {
    color: $footer-text;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .product-material-section .flex-1 {
    /*flex: 1;*/
    width: 50%;
  }
  .product-material-img-container {
    height: 300px;
    background-color: $footer-text;
    overflow: hidden;
  }
  .product-material-img-container img {
    width: 100%;
  }
  @supports (object-fit: cover) {
    .product-material-img-container img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .product-material-info-container {
    padding: 1rem;
    width: calc(50% - 2rem);
  }
  .product-material-nombre {
    font-size: 1.4rem;
    font-weight: 600;
    color: $footer-text;
  }
  .product-material-valores .title {
    font-size: 1.2rem;
    color: $footer-text;
    width: 150px;
    display: block;
  }
  .product-material-values-container {
    width: calc(100% - 100px);
  }
  .product-material-valores .value {
    font-size: 1rem;
    font-weight: 600;
    color: $footer-text;
  }

  @include media-breakpoint-down(sm) {
    .product-slider-info-container {
      .family-name {
        font-size: 32px;
      }
      .product-name {
        font-size: 32px;
      }
      .product-description {
        font-size: 32px;
      }
    }
    .pdf-download-container {
      div {
        width: auto;
        svg {
          width: var(--main-space);
        }
      }
    }
  }
</style>
