<template>
  <div class="slider-container" :class="[containerClasses]">
    <vue-flux
      :options="vfOptions"
      :images="images"
      :transitions="transitions"
      style="width:100%;height: 100%"
      ref="slider"
      @images-preload-end="preloadEnds()"
    >

      <template v-slot:preloader>
        <Loading v-if="showLoading"></Loading>
      </template>

      <template v-slot:controls v-if="showControls && images.length > 1">
        <ArrowIcon class="control-arrow control-left" @click.stop.prevent="moveLeft()"></ArrowIcon>
        <ArrowIcon class="control-arrow control-rigth" @click.stop.prevent="moveRigth()"></ArrowIcon>
      </template>
    </vue-flux>
  </div>
</template>

<script>
import {
  VueFlux
} from 'vue-flux'
import Loading from '@/components/Loading'
import ArrowIcon from '@/assets/img/arrow-icon.svg'
import { EventBus } from '@/event-bus'
import { isMobile } from 'mobile-device-detect'
export default {
  name: 'ImagesSlider',
  components: {
    VueFlux,
    Loading,
    ArrowIcon
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    options: {
      type: Object
    },
    containerClasses: {
      type: Array
    },
    transitions: {
      type: Array,
      default () {
        return ['fade']
        /* return [{
          name: 'blocks1',
          options: {
            rows: (isMobile) ? 16 : 9,
            cols: (isMobile) ? 9 : 16,
            tileDelay: 1000,
            tileDuration: 500
          }
        }] */
      }
    }
  },
  data () {
    return {
      isMobile,
      showLoading: true,
      currentSliderPos: 0,
      vfOptions: {
        autoplay: false,
        allowFullscreen: true,
        lazyLoad: true
      }
    }
  },
  computed: {
    initOnMounted () {
      if (this.options === undefined) {
        return true
      }
      return this.options.initSliderOnMounted
    },
    showControls () {
      if (this.options === undefined) {
        return false
      }
      return this.options.showControls
    }
  },
  methods: {
    preloadEnds () {
      this.showLoading = false
      this.$emit('preloadsEnds')
    },
    initSlider () {
      if (this.images.length > 1) {
        setInterval(() => {
          if (this.$refs.slider !== undefined) {
            this.moveRigth()
          }
        }, 6500)
      }
    },
    moveLeft () {
      this.currentSliderPos--
      if (this.currentSliderPos < 0) {
        this.currentSliderPos = (this.images.length - 1)
      }
      this.move('prev')
    },
    moveRigth () {
      this.currentSliderPos++
      if (this.currentSliderPos > (this.images.length - 1)) {
        this.currentSliderPos = 0
      }
      this.move('next')
    },
    move (moveTo) {
      if (this.$refs.slider !== undefined) {
        this.$refs.slider.show(moveTo)
        this.$emit('changeSlider', this.currentSliderPos)
      }
    }
  },
  mounted () {
    if (this.initOnMounted) {
      this.initSlider()
    }
    EventBus.$on('moveRigth', () => {
      this.moveRigth()
    })
    EventBus.$on('moveLeft', () => {
      this.moveLeft()
    })
    EventBus.$on('moveToPos', (pos) => {
      this.currentSliderPos = pos
      this.move(pos)
    })
  }
}
</script>

<style scoped lang="scss">
.slider-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  &.inside-container {
    position: static;
    width: 100%;
    height: 100%;
  }
  .control-arrow {
    fill: #FFFFFF;
    width: calc(var(--main-space) * 3 / 2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.control-left {
      left: var(--main-space);
      transform: translateY(-50%) rotate(180deg);
    }
    &.control-rigth {
      right: var(--main-space);
    }
  }
}
</style>
