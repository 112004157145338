<template>
  <div class="brochure-page">
    <div class="wallpapers-container masonry-container">
      <BrochureCard v-for="(brochure, index) in brochures" :key="'brochure-' + index" :brochure="brochure" class="masonry-item"></BrochureCard>
    </div>
  </div>
</template>

<script>
import brochures from '@/assets/js/brochure.json'
import BrochureCard from '@/components/BrochureCard'
import { EventBus } from '@/event-bus'
export default {
  name: 'Brochure',
  components: {
    BrochureCard
  },
  data () {
    return {
      brochures: brochures
    }
  },
  computed: {
    totalSize () {
      let total = 0
      this.brochures.forEach(w => { total += parseFloat(w.size) })
      return total.toFixed(2)
    }
  },
  mounted () {
    EventBus.$emit('showTitlePage', 'Brochure')
  }
}
</script>

<style  scoped lang="scss">
  .brochure-page {
    min-height: calc(100vh - calc(4 * var(--main-space)) - 52px);
    margin-top: calc(4 * var(--main-space));
    width: 100%;

    .img-download-container {
      cursor: pointer;
      background-color: #FFF;
      margin: 0 0 calc(var(--main-space) / 2) 0;
      height: 40px;
      padding: 0 calc(var(--main-space) / 2);

      span {
        color: #000;
        margin-right: calc(var(--main-space) / 2);
      }
      svg {
        width: 20px;
      }
      svg, path {
        fill: #000;
      }
    }
  }
  .masonry-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, calc(33% - 1px));
    grid-auto-flow: row dense;
    grid-gap: 1px;
    background-color: #000000;

    .masonry-item {
      width: 100%;
      padding-top: 100%;
      position: relative;
      cursor: pointer;
      background-color: #000000;

      ::v-deep {
        .masonry-item-foto-container {
          width: 100%;
          height: 100%;
          position: relative;
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
          margin: 0;

          img {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .masonry-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>
