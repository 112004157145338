import ajax from '../ajax'
import ConstantsHelper from '../ConstantsHelper'
// import { EventBus } from '../event-bus'

export default class Service {
  // startRequest () {
  //   EventBus.$emit('toggleSpinner', true)
  // }
  // endRequest () {
  //   EventBus.$emit('toggleSpinner', false)
  // }

  ajax (type, urlWithParams, data, callbackSuccess, callbackHandleError) {
    type = type || 'GET'
    data = data || {}
    // this.startRequest()
    ajax(type, ConstantsHelper.getURLs().v1 + urlWithParams, data)
      .then((response) => {
        if (callbackSuccess !== undefined) {
          callbackSuccess(response)
        }
        // this.endRequest()
      })
      .catch((error) => {
        if (callbackHandleError !== undefined) {
          callbackHandleError(error)
        }
      })
  }
}
