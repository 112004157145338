<template>
  <div class="work-info-container" @mouseover="$emit('handlerMouseenter')" @mouseleave="$emit('handlerMouseleave')">
    <div class="work-info d-flex flex-column">
      <span class="info">{{work.nombre_lang[$i18n.locale]}}</span>
      <div class="separator md"></div>
      <div class="d-flex flex-row">
        <span class="indicador">{{$t('project.titles.location')}}:</span>
        <span class="info">{{work.ubicacion}}</span>
      </div>
      <div class="separator md"></div>
      <div class="d-flex flex-row">
        <span class="indicador">{{$t('project.titles.year')}}:</span>
        <span class="info">{{work.fecha}}</span>
      </div>
      <div class="separator md"></div>
      <div class="d-flex flex-row">
        <span class="indicador">{{$t('project.titles.arq')}}:</span>
        <span class="info">{{work.estudio}}</span>
      </div>
      <div class="separator msh"></div>
      <span class="info">MSH</span>
      <div class="separator msh"></div>

      <div class="info-item-container">
        <span class="indicador indicator-chip">{{$t('project.titles.products')}}</span>
        <div class="d-flex flex-rown flex-wrap">
          <span class="chip" v-for="(prod) in work.productos" :key="'work-' + work.id + '-prod-'+prod.id">{{prod.nombre_lang[$i18n.locale]}}</span>
        </div>
      </div>
      <div class="info-item-container">
        <span class="indicador indicator-chip">{{$t('project.titles.aplications')}}</span>
        <div class="d-flex flex-rown flex-wrap">
          <span class="chip" v-for="(app) in work.aplicaciones" :key="'work-' + work.id + '-app-'+app.id">{{app.nombre_lang[$i18n.locale]}}</span>
        </div>
      </div>
      <div class="info-item-container">
        <span class="indicador indicator-chip">{{$t('project.titles.materials')}}</span>
        <div class="d-flex flex-rown flex-wrap">
          <span class="chip" v-for="(mat) in work.materiales" :key="'work-' + work.id + '-mat-'+mat.id">{{mat.nombre_lang[$i18n.locale]}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkSelectedInfo',
  props: ['work'],
  computed: {
    workHasProducts () {
      return this.work.productos.length > 0
    },
    firstProductoWork () {
      if (this.workHasProducts) {
        return this.work.productos[0]
      } else {
        return {
          id: '',
          nombre: '',
          familia: {
            icono: '',
            nombre: '',
            color: '#FFFFFF'
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .work-info-container {
    @include backgroundTransparency(#000000, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100vh - calc(var(--main-space) * 3));
    margin-top: calc(var(--main-space) * 3);
    width: 400px;
    .work-info {
      height: 100%;
      padding: calc(var(--main-space));
      overflow: auto;
      span {
        color: #FFFFFF;
        &.info {
          font-weight: 600;
        }
        &.indicador {
          margin-right: calc(var(--main-space) / 3);
          &.indicator-chip {
            margin-bottom: calc(var(--main-space) / 3);
            display: block;
          }
        }
        &.chip {
          padding: calc(var(--main-space) / 3);
          background-color: #000000;
          display: inline-block;
          margin-right: calc(var(--main-space) / 2);
          margin-bottom: calc(var(--main-space) / 2);
        }

        .info-item-container {
          margin-top: var(--main-space);
        }
      }
      .separator {
        width: 100%;
        border-bottom: 3px dotted #FFFFFF;
        margin: var(--main-space) 0;
        &.md {
          margin: calc(var(--main-space) / 2) 0;
        }
        &.msh {
          margin: calc(var(--main-space) / 2) 0 var(--main-space) 0;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .work-info-container {
      margin-top: calc(var(--main-space) * 2 + calc(var(--main-space) * 3 / 2));
    }
  }
  @include media-breakpoint-down(sm) {
    .work-info-container {
      width: 100%;
    }
  }
</style>
