<template>
  <header class="header-work-selected" @mouseover="$emit('handlerMouseenter')" @mouseleave="$emit('handlerMouseleave')">
    <div class="wrapper d-flex flex-row justify-content-between">
      <div class="header-work-info d-flex flex-row align-items-center">
        <figure class="header-work-family-icon">
          <img :src="firstProductoWork.familia.icono" alt="">
        </figure>
        <div class="d-flex flex-column justify-content-center header-work-text-container">
          <span class="bcLiguria d-none d-sm-block" :style="{'color': firstProductoWork.familia.color}">
            {{nameFirstProductWork}} / {{firstProductoWork.nombre_lang[$i18n.locale]}}
          </span>
          <span class="bcLiguria" :style="{'color': firstProductoWork.familia.color}">{{work.estudio}}</span>
        </div>
      </div>
      <div class="header-button-container d-flex flex-row align-items-center justify-content-end">
        <InfoIcon @mouseover.prevent.stop="onHover()" @click.prevent.stop="onClick"></InfoIcon>
        <a @click.prevent.stop="$emit('closeWork')">
          <CloseIcon></CloseIcon>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import CloseIcon from '../../assets/img/close-icon.svg'
import InfoIcon from '../../assets/img/info-icon.svg'
import { isMobile } from 'mobile-device-detect'
export default {
  name: 'WorkSelectedHeader',
  props: ['work'],
  components: {
    CloseIcon,
    InfoIcon
  },
  data () {
    return {
      isMobile
    }
  },
  computed: {
    workHasProducts () {
      return this.work.productos.length > 0
    },
    firstProductoWork () {
      if (this.workHasProducts) {
        return this.work.productos[0]
      } else {
        return {
          id: '',
          nombre: '',
          familia: {
            icono: '',
            nombre: '',
            color: '#FFFFFF'
          }
        }
      }
    },
    nameFirstProductWork () {
      if (this.firstProductoWork.familia.nombre_lang !== undefined) {
        return this.firstProductoWork.familia.nombre_lang[this.$i18n.locale]
      } else {
        if (this.$i18n.locale === 'es') {
          return this.firstProductoWork.familia.nombre
        } else {
          return this.firstProductoWork.familia.nombre_en
        }
      }
    }
  },
  methods: {
    onHover () {
      if (!isMobile) {
        this.$emit('toggleWorkInfo', true)
      }
    },
    onClick () {
      if (isMobile) {
        this.$emit('toggleWorkInfo')
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .header-work-selected {
    width: 100%;
    padding: var(--main-space) 0;
    @include backgroundTransparency(#000000, 0.5);
    .wrapper {
      .header-work-info {
        .header-work-family-icon {
          width: var(--main-space);
          margin: 0;
          margin-right: calc(var(--main-space) / 2);
        }
        .header-work-text-container {
          height: var(--main-space);
          span {
            font-size: 20px;
          }
        }
      }
      .header-button-container {
        svg, a {
          width: var(--main-space);
          fill: #FFFFFF;
          cursor: pointer;
          &:not(:first-child) {
            margin-left: calc(var(--main-space) / 5 * 4);
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .header-work-selected {
      .wrapper {
        .header-work-info {
          .header-work-family-icon {
            width: calc(var(--main-space) * 3 / 2);
          }
          .header-work-text-container {
            height: calc(var(--main-space) * 3 / 2);
          }
        }
      }
    }
  }
</style>
