<template>
  <div class="material-popup-container">
    <div class="d-flex flex-column position-relative material-popup-info-container justify-content-center" :style="{backgroundImage: 'url(' + material.foto_file + ')'}">
      <CloseIcon class="material-close-icon hoverable" @click="$emit('closeMaterial')"></CloseIcon>
      <div class="wrapper">
        <h2 class="bcLiguria">{{material.nombre_lang[$i18n.locale]}}</h2>
        <p class="">{{material.descripcion_lang[$i18n.locale]}}</p>
        <router-link v-if="!isMobile" class="d-inline-flex flex-row button-obras" :to="{name: 'Works', params: {filtroMaterial: material.id}}">
          <span>{{$t('materials.seeProjectsButton', {material: material.nombre_lang[$i18n.locale]})}}</span>
        </router-link>
      </div>
      <DotsSlider :items="materiales" :pos-active="pos" @selectMaterialByPos="selectMaterialByPos" @next="$emit('next')"></DotsSlider>
    </div>
  </div>
</template>

<script>
import CloseIcon from '../assets/img/close-icon.svg'
import DotsSlider from '../components/DotsSlider'
import { isMobile } from 'mobile-device-detect'
export default {
  name: 'Material',
  props: ['material', 'pos', 'materiales'],
  components: {
    CloseIcon,
    DotsSlider
  },
  data () {
    return {
      isMobile,
      mainStyles: {
        backgroundImage: `url(${require('@/assets/img/material-background.jpg')})`
      }
    }
  },
  computed: {
    hayMaterialObras () {
      return this.material.obras !== null && this.material.obras.length > 0
    }
  },
  methods: {
    selectMaterialByPos (pos) {
      const m = this.materiales[pos]
      this.$emit('selectMaterial', m)
    }
  },
  mounted () {
    window.scrollTo({ top: 0 })
    this.mainStyles.backgroundImage = this.material.foto_file
  }
}
</script>

<style scoped lang="scss">
  .material-popup-container {
    z-index: 99;
    position: relative;
    background-color: #000000;
    transition: all 0.5s ease-in-out;
    .material-popup-info-container {
      background-color: #000000;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100vh;

      -webkit-animation: backgroundFadeIn 5s ;
      animation: backgroundFadeIn 5s ;
      .wrapper {
        width: 90%;
        height: auto;

        h2 {
          margin: 0;
          font-size: 61px;
          color: #FFFFFF;
        }
        p {
          color: #FFFFFF;
          font-size: 30px;
          width: 90%;
          //line-height: 40px;
          margin: var(--main-space) 0;
        }
        .button-obras {
          span {
            background-color: #000000;
            color: #FFFFFF;
            padding: calc(var(--main-space) / 2);
          }
        }
      }
    }

    .material-close-icon {
      position: absolute;
      top: var(--main-space);
      right: var(--main-space);
      width: var(--main-space);
      height: var(--main-space);
      fill: #FFFFFF;
    }
  }

  @include media-breakpoint-down(lg) {
    .material-popup-container {
      .material-popup-info-container {
        .wrapper {
          h2 {
            margin-top: calc(var(--main-space) / 2);
            font-size: 48px;
          }
          p {
            font-size: 24px;
            line-height: 150%;
            width: 100%;
            margin: calc(var(--main-space) / 2) 0;
          }
          .button-obras {
            span {
              padding: calc(var(--main-space) / 5 * 2);
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .material-popup-container {
      .material-popup-info-container {
        .wrapper {
          h2 {
            margin-top: var(--main-space);
          }
          p {
            width: 100%;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .material-popup-container {
      .material-popup-info-container {
        min-height: 100vh;
        height: auto;
        .wrapper {
          h2 {
            font-size: 36px;
            margin-top: 0;
          }
          p {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }
  }
</style>
