<template>
  <footer class="footer d-flex flex-column justify-content-center">
    <div class="wrapper">
      <div class="footer-brand-power d-flex flex-sm-row flex-column justify-content-between align-items-center">
        <span class="footer-brand">Copyright 2020 &copy; Grupo MSH</span>
        <span class="footer-power-by">{{$t('footer.powerBy')}} <a href="https://daptee.com.ar/" target="_blank">Daptee | Agencia digital</a></span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss">
  .footer {
    background-color: #000000;
    width: 100%;
    /*height: var(--main-space);*/
    padding: calc(var(--main-space) / 2) 0;
    position: fixed;
    bottom: 0;
    z-index: -1;

    .wrapper {
      .footer-brand-power {
        .footer-brand {
          font-size: 0.8rem;
          color: $footer-text;
          display: block;
        }
        .footer-power-by {
          font-size: 0.8rem;
          color: $footer-text;
          a {
            font-size: 0.8rem;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .footer {
      /*height: calc(var(--main-space) * 4);*/
      padding: var(--main-space) 0;
      .footer-brand {
        width: 100%;
      }
      .footer-power-by {
        width: 100%;
        text-align: right;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .footer {
      .footer-power-by {
        margin-top: calc(var(--main-space)/2);
        text-align: left;
      }
    }
  }
</style>
