<template>
  <div id="app" :class="{'white-app': whiteApp, 'show-footer': showFooter}">
    <Header :white-header="whiteHeader" v-on:update:value="whiteHeader = $event"></Header>
    <transition name="fade-in-up" mode="out-in">
      <Menu v-if="showMenu"></Menu>
    </transition>
    <transition name="fade-in-up" mode="out-in">
      <GridFamilyDots v-if="showGridFamilyDots" :family-products="familyProducts" :family-projects="familyProjectsMenu"></GridFamilyDots>
    </transition>
    <transition
      :name="transitionName"
      mode="out-in"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
    >
<!--      <router-view :familias="familyProducts" v-on:update:value="familyProducts = $event" />-->
      <router-view />
    </transition>
    <Footer v-if="showFooter"></Footer>
    <Loading v-if="showLoading"></Loading>
    <WorkSelected v-if="workSelected !== null" :work="workSelected" @closeWork="closeWork()"></WorkSelected>
  </div>
</template>

<script>
import Header from './components/Header'
import Menu from './components/Menu'
import GridFamilyDots from './components/GridFamilyDots'
import { EventBus } from './event-bus'
import { isMobileOnly } from 'mobile-device-detect'
import Loading from './components/Loading'
import Helper from './helpers/Helper'
import Footer from './components/Footer'
import ObrasService from './services/ObrasService'
import WorkSelected from '@/components/works/WorkSelected'

const DEFAULT_TRANSITION = 'fade'
export default {
  name: 'App',
  data () {
    return {
      isMobileOnly,
      showMenu: false,
      showGridFamilyDots: false,
      showLoading: false,
      whiteHeader: false,
      transitionName: DEFAULT_TRANSITION,
      familyProducts: [],
      whiteApp: false,
      familyProjectsMenu: [],
      workSelected: null,
      showFooter: true
    }
  },
  components: {
    Header,
    Menu,
    GridFamilyDots,
    Loading,
    WorkSelected,
    Footer
  },
  mounted () {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName

      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }
      this.transitionName = transitionName || DEFAULT_TRANSITION

      next()
    })

    if (!isMobileOnly) {
      this.getProjectsFamilyMenu()
    }

    this.showFooter = !(Helper.isInURL('materials') || Helper.isInURL('videos') || Helper.isInURL('product'))

    // this.whiteHeader = this.$route.path.includes('/works')
    // this.whiteApp = this.$route.path === '/works'

    EventBus.$on('toggleMenu', (showMenu) => {
      this.showMenu = showMenu
      EventBus.$emit('toggleMenuIcon', !showMenu)
      EventBus.$emit('showMailIcon', !showMenu)
    })
    EventBus.$on('toggleGridFamilyDots', (showGrid) => {
      this.showGridFamilyDots = showGrid
      EventBus.$emit('toggleGridFamilyIcon', !showGrid)
    })
    EventBus.$on('toggleLoading', (toggle) => {
      this.showLoading = toggle
    })

    EventBus.$on('familyProducts', (familyProducts) => {
      this.familyProducts = familyProducts
    })

    EventBus.$on('bodyScroll', (bodyScrollToggle) => {
      this.toggleBodyScroll(bodyScrollToggle)
    })

    EventBus.$on('showWork', (work) => {
      this.showWork(work)
    })

    EventBus.$on('showFooter', (show) => {
      this.showFooter = show
    })

    EventBus.$on('changeLanguage', () => {
      const locale = (this.$i18n.locale === 'es') ? 'en' : 'es'
      this.changeLanguage(locale)
    })
  },
  methods: {
    beforeEnter (el) {
      // this.showLoading = !this.showLoading
    },
    beforeLeave (el) {
      // this.showLoading = !this.showLoading
    },
    toggleBodyScroll (bodyScrollToggle) {
      document.body.classList.toggle('no-scroll', !bodyScrollToggle)
    },

    getProjectsFamilyMenu () {
      const service = new ObrasService()
      service.findAll({ for: 1 }, (response) => {
        this.familyProjectsMenu = response
      })
    },
    showWork (work) {
      this.workSelected = work
    },
    closeWork () {
      this.workSelected = null
    },

    changeLanguage (locale) {
      this.$i18n.locale = locale
      const to = this.$router.resolve({ params: { locale } })
      this.$router.push(to.location)
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        this.toggleBodyScroll(true)
        EventBus.$emit('toggleGridFamilyIcon', true)
        if (!Helper.isInURL('family-product') && !Helper.isInURL('product')) {
          EventBus.$emit('headerFamilySelected', null)
        }
        window.scrollTo({ top: 0 })

        if (to.name === 'Works') {
          EventBus.$emit('familyProducts', this.familyProducts)
          EventBus.$emit('toggleMenuIcon', true)
        }
        if (to.name === 'Home' || to.name === 'Wallpapers' || to.name === 'Brochure') {
          EventBus.$emit('toggleMenuIcon', true)
        }

        EventBus.$emit('showContactsIcons', to.name === 'Contact')

        EventBus.$emit('toggleHeaderFamilyIcons', to.name !== 'GPTW')

        this.showFooter = !(to.name === 'Materials' || to.name === 'Videos' || to.name === 'product')
      }
    },
    showLoading: {
      handler () {
      }
    }
  }
}
</script>

<style lang="scss">
@import '/assets/scss/app';
@import "/assets/scss/transitions/fade/fade";
@import "/assets/scss/transitions/fade/fade-in-left";
@import "/assets/scss/transitions/fade/fade-in-right";
@import "/assets/scss/transitions/fade/fade-in-up";
@import "/assets/scss/transitions/fade/fade-in-down";
@import "assets/scss/transitions/fade/fade-in-right-group";

  #app {
    overflow-x: hidden;
    background-color: #000000;
    &.white-app {
      background-color: #FFFFFF;
    }
    &.show-footer {
      margin-bottom: 52px;  //ALTO DEL FOOTER
    }
  }
@include media-breakpoint-down(sm) {
  #app {
    &.show-footer {
      margin-bottom: 60px;  //ALTO DEL FOOTER
    }
  }
}
  @include media-breakpoint-down(xs) {
    #app {
      &.show-footer {
        margin-bottom: 84px;  //ALTO DEL FOOTER
      }
    }
  }
</style>
