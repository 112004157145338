<template>
<!--  <router-link :to="{name: 'Works', params: {work: dataPath, object: data}}" v-if="data !== undefined">-->
  <a href="#" @click.stop.prevent="showWork()" v-if="data !== undefined">
    <div class="home-slider-info-container d-flex flex-row align-items-center">
      <figure class="home-slider-family-product-icon">
        <img :src="getFamilyIcon" alt="">
      </figure>
      <div class="d-flex flex-column">
        <span :style="{color: getFamilyColor}">{{getFamilyName}} / {{getFirstProductName}}</span>
        <span :style="{color: getFamilyColor}">{{data.estudio}}</span>
      </div>
    </div>
  </a>
</template>

<script>
import Helper from '@/helpers/Helper'
import { EventBus } from '@/event-bus'
export default {
  name: 'HomeSliderInfo',
  props: ['data'],
  computed: {
    getFirstProduct () {
      if (this.data.productos !== undefined) {
        return this.data.productos[0]
      }
      return null
    },
    getFirstProductName () {
      if (this.getFirstProduct !== null) {
        return this.getFirstProduct.nombre
      }
      return ''
    },
    getFamilyIcon () {
      if (this.getFirstProduct !== null) {
        return this.getFirstProduct.familia.icono
      }
      return ''
    },
    getFamilyColor () {
      if (this.getFirstProduct !== null) {
        return this.getFirstProduct.familia.color
      }
      return '#000000'
    },
    getFamilyName () {
      if (this.getFirstProduct !== null) {
        return this.getFirstProduct.familia.nombre
      }
      return ''
    },
    dataPath () {
      if (this.data.nombre !== undefined) {
        return Helper.nameToPath(this.data.nombre)
      }
      return ''
    }
  },
  methods: {
    showWork () {
      EventBus.$emit('showWork', this.data)
    }
  }
}
</script>

<style scoped lang="scss">
  .home-slider-info-container {
    position: fixed;
    bottom: var(--main-space);
    left: var(--main-space);
    height: var(--main-space);
  }
  .home-slider-family-product-icon {
    width: var(--main-space);
    height: var(--main-space);
    margin: 0 calc(var(--main-space)/5*2) 0 0;
  }

  span {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.6rem;
  }

  @include media-breakpoint-down(sm) {
    span {
      font-size: 12px;
      line-height: 1rem;
    }
  }
</style>
